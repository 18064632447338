import React, { useState, useEffect, useRef } from 'react';

interface TableTextProps {
  text?: string;
  userPks?: string;
  memberPopupOpen?: (pk: number) => void;
}

export default function TableText(props: TableTextProps) {
  const [moreBtnViewer, setMoreBtnViewer] = useState<boolean>(false);
  const [moreBtn, setMoreBtn] = useState<boolean>(false);
  const [defaultHeight, setDefaultHeight] = useState<number>(0);
  const textRef = useRef<HTMLDivElement>(null);

  const memberDetailPopup = (pk: number) => {
    if (props.memberPopupOpen) {
      props.memberPopupOpen(pk);
    }
  };

  const moreToggle = () => {
    if (textRef.current && textRef.current) {
      textRef.current.style.height = defaultHeight + 'px';
      if (moreBtn) {
      } else {
        textRef.current.style.height = textRef.current.scrollHeight + 'px';
      }
    }
    setMoreBtn(!moreBtn);
  };

  useEffect(() => {
    if (textRef.current) {
      if (textRef.current.scrollHeight > textRef.current.clientHeight) {
        setMoreBtnViewer(true);
        setDefaultHeight(textRef.current.clientHeight);
      } else {
        setMoreBtnViewer(false);
      }
    }
  }, [props.text]);

  return (
    <>
      <div ref={textRef} className="textWrapper">
        {props.text && !props.userPks && <div dangerouslySetInnerHTML={{ __html: props.text }}></div>}
        {props.text && props.userPks && (
          <div>
            {props.text.split(',').map((name: string, idx: number) => (
              <span
                key={name + idx}
                className={name === '대상자 없음' ? '' : 'pointer'}
                onClick={() =>
                  name === '대상자 없음' ? '' : memberDetailPopup(Number(props.userPks?.split(',')[idx]))
                }
                dangerouslySetInnerHTML={{
                  __html: name + (props.text && props.text.split(',').length === idx + 1 ? '' : ','),
                }}></span>
            ))}
          </div>
        )}
      </div>
      {moreBtnViewer && (
        <span className={moreBtn ? 'active' : ''} onClick={moreToggle}>
          <i className="fas fa-caret-up"></i>
        </span>
      )}
    </>
  );
}
