import axios from 'axios';
import { isArrayBuffer } from 'lodash';
import qs from 'qs';
import { userState } from '../stores/UserState';

let loading: boolean;
let header: any;
const loginInfo = localStorage.getItem('mecuteLogin');
if (loginInfo && loginInfo !== null) {
  header = { Authorization: 'Bearer ' + JSON.parse(loginInfo).accessToken, token: JSON.parse(loginInfo).token };
} else {
  header = {};
}

const axiosInstance = axios.create({
  headers: header,
  baseURL: process.env.REACT_APP_API_URL,
  transformRequest: [
    function(data, headers) {
      const loader = document.querySelector('.loader');
      if (loader) {
        if (!loading) {
          loading = true;
          loader.classList.add('loading');
        }
      }

      if (headers && headers['Content-Type'] === 'application/json') {
        return JSON.stringify(data);
      }

      if (qs.stringify(data)) {
        return qs.stringify(data);
      } else {
        return data;
      }
    },
  ],
  transformResponse: [
    function(data, error) {
      const loader = document.querySelector('.loader');
      if (loader) {
        if (loading) {
          setTimeout(() => {
            loader.classList.remove('loading');
            loading = false;
          }, 500);
        }
      }
      if (typeof data === 'string' && !data.trim()) {
        return;
      }
      if (isArrayBuffer(data)) {
        return data;
      }
      return JSON.parse(data);
    },
  ],
  responseType: 'json',
});

axiosInstance.interceptors.response.use(
  response => {
    if (response?.status === 401) {
      userState.clearUserInfo();
      window.location.href = '/auth/login';
      localStorage.removeItem('mecuteLastActiveTime');
      return;
    }
    return response;
  },
  error => {
    console.error('error: ', error);
  },
);

export default axiosInstance;
