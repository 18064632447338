import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';

import axios from '../../../axios/index';
import PageNav from '../../../components/Pages/PageNav';
import NewBanner from './Event/Banner/NewBanner';
import BannerList from './Event/Banner/BannerList';
import BannerDetail from './Event/Banner/BannerDetail';

interface EventBanner {
  id: number;
  valid: boolean;
  linkUrl: string;
  imageUrl: string;
  createdAt: string;
  startedAt: string;
  endedAt: string;
}
export interface EventBannersInfo {
  items: EventBanner[];
  meta: {
    totalItems: number;
    itemCount: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
  };
}
interface BannerViewInfo {
  type: BannerViewType;
  id: number;
}

enum BannerViewType {
  ListView,
  DetailView,
  EditView,
}

const ITEMS_PER_PAGE = 10;

export default function BannerSetting() {
  const [eventBannersInfo, setEventBannersInfo] = useState<EventBannersInfo | null>(null);
  const [exposureActivatedBannerIds, setExposureActivatedBannerIds] = useState<Array<number>>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  /** 라우팅 관리 */
  const [viewInfo, setViewInfo] = useState<BannerViewInfo>({ type: BannerViewType.ListView, id: 0 });
  const location = useLocation();

  const checkIfValidTime = (startedAt: string, endedAt: string) => {
    const now = new Date();
    const startedAtDate = new Date(startedAt);
    const endedAtDate = new Date(endedAt);

    if (startedAtDate <= now && now <= endedAtDate) {
      return true;
    }

    return false;
  };

  const fetchEventBanners = async () => {
    try {
      const response = await axios.get(`/v1/announce-banner?page=${currentPage + 1}&limit=${ITEMS_PER_PAGE}`);

      if (response?.status === 200) {
        // 노출중인 배너 아이디 값 관리
        if (response.data.meta.currentPage === 1) {
          const activatedBannerIds: Array<number> = [];

          response.data.items.forEach((item: EventBanner) => {
            if (item.valid && checkIfValidTime(item.startedAt, item.endedAt)) {
              activatedBannerIds.push(item.id);
            }
          });

          setExposureActivatedBannerIds(activatedBannerIds);
        }

        setEventBannersInfo({ items: response.data.items, meta: response.data.meta });
      } else {
        console.log(response);
      }
    } catch (error) {
      alert(error);
      console.error(`/v1/announce-banner 에러: ${error}`);
    }
  };

  useEffect(() => {
    fetchEventBanners();
  }, [currentPage]);

  // 라우팅 관리
  useEffect(() => {
    // list view
    if (location.search === '') {
      setViewInfo({
        id: 0,
        type: BannerViewType.ListView,
      });
      return;
    }

    // edit view
    if (location.search && location.search.includes('edit=true')) {
      const extractIdValue = (query: string) => {
        const regex = /id=(\d+)/;
        const match = regex.exec(query);

        if (match && match[1]) {
          const pkValue = parseInt(match[1]);
          return pkValue;
        }

        return null;
      };

      const id = extractIdValue(window.location.href);
      if (id) {
        setViewInfo({
          id,
          type: BannerViewType.EditView,
        });
      }
      return;
    }

    // detail view
    if (location.search && location.search.includes('id')) {
      const id = Number(location.search.split('=')[1]);
      setViewInfo({
        id,
        type: BannerViewType.DetailView,
      });
    }
  }, [location]);

  return (
    <>
      <div className="body-section">
        <div className="contentsContainer">
          <div className="pageInfo">
            <span className="text-muted">관리자 및 앱 / </span>
            <span className="text-primary">이벤트 배너 관리</span>
          </div>
          {viewInfo.type === BannerViewType.ListView && (
            <>
              {/* 이벤트 배너 생성 */}
              <NewBanner fetchEventBanners={fetchEventBanners} />
              {/* 이벤트 배너 리스트 */}
              <BannerList eventBannersInfo={eventBannersInfo} exposureActivatedBannerIds={exposureActivatedBannerIds} />
              {/* 페이지네이션 */}
              {eventBannersInfo && (
                <PageNav
                  current={currentPage}
                  maxPage={eventBannersInfo?.meta.totalPages}
                  getPageInfo={setCurrentPage}
                />
              )}
            </>
          )}
          {viewInfo.type === BannerViewType.DetailView && <BannerDetail id={viewInfo.id} />}
          {viewInfo.type === BannerViewType.EditView && (
            <NewBanner id={viewInfo.id} fetchEventBanners={fetchEventBanners} />
          )}
        </div>
      </div>
    </>
  );
}
