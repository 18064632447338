import React, { useEffect, useState } from 'react';
import { Row, Col, FormGroup, InputGroup, Input, InputGroupAddon, Button, Table } from 'reactstrap';
import dayjs from 'dayjs';

import axios from '../../../axios/index';
import DateSetting from '../../../components/Pages/DatePicker';
import { numberWithCommas } from '../../../components/Pages/commonFunction';
import { formatKoreanLocalTime } from '../../../utils/utils';
import PageNav from '../../../components/Pages/PageNav';

interface PaymentInfoI {
  items: [
    {
      id: number;
      nick: string;
      gender: string;
      product: string;
      store: string;
      price: number;
      created: string;
    },
  ];
  meta: {
    itemCount: number;
    totalItems: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
  };
}

const ITEMS_PER_PAGE = 10;
const initialDate = {
  pinDate: dayjs().format('YYYY.MM.DD'),
  startDate: dayjs('2023.01.01').format('YYYY.MM.DD'),
  endDate: dayjs()
    .add(1, 'month')
    .format('YYYY.MM.DD'),
};

function PaymentPage() {
  const [dateFilter, setDateFilter] = useState(initialDate);
  const [nicknameFilter, setNicknameFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [paymentInfo, setPaymentInfo] = useState<PaymentInfoI | null>(null);

  const getDate = (date?: string) => {
    if (date) {
      const dateParse = JSON.parse(date);
      setDateFilter({
        pinDate: dateParse.pinDate,
        startDate: dateParse.startDate,
        endDate: dateParse.endDate,
      });
    }
  };

  const fetchPaymentInfo = async () => {
    try {
      const response = await axios.get(
        `/v1/payment/list?page=${currentPage + 1}&limit=${ITEMS_PER_PAGE}&startedAt=${new Date(
          dateFilter.startDate,
        ).toISOString()}&endedAt=${new Date(dateFilter.endDate).toISOString()}${
          nicknameFilter ? `&nick=${nicknameFilter}` : ''
        }`,
      );

      if (response?.status === 200) {
        setPaymentInfo(response.data);
        setCurrentPage(response.data.meta.currentPage - 1);
      } else {
        console.error('/v1/payment/list GET 실패. response: ', response);
      }
    } catch (err) {
      console.error('/v1/payment/list GET 에러: ', err);
    }
  };

  const handleNameSearchClick = async () => {
    fetchPaymentInfo();
    setNicknameFilter('');
  };

  const handleDateSearchClick = () => {
    fetchPaymentInfo();
  };

  useEffect(() => {
    getDate(JSON.stringify(initialDate));
  }, []);

  useEffect(() => {
    fetchPaymentInfo();
  }, [currentPage]);

  return (
    <>
      <div className="body-section">
        <div className="contentsContainer">
          <div className="pageInfo">
            <span className="text-muted">결제 / </span>
            <span className="text-primary">결제 로그</span>
          </div>
          <h1 className="pageTitle">결제 로그</h1>
          <div className="contentsWrap">
            {/* Date 필터, 닉네임 필터*/}
            <Row>
              <Col md="8">
                <FormGroup className="row mb-0">
                  <DateSetting getDate={getDate} allDate onSearchClick={handleDateSearchClick} />
                </FormGroup>
              </Col>
              <Col md="4">
                <InputGroup>
                  <Input onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNicknameFilter(e.target.value)} />
                  <InputGroupAddon addonType="append">
                    <Button onClick={handleNameSearchClick}>검색</Button>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
            </Row>
            {/* PaymentInfo 리스트 */}
            <div className="tableWrap">
              <Table bordered>
                <thead>
                  <tr>
                    <th>결제 ID</th>
                    <th>결제일</th>
                    <th>닉네임</th>
                    <th>성별</th>
                    <th>상품</th>
                    <th>가맹점</th>
                    <th>결제금액</th>
                  </tr>
                </thead>
                <tbody>
                  {paymentInfo?.items.length ? (
                    paymentInfo?.items.map((item, index) => (
                      <tr key={item.id}>
                        <td>{item.id}</td>
                        <td>{formatKoreanLocalTime(item.created)}</td>
                        <td>{item.nick}</td>
                        <td>{item.gender}</td>
                        <td>{item.product}</td>
                        <td>{item.store}</td>
                        {/* 개발서버는 price 값을 안내려 받음 */}
                        {item.price && <td>{numberWithCommas(item.price)}원</td>}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={8}>결제 내역이 없습니다.</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            {paymentInfo && (
              <PageNav current={currentPage} maxPage={paymentInfo.meta.totalPages} getPageInfo={setCurrentPage} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentPage;
