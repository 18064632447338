import React, { useState, useEffect } from 'react';
// import { useHistory } from 'react-router'

import qs from 'qs';

import axios from '../../../axios/index';
import '../../../assets/css/member.scss';
import MemberProfile from '../memberDetail/Profile';
import PayHistory from '../memberDetail/PayHistory';

interface MemberDetailProps {
  memberPopupClose: () => void;
  pageMain: string | undefined | null;
  pageSub: string | undefined | null;
}

interface UserInfo {
  pk: number;
  tel: string;
  email: string;
  nick: string;
  gender: 'male' | 'female';
  age: number;
  birth: string; // Date
  dormant: number;
  withdrawal: string; // Date
  disabled: number;
  public: number;
  lastSignIn: string; // Date
  created: string; // Date
  height: number;
  religion: string;
  drink: string;
  relation: string;
  smoke: string;
  mbti: string;
  freeQna: string;
  city: string;
  address: string;
  homeTown: string;
  profileImages: any;
  career: string;
  pet: string;
}

export default function MemberDetail(props: MemberDetailProps) {
  const [userPk, setUserPk] = useState<number>(0);
  const [activeTab, setActiveTab] = useState<number>();
  const [userInfo, setUserInfo] = useState<UserInfo>();

  const getChangeData = (memberPk?: number) => {
    axios
      .get(`/member/${memberPk ?? userPk}/detail`)
      .then(res => {
        setUserInfo(res.data);
      })
      .catch(err => {
        console.log('/member/:userPk/detail GET. ERROR', err);
      });
  };

  const changeTabState = (state: number) => {
    const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    setActiveTab(state);
    const changeParmas = 'member-list?member=' + params.member + '&activetab=' + state;
    window.history.replaceState(null, '', changeParmas);
  };

  useEffect(() => {
    const paramsTab = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const activeNum = Number(paramsTab.activetab);
    if (activeNum) {
      setActiveTab(activeNum);
    } else {
      setActiveTab(0);
    }
    document.querySelector('.body-section')?.classList.add('stopScroll');
    window.scrollTo(0, 0);
    return () => {
      document.querySelector('.body-section')?.classList.remove('stopScroll');
    };
  }, []);

  useEffect(() => {
    const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const memberPk = Number(params.member);
    setUserPk(memberPk);
    getChangeData(memberPk);
  }, [userPk]);
  return (
    <>
      {!userInfo?.withdrawal?.length && (
        <div className="fullPopupContain memberPopup">
          <div className="contentsContainer">
            <div className="pageInfo">
              <span className="text-muted">{props.pageMain} / </span>
              <span className="text-primary">{props.pageSub}</span>
            </div>
            <h2 className="mb-4">
              <span onClick={props.memberPopupClose} className="mr-2 popupBack">
                <i className="fas fa-arrow-left"></i>
              </span>
              <span className={userInfo?.withdrawal && userInfo.withdrawal.length > 0 ? 'text-muted' : ''}>
                {userInfo?.nick}
              </span>
            </h2>
            <ul className="contentsTab">
              <li className={activeTab === 0 ? 'active' : ''} onClick={() => changeTabState(0)}>
                프로필
              </li>
              {/* <li className={activeTab === 1 ? 'active' : ''} onClick={() => changeTabState(1)}>
                결제 내역
              </li>
              <li className={activeTab === 4 ? 'active' : ''} onClick={() => changeTabState(4)}>
                신고/차단 내역
              </li>
              <li className={activeTab === 6 ? 'active' : ''} onClick={() => changeTabState(6)}>
                유저 활동 로그
              </li>
              <li className={activeTab === 7 ? 'active' : ''} onClick={() => changeTabState(7)}>
                탈퇴
              </li> */}
            </ul>
            <div className="contentsWrap">
              {/* 프로필 */}
              {activeTab === 0 && <MemberProfile userInfo={userInfo} userPk={userPk} changeData={getChangeData} />}
              {/* 결제내역 */}
              {activeTab === 1 && <PayHistory userPk={userPk} />}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
