import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Col, FormGroup, ButtonGroup, Button } from 'reactstrap';

interface DateProps {
  getDate: (e: any) => void;
  today?: boolean;
  allDate?: any;
  onSearchClick?: () => void;
}

export default function DateSetting(props: DateProps) {
  const today = new Date(new Date().setDate(new Date().getDate()));
  const defaultDate = new Date(new Date().setDate(new Date().getDate()));
  const maxDatePlusOneMonth = new Date(today.setMonth(today.getMonth() + 1));
  const [startDate, setStartDate] = useState<Date | null>(defaultDate);
  const [endDate, setEndDate] = useState<Date | null>(defaultDate);
  const [complete, setComplete] = useState(false);
  const [period, setPeriod] = useState(3);

  const dateToString = (date: any) => {
    return (
      date.getFullYear() +
      '.' +
      (date.getMonth() + 1).toString().padStart(2, '0') +
      '.' +
      date
        .getDate()
        .toString()
        .padStart(2, '0')
    );
  };

  const onChange = (date: Date) => {
    setStartDate(date);
    setEndDate(null);
    setComplete(true);
    setPeriod(-1);
  };

  const setDate = (date: any) => {
    setEndDate(date);
    setComplete(false);
  };

  const periodSetting = (period: number) => {
    const prevDate = new Date(new Date().setDate(new Date().getDate() - 1));
    let setDate: any;
    if (period === 0) setDate = new Date();
    if (period === 1) setDate = prevDate;
    if (period === 7) setDate = new Date(prevDate.setDate(prevDate.getDate() - 7));
    if (period === 30) setDate = new Date(prevDate.setMonth(prevDate.getMonth() - 1));
    if (period === 180) setDate = new Date(prevDate.setMonth(prevDate.getMonth() - 6));
    if (period === 365) setDate = new Date(prevDate.setFullYear(prevDate.getFullYear() - 1));
    if (period === 3) setDate = new Date('2023.01.01');
    setStartDate(setDate);
    if (period === 0) setEndDate(new Date());
    else if (period === 3) setEndDate(maxDatePlusOneMonth);
    else setEndDate(defaultDate);
    setPeriod(period);
  };

  const sendDate = () => {
    const data = JSON.stringify({
      pinDate: dateToString(defaultDate),
      startDate: dateToString(startDate),
      endDate: dateToString(endDate),
    });
    props.getDate(data);
  };

  useEffect(() => {
    props.allDate ? periodSetting(3) : periodSetting(1);
    sendDate();
  }, []);

  return (
    <>
      <FormGroup className="mb-0">
        <ButtonGroup className="dateGroup mr-2" aria-label="Basic example" role="group" bssize="sm">
          {props?.allDate ? (
            <Button
              className={period === 3 ? 'active' : ''}
              color="secondary"
              type="button"
              onClick={() => periodSetting(3)}>
              올해
            </Button>
          ) : (
            <Button
              className={period === 0 ? 'active' : ''}
              color="secondary"
              type="button"
              onClick={() => periodSetting(0)}
              disabled={props.today ? true : false}>
              오늘
            </Button>
          )}
          <Button
            className={period === 1 ? 'active' : ''}
            color="secondary"
            type="button"
            onClick={() => periodSetting(1)}>
            전일
          </Button>
          <Button
            className={period === 7 ? 'active' : ''}
            color="secondary"
            type="button"
            onClick={() => periodSetting(7)}>
            일주일
          </Button>
          <Button
            className={period === 30 ? 'active' : ''}
            color="secondary"
            type="button"
            onClick={() => periodSetting(30)}>
            한달
          </Button>
          <Button
            className={period === 180 ? 'active' : ''}
            color="secondary"
            type="button"
            onClick={() => periodSetting(180)}>
            6개월
          </Button>
          <Button
            className={period === 365 ? 'active' : ''}
            color="secondary"
            type="button"
            onClick={() => periodSetting(365)}>
            1년
          </Button>
        </ButtonGroup>
        <div className="dateSetWrap d-inline-block">
          <div className="dateSet">
            <div>
              <DatePicker
                selected={startDate}
                maxDate={props.allDate ? maxDatePlusOneMonth : defaultDate}
                selectsStart
                onChange={(date: any) => onChange(date)}
                customInput={<input className="dateInput startDate" />}
                dateFormat="yyyy.MM.dd"
              />
              <input
                readOnly
                value={endDate ? dateToString(endDate) : 'end date'}
                className="endDate dateInput text-right"></input>
              {complete && (
                <DatePicker
                  selected={endDate}
                  selectsEnd
                  startDate={startDate}
                  minDate={startDate}
                  maxDate={props.allDate ? maxDatePlusOneMonth : defaultDate}
                  onChange={(date: any) => setDate(date)}
                  dateFormat="yyyy.MM.dd"
                  inline
                />
              )}
            </div>
          </div>
        </div>
        <Button
          className="ml-2 align-top"
          size="sm"
          outline
          color="default"
          onClick={() => {
            sendDate();
            props.onSearchClick && props.onSearchClick();
          }}>
          조회
        </Button>
      </FormGroup>
    </>
  );
}
