import React from 'react';

export function numberWithCommas(x: number | string) {
  if (typeof x === 'string') x = Number(x);
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function dateFormat(d: string) {
  if (d === null) return '-';
  const date = d.split(' ')[0];
  const time = d.split(' ')[1];
  return (
    (date ? date.split('-')[0] + '.' + date.split('-')[1] + '.' + date.split('-')[2] : '') + ' ' + (time ? time : '')
  );
}

export function timeSeconds(d: string | Date) {
  let time: any = d;
  time = new Date(d);
  time = new Date(time.setSeconds(time.getSeconds() + 59));
  return (
    time.getFullYear() +
    '.' +
    (time.getMonth() + 1).toString().padStart(2, '0') +
    '.' +
    time
      .getDate()
      .toString()
      .padStart(2, '0') +
    ' ' +
    (time.getHours() < 10 ? '0' + time.getHours() : time.getHours()) +
    ':' +
    (time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes()) +
    ':' +
    (time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds())
  );
}

export function excelTable(thd: string[], tbody: string, title: string, sheetTitle: string) {
  let thader = '';
  if (thd.length > 0) {
    thd.map((item: string) => {
      thader = thader + `<th>` + item + `</th>`;
    });
  }
  let tab_text = '<html xmlns:x="urn:schemas-microsoft-com:office:excel">';
  tab_text = tab_text + '<head><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">';
  tab_text = tab_text + '<xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>';
  tab_text = tab_text + '<x:Name>' + sheetTitle + '</x:Name>';
  tab_text = tab_text + '<x:WorksheetOptions><x:Panes></x:Panes></x:WorksheetOptions></x:ExcelWorksheet>';
  tab_text = tab_text + '</x:ExcelWorksheets></x:ExcelWorkbook></xml></head><body>';
  tab_text = tab_text + `<table border='1px' >`;
  tab_text = tab_text + `<tr><th style="text-align:right" colspan="` + thd.length + `">` + new Date() + `</th></tr>`;
  tab_text = tab_text + `<tr>` + thader + `</tr>` + tbody;
  tab_text = tab_text + '</table></body></html>';

  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  const fileName = title + '.xls';
  if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
    if (window.navigator.msSaveBlob) {
      const blob = new Blob([tab_text], {
        type: 'application/csv;charset=utf-8;',
      });
      navigator.msSaveBlob(blob, fileName);
    }
  } else {
    const blob2 = new Blob([tab_text], {
      type: 'application/csv;charset=utf-8;',
    });
    const elem = window.document.createElement('a');
    elem.href = window.URL.createObjectURL(blob2);
    elem.download = fileName;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  }
}

export function formatDate(current_datetime: Date) {
  const year = current_datetime.getFullYear();
  const month = current_datetime.getMonth() + 1;
  const day = current_datetime.getDate();
  const hour = current_datetime.getHours();
  const min = current_datetime.getMinutes();
  return (
    year +
    '-' +
    (month < 10 ? '0' + month : month) +
    '-' +
    (day < 10 ? '0' + day : day) +
    ' ' +
    (hour < 10 ? '0' + hour : hour) +
    ':' +
    (min < 10 ? '0' + min : min)
  );
}
