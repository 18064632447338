/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { createRef } from 'react';
// react library for routing
import { RouteComponentProps, Route, Switch, Redirect } from 'react-router-dom';
import Chart from 'chart.js';
import { chartOptions, parseOptions } from '../variables/charts.js';
import HeaderBar from '../components/Navbars/HeaderBar';
import Sidebar from '../components/Sidebar/Sidebar';

import { paths, routes } from '../routes.js';
import '../assets/css/common.scss';
import '../assets/css/mobile.scss';
import MemberDetail from '../views/pages/member/MemberDetail';
import LottiePage from '../components/Pages/Lottie';
import devLogo from '../assets/img/brand/mecute-textlogo.png';
import realLogo from '../assets/img/brand/mecute-real-logo.svg';

class Admin extends React.Component<RouteComponentProps> {
  private mainContent = createRef<HTMLDivElement>();
  state = {
    sidenavOpen: true,
    memberPopup: false,
  };

  componentDidUpdate(e: any) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      if (document.scrollingElement) {
        document.scrollingElement.scrollTop = 0;
      }
      if (this.mainContent.current) {
        this.mainContent.current.scrollTop = 0;
      }
    }
    if (window.location.search.includes('member') && !this.state.memberPopup) {
      this.setState({
        ...this.state,
        memberPopup: true,
      });
    }
    if (!window.location.search.includes('member') && this.state.memberPopup) {
      this.setState({
        ...this.state,
        memberPopup: false,
      });
    }
  }

  UNSAFE_componentWillMount() {
    const app = this;
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
    window.addEventListener('resize', function() {
      app.resizeSide();
    });
    this.resizeSide();
    if (window.location.search.includes('member') && !this.state.memberPopup) {
      this.setState({
        ...this.state,
        memberPopup: true,
      });
    }
  }

  resizeSide = () => {
    if (window.innerWidth <= 1024) {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-hidden');
      this.setState({
        ...this.state,
        sidenavOpen: false,
      });
    }
  };

  getRoutes = (routes: any) => {
    return routes.map((prop: any, key: number) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (paths.includes(prop.layout)) {
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            render={props => (
              <prop.component {...props} memberPopupOpen={this.memberPopupOpen} dataReload={this.state.memberPopup} />
            )}
          />
        );
      } else {
        return null;
      }
    });
  };

  getMainName = (path: any) => {
    if (path) {
      for (let i = 0; i < routes.length; i++) {
        if (this.props.location.pathname.indexOf(routes[i].layout) !== -1) {
          return routes[i].name;
        }
      }
    }
  };

  getSubName = (path: any) => {
    for (let i = 0; i < routes.length; i++) {
      const router = routes[i];
      if (this.props.location.pathname.indexOf(router.layout) !== -1) {
        if (router.views) {
          for (let a = 0; a < router.views.length; a++) {
            if (this.props.location.pathname.indexOf(router.views[a].path) !== -1) {
              return router.views[a].name;
            }
          }
        }
      }
    }
    return null;
  };

  // toggles collapse between mini sidenav and normal
  toggleSidenav = () => {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-hidden');
    } else {
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-hidden');
    }
    this.setState({
      ...this.state,
      sidenavOpen: !this.state.sidenavOpen,
    });
  };
  getNavbarTheme = () => {
    return this.props.location.pathname.indexOf('admin/alternative-dashboard') === -1 ? 'dark' : 'light';
  };

  sideToggle = () => {
    if (document.body.classList.contains('sideOpen')) {
      document.body.classList.remove('sideOpen');
    } else {
      document.body.classList.add('sideOpen');
    }
    this.setState({
      ...this.state,
      sidenavOpen: !this.state.sidenavOpen,
    });
  };

  memberPopupOpen = (pk: number) => {
    const scroll = document.documentElement.scrollTop;
    if (scroll > 0) {
      localStorage.setItem('scrollPosition', scroll.toString());
    }
    window.history.pushState(null, '', '?member=' + pk);
    this.setState({
      ...this.state,
      memberPopup: true,
    });
  };

  memberPopupClose = () => {
    window.history.pushState(null, '', window.location.pathname);
    this.setState({
      ...this.state,
      memberPopup: false,
    });
    if (localStorage.getItem('scrollPosition')) {
      const scrollPosition = Number(localStorage.getItem('scrollPosition'));
      localStorage.removeItem('scrollPosition');
      const _interval = setInterval(() => {
        if (!document.querySelector('.body-section')?.classList.contains('stopScroll')) {
          clearInterval(_interval);
          document.documentElement.scrollTop = scrollPosition;
          if (document.scrollingElement) {
            document.scrollingElement.scrollTop = 0;
          }
          if (this.mainContent.current) {
            this.mainContent.current.scrollTop = 0;
          }
        }
      }, 10);
    }
  };
  closeSidenav = () => {
    if (window.innerWidth < 1200) {
      // this.props.toggleSidenav()
    }
  };

  render() {
    return (
      <>
        <Sidebar
          {...this.props}
          toggleSidenav={this.toggleSidenav}
          sidenavOpen={this.state.sidenavOpen}
          routes={routes}
          logo={{
            innerLink: '/',
            imgSrc: process.env.REACT_APP_MODE === 'development' ? devLogo : realLogo,
            imgAlt: '...',
          }}
        />
        <div className="main-content" ref={this.mainContent} onClick={this.closeSidenav}>
          <HeaderBar
            {...this.props}
            theme={this.getNavbarTheme()}
            toggleSidenav={this.toggleSidenav}
            sidenavOpen={this.state.sidenavOpen}
          />
          <Switch>
            {this.getRoutes(routes)}
            <Redirect from="*" to="/member/dash" />
          </Switch>
          {this.state.memberPopup && (
            <MemberDetail
              pageMain={this.getMainName(this.props.location.pathname)}
              pageSub={this.getSubName(this.props.location.pathname)}
              memberPopupClose={this.memberPopupClose}
            />
          )}
        </div>
        <LottiePage />
        {this.state.sidenavOpen ? <div className="backdrop d-xl-none" onClick={this.toggleSidenav} /> : null}
      </>
    );
  }
}

export default Admin;
