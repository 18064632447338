import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';

import '../../../assets/css/member.scss';
import axios from '../../../axios/index';
import PageNav from '../../../components/Pages/PageNav';
import { numberWithCommas } from '../../../components/Pages/commonFunction';
import useStore from '../../../stores/useStore';
interface PayHistoryProps {
  userPk: number;
}

interface PayListsProps {
  pk: number;
  productName: string;
  productId: string;
  salesPriceKR: number;
  store: string;
  notification: string;
  autoRenewStatus: number;
  isSubscription: number;
  heart: number;
  eventHeart: number;
  isPayment: number;
  errorMessage?: string | null;
  purchased: string;
  expirationed: string;
  created: string;
}

export default function PayHistory(props: PayHistoryProps) {
  const { userState } = useStore();
  const [payLists, setPayLists] = useState<PayListsProps[]>();
  const [payPage, setPayPage] = useState({ current: 0, max: 0 });
  const [payFilter, setPayFilter] = useState({ type: '', value: '' });
  const listLeng = 100;

  const [filterInputs, setFilterInputs] = useState({
    product: '-1',
    store: '0',
    payment: '0',
  });
  const { product, store, payment } = filterInputs;

  const filterChange = (e: React.ChangeEvent<HTMLSelectElement>, type: string) => {
    const { value, name } = e.target;
    setFilterInputs({
      product: '-1',
      store: '0',
      payment: '0',
      [name]: value,
    });
    setPayFilter({ type: type, value: e.target.value });
    // getPayLists(0, type, e.target.value)
  };

  // const getPayPageInfo = (page: number) => {
  //   getPayLists(page)
  // }
  // const getPayLists = (page: number, option?: string, optionValue?: string) => {
  //   axios
  //     .get(
  //       `/member/` +
  //         props.userPk +
  //         `/purchased?` +
  //         (option ? option + `=` + optionValue : '') +
  //         `&start=` +
  //         page * listLeng +
  //         `&limit=` +
  //         listLeng,
  //     )
  //     .then((res) => {
  //       setPayLists(res.data.data.rows)
  //       setPayPage({
  //         current: page,
  //         max: Math.ceil(res.data.data.count / listLeng) ? Math.ceil(res.data.data.count / listLeng) : 1,
  //       })
  //     })
  // }

  // useEffect(() => {
  //   getPayLists(0)
  // }, [])

  return (
    <>
      {userState.user.permitions.gradeCode === 1 && <p>권한이 없습니다.</p>}
      {userState.user.permitions.gradeCode !== 1 && (
        <>
          <Table className="align-items-center table-flush text-center" responsive>
            <thead className="thead-light">
              <tr>
                <th style={{ width: '5%' }}>순번</th>
                <th style={{ width: '10.5%' }}>
                  결제 상품
                  <select
                    name="product"
                    value={product}
                    className="selectFont0"
                    onChange={e => {
                      filterChange(e, 'product');
                    }}>
                    <option value="0">전체</option>
                    <option value="1">프리미엄</option>
                    <option value="2">VIP</option>
                    <option value="3">하트풍선</option>
                    <option value="4">시그널</option>
                  </select>
                </th>
                <th style={{ width: '10.5%' }}>결제 금액</th>
                <th style={{ width: '10.5%' }}>
                  결제 방법
                  <select
                    name="store"
                    value={store}
                    className="selectFont0"
                    onChange={e => {
                      filterChange(e, 'store');
                    }}>
                    <option value="0">전체</option>
                    <option value="1">앱스토어</option>
                    <option value="2">구글플레이</option>
                  </select>
                </th>
                <th style={{ width: '11%' }}>NOTIFICATION</th>
                <th style={{ width: '10.5%' }}>결제/취소 일시</th>
                <th style={{ width: '10.5%' }}>구독자동연장</th>
                <th style={{ width: '10.5%' }}>구독시작일시</th>
                <th style={{ width: '10.5%' }}>구독만료일시</th>
                <th style={{ width: '10%' }}>
                  지급 여부
                  <select
                    name="payment"
                    value={payment}
                    className="selectFont0"
                    onChange={e => {
                      filterChange(e, 'payment');
                    }}>
                    <option value="0">전체</option>
                    <option value="1">O</option>
                    <option value="2">X</option>
                  </select>
                </th>
              </tr>
            </thead>
            <tbody>
              {/* <tr>
            <td colSpan={10}>{payLists === [] && 'true'}</td>
          </tr> */}
              {(payLists?.length === 0 || payLists?.length === undefined) && (
                <tr>
                  <td colSpan={10}>조회된 내역이 없습니다.</td>
                </tr>
              )}
              {payLists &&
                payLists?.length > 0 &&
                payLists.map((item: PayListsProps, idx) => {
                  return (
                    <tr key={idx + 'PayHistory'}>
                      <td>{payPage.current * listLeng + idx + 1}</td>
                      <td>{item.productName}</td>
                      <td>{numberWithCommas(item.salesPriceKR)}원</td>
                      <td>{item.store}</td>
                      <td>{item.notification}</td>
                      <td>{item.created.replace(/\-/g, '.')}</td>
                      <td>{item.autoRenewStatus === 1 ? 'O' : 'X'}</td>
                      <td>{item.purchased.replace(/\-/g, '.')}</td>
                      <td>{item.expirationed.replace(/\-/g, '.')}</td>
                      <td>{item.isPayment === 1 ? 'O' : 'X'}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          {/* <PageNav getPageInfo={getPayPageInfo} current={payPage.current} maxPage={payPage.max} /> */}
        </>
      )}
    </>
  );
}
