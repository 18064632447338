import React, { useEffect, useState } from 'react';
import { Col, Row, Button, Input, Table } from 'reactstrap';
import axios from '../../../axios/index';
import '../../../assets/css/member.scss';
import PageNav from '../../../components/Pages/PageNav';
import useStore from '../../../stores/useStore';
import { Observer } from 'mobx-react';

interface MemberDeviceVerificationProps {
  memberPopupOpen: (pk: number) => void;
  dataReload: boolean;
}

interface DeviceVerificationProps {
  pk: string;
  userPk: number;
  failedCount: number;
  phoneNumber: string;
  udid: string;
  isPassed: number;
  memo: string;
  deleted: string;
  updated: string;
  created: string;
}

export default function MemberDeviceVerification() {
  const [memberDeviceVerificationLists, setMemberDeviceVerificationLists] = useState([]);
  const [DeviceVerificationPage, setDeviceVerificationPage] = useState({ current: 0, max: 0 });
  const [DeviceVerificationFind, setDeviceVerificationFind] = useState({ search: '', keyword: '' });
  const [confirmPopupView, setConfirmPopupView] = useState<boolean>(false);
  // const [popupView, setPopupView] = useState<boolean>(false)

  const listLength = 100;

  const [inputs, setInputs] = useState({ search: '' });
  const { search } = inputs;
  const { modalState } = useStore();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const searchingKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      getMemberDeviceVerificationList(0, DeviceVerificationFind.search ? DeviceVerificationFind.search : 'tel', search);
      setInputs({ search: '' });
    }
  };

  const getPageInfo = (page: number) => {
    getMemberDeviceVerificationList(page);
  };

  const handleButtonClick = (item: DeviceVerificationProps) => {
    modalState.openUserDeviceVerificationConfirmModal(item.pk);
    setConfirmPopupView(true);
    return;
  };

  const getMemberDeviceVerificationList = async (page: number, search?: string, keyword?: string) => {
    if (search && keyword) {
      setDeviceVerificationFind({
        search: search,
        keyword: keyword,
      });
    } else {
      setDeviceVerificationFind({
        search: '',
        keyword: '',
      });
    }
    await axios
      .get(
        `/v1/user/device/verification/list` +
          (search && keyword ? `?search=${search}&keyword=${keyword}` : `?page=${page + 1}`),
      )
      .then(res => {
        modalState.openModal();
        const result = res.data;
        setMemberDeviceVerificationLists(result.items);
        setDeviceVerificationPage({
          current: page,
          max: result.meta.totalItems,
        });
      });
  };

  const handleClickConfirmModal = async () => {
    modalState.closeUserDeviceVerificationConfirmModal();
    await updateDeviceVerificationAsPass(modalState.userDeviceVerificationConfirmView.pk);
    getMemberDeviceVerificationList(0);
    return;
  };

  const updateDeviceVerificationAsPass = async (pk: string) => {
    const requestBody = {
      userDeviceVerificationPk: pk,
    };
    try {
      await axios.patch(`/v1/user/device/verification/`, pk).then(res => {
        if (res.data && res.data.result === 200) {
          // 뭔가 잘 통과 됐다고 팝업을 띄우고 싶은데 할 줄 모릅니다 ㅠㅠ
          console.log('통과됨');
        } else {
          // 통과시키기 API 에 문제가 있다고 하고 싶은데 할줄 모릅니다 ㅠㅠ
          console.log('통과 안됨');
        }
      });
    } catch (err) {
      console.log('catched server err:', err);
    }
  };

  useEffect(() => {
    getMemberDeviceVerificationList(0);
  }, [confirmPopupView]);

  return (
    <Observer>
      {() => (
        <div className="body-section">
          <div className="contentsContainer">
            <div className="pageInfo">
              <span className="text-muted">회원 / </span>
              <span className="text-primary">본인기기 인증</span>
            </div>
            <h1 className="pageTitle">본인기기 인증</h1>
            <ul className="contentsTab">
              <li className="active">본인기기 인증 리스트</li>
            </ul>
            <div className="contentsWrap">
              <Row className="mb-3">
                {
                  <>
                    <Col sm="2">
                      <Input
                        bssize="sm"
                        type="select"
                        value={DeviceVerificationFind.search}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setDeviceVerificationFind({ ...DeviceVerificationFind, search: e.target.value });
                        }}>
                        <option value="tel">전화번호</option>
                        <option value="userPk">유저 PK</option>
                      </Input>
                    </Col>
                    <Col sm="4">
                      <div className="includeBtnWrap">
                        <Input
                          bssize="sm"
                          type="text"
                          autoComplete="off"
                          name="search"
                          value={search}
                          onChange={onChange}
                          onKeyPress={searchingKeyPress}
                        />
                        <Button
                          bssize="sm"
                          disabled={!inputs.search}
                          className={inputs.search ? 'cursor' : 'notAllow'}
                          onClick={() => {
                            setInputs({ ...inputs, search: '' });
                          }}>
                          검색
                        </Button>
                      </div>
                    </Col>
                  </>
                }
              </Row>

              <div className="includeTable">
                <Table className="align-items-center table-flush text-center mt-2" responsive>
                  <thead>
                    <tr>
                      <th>Pk</th>
                      <th>User_Pk</th>
                      <th>전화번호</th>
                      <th>UDID</th>
                      <th>실패 횟수</th>
                      <th>통과 여부</th>
                      <th>업데이트 일자</th>
                      <th>인증 일자</th>
                    </tr>
                  </thead>
                  <tbody>
                    {memberDeviceVerificationLists.length === 0 ? (
                      <tr>
                        <td colSpan={10}>조회된 결과가 없습니다.</td>
                      </tr>
                    ) : (
                      memberDeviceVerificationLists.map((item: DeviceVerificationProps) => (
                        <tr key={item.pk}>
                          <td>{item.pk}</td>
                          <td>{item.userPk}</td>
                          <td>{item.phoneNumber}</td>
                          <td>{item.udid}</td>
                          <td>{item.failedCount}</td>
                          <td>{item.isPassed ? '성공' : '실패'}</td>
                          <td>{item.updated}</td>
                          <td>{item.created}</td>
                          <td>
                            {item.isPassed === 0 && (
                              <Button
                                bssize="sm"
                                color="default"
                                outline
                                onClick={() => {
                                  handleButtonClick(item);
                                }}>
                                통과시키기
                              </Button>
                            )}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
                <Row className="mt-1">
                  <Col>
                    <PageNav
                      getPageInfo={getPageInfo}
                      current={DeviceVerificationPage.current}
                      maxPage={DeviceVerificationPage.max}
                    />
                  </Col>
                </Row>
              </div>
            </div>
            {/* {modalState.view && popupView && <MecutingPopup data={matchedCouple} modalClose={() => modalClose()} />} */}
            {modalState.userDeviceVerificationConfirmView.view && confirmPopupView && (
              <div className="popupDefault">
                <div className="layer">
                  <p>{'통과시키기를 정말 하시겠습니까?'}</p>
                  <div className="btnWrap">
                    <Button
                      bssize="sm"
                      className="btn-default"
                      onClick={() => {
                        modalState.closeUserDeviceVerificationConfirmModal();
                        setConfirmPopupView(false);
                      }}>
                      아니오
                    </Button>
                    <Button
                      bssize="sm"
                      className=" btn-default"
                      onClick={async () => {
                        await handleClickConfirmModal();
                      }}>
                      네
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </Observer>
  );
}
