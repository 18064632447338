import React from 'react';
import { PaginationLink, Pagination, PaginationItem } from 'reactstrap';

interface pageNavProps {
  current: number;
  maxPage: number;
  getPageInfo: (page: number) => void;
}

export default function PageNav(props: pageNavProps) {
  const sendPageInfo = (page: number) => {
    props.getPageInfo(page);
  };
  const perPage = 10;

  return (
    <Pagination bssize="sm" className="pagination justify-content-center mb-0" listClassName="justify-content-end mb-0">
      <PaginationItem className={props.current === 0 ? 'disabled' : ''}>
        <PaginationLink
          onClick={() => {
            sendPageInfo(props.current - 1);
          }}>
          <i className="fas fa-angle-left" />
          <span className="sr-only">Previous</span>
        </PaginationLink>
      </PaginationItem>
      {[...Array(perPage)].map((page: undefined, idx: number) => {
        const middlePer = Math.ceil(perPage / 2);
        let count: number;
        count = props.current - middlePer < 0 ? idx : props.current - middlePer + idx;
        if (props.current + middlePer > props.maxPage) {
          count = props.maxPage - perPage + idx;
        }
        if (props.maxPage > count && count >= 0) {
          return (
            <PaginationItem key={idx} className={count === props.current ? 'active' : ''}>
              <PaginationLink
                onClick={() => {
                  sendPageInfo(count);
                }}>
                {count + 1}
              </PaginationLink>
            </PaginationItem>
          );
        }
      })}
      <PaginationItem className={props.current + 1 === props.maxPage ? 'disabled' : ''}>
        <PaginationLink
          onClick={() => {
            sendPageInfo(props.current + 1);
          }}>
          <i className="fas fa-angle-right" />
          <span className="sr-only">Next</span>
        </PaginationLink>
      </PaginationItem>
    </Pagination>
  );
}
