import React from 'react';
import { useHistory } from 'react-router';
import { Table } from 'reactstrap';
import { formatKoreanLocalTime, getS3SourceUri } from '../../../../../utils/utils';
import { EventBannersInfo } from '../../BannerSetting';

interface BannerListProps {
  eventBannersInfo: EventBannersInfo | null;
  exposureActivatedBannerIds: Array<number>;
}

function BannerList(props: BannerListProps) {
  const { eventBannersInfo, exposureActivatedBannerIds } = props;
  const history = useHistory();

  return (
    <div className="contentsWrap">
      <div className="contentsBox">
        <h2>이벤트 배너 리스트</h2>
        <div className="contents">
          <Table className="w-100 align-items-center table-flush text-center mb-3" responsive>
            <thead>
              <tr>
                <th style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>이미지</th>
                <th>ID</th>
                <th>생성시간</th>
                <th>노출시작 시간</th>
                <th>노출종료 시간</th>
                <th>valid</th>
                <th>상태</th>
              </tr>
            </thead>
            <tbody>
              {eventBannersInfo?.items.map(item => {
                return (
                  <tr key={item.id} className="pointer" onClick={() => history.push(`/event/banner?id=${item.id}`)}>
                    <td className="w-50">
                      <img
                        style={{ width: '304px', height: '70px' }}
                        src={getS3SourceUri(item.imageUrl)}
                        alt="이벤트 배너 이미지"
                      />
                    </td>
                    <td>{item.id}</td>
                    <td>{formatKoreanLocalTime(item.createdAt)}</td>
                    <td>{formatKoreanLocalTime(item.startedAt)}</td>
                    <td>{formatKoreanLocalTime(item.endedAt)}</td>
                    <td>{item.valid ? 'true' : 'false'}</td>
                    <td
                      style={{
                        color: exposureActivatedBannerIds.includes(item.id) ? 'blue' : 'red',
                      }}>
                      {exposureActivatedBannerIds.includes(item.id) ? '노출중' : '비노출'}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default BannerList;
