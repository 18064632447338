import React, { useEffect } from 'react';
import { Card, CardBody } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

import axios from '../../../axios/index';

import devLogo from '../../../assets/img/brand/mecute-textlogo.png';
import realLogo from '../../../assets/img/brand/mecute-real-logo.svg';

// core components
import useStore from '../../../stores/useStore';

function Login() {
  const history = useHistory();
  const { userState } = useStore();

  const firebaseConfig = {
    apiKey: 'AIzaSyCbzrl2oJGooKeOOurICVwhA9qlDSEMIgo',
    authDomain: 'mecute-dev.firebaseapp.com',
    databaseURL: 'https://mecute-dev.firebaseio.com',
    projectId: 'mecute-dev',
    storageBucket: 'mecute-dev.appspot.com',
    messagingSenderId: '800043755974',
    appId: '1:800043755974:web:bbbdd2eb730a3bf80a2ac7',
    measurementId: 'G-9XM6VVE2W0',
  };
  const firebaseConfigDev = {
    apiKey: 'AIzaSyCu_XLKdYZlrsVD__avRdFDARkYxmwxO6U',
    authDomain: 'mecute-test.firebaseapp.com',
    projectId: 'mecute-test',
    storageBucket: 'mecute-test.appspot.com',
    messagingSenderId: '719679035459',
    appId: '1:719679035459:web:98b8791237b46750cd1cf6',
    measurementId: 'G-1JZR5F07Q4',
  };

  // Initialize Firebase
  if (process.env.REACT_APP_MODE === 'development') {
    firebase.initializeApp(firebaseConfigDev);
  }
  if (process.env.REACT_APP_MODE === 'production') {
    firebase.initializeApp(firebaseConfig);
  }

  const auth = firebase.auth();
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.addScope('email');
  provider.setCustomParameters({ prompt: 'select_account' });

  const signInWithGoogle = () => {
    auth
      .signInWithPopup(provider)
      .then(async (result: any) => {
        auth.onAuthStateChanged(async firebaseUser => {
          if (firebaseUser) {
            // 토큰을 가져온다.
            const token = await firebaseUser.getIdToken();
            axios
              .post(`/v1/manager/sign-in`, undefined, {
                headers: {
                  token: token,
                },
              })
              .then((res: any) => {
                if (res.status === 201) {
                  const saveInfo = {
                    uid: result.user.uid,
                    email: result.additionalUserInfo.profile?.email,
                    name: result.additionalUserInfo.profile?.name,
                    accessToken: result.credential?.accessToken,
                    token,
                    // 푸시 및 앱 버전 관리 보존을 위한 하드코딩
                    permitions: {
                      grade: 'ADMIN1',
                      gradeCode: 5,
                      gradeStr: '관리자',
                      permission: [
                        {
                          code: 'M07-020-04000',
                          enable: 1,
                        },
                      ],
                    },
                  };
                  axios.defaults.headers.common['Authorization'] = `Bearer ` + saveInfo.accessToken;
                  axios.defaults.headers.common['token'] = saveInfo.token;
                  userState.saveUserInfo(saveInfo);
                  history.push('/member/dash');
                }
              })
              .catch((er: any) => {
                if (er.response) {
                  const error = er.response;
                  if (error.status === 500) {
                    if (error.data.msg === "Column 'managerRef' cannot be null") {
                      alert('등록되지 않은 계정입니다. 관리자에게 문의하세요.');
                    } else if (error.data.msg === 'manager_does_not_exist') {
                      alert('인증되지 않은 계정입니다. 메일함을 확인해 주세요.');
                    } else {
                      alert('잠시 후 다시 시도해주세요.' + error.data.msg);
                    }
                  }
                }
              });
            return token;
          }
        });
      })
      .catch(error => {
        alert(`firebase` + error.message);
      });
  };

  useEffect(() => {
    if (userState.user?.accessToken !== '' || localStorage.getItem('mecuteLogin')) {
      history.push('/member/dash');
    }
  }, []);

  return (
    <>
      <div className="loginContainer">
        <Card>
          <CardBody>
            <div className="text-center text-muted mb-3">
              <small>{process.env.REACT_APP_MODE === 'development' ? 'TEST' : 'REAL'} SERVER LOGIN</small>
            </div>
            <img src={process.env.REACT_APP_MODE === 'development' ? devLogo : realLogo} alt="Mecute" />
            <div className="loginBtn" onClick={signInWithGoogle}>
              구글로그인
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
}

export default Login;
