import { makeAutoObservable, toJS } from 'mobx';
export interface userStoreProps {
  email: string;
  name: string;
  accessToken: string;
  permitions: {
    grade: string;
    gradeCode: number;
    gradeStr: string;
    permition: { code: string; depth: number; enable: number }[];
  };
}
// TODO: 유저 스테이트 user.permitions, user.permitions.permition 관련 상태값들 다 네이밍 typo 수정 필요
const userState = makeAutoObservable({
  user: {
    email: '',
    name: '',
    accessToken: '',
    permitions: {
      grade: '',
      gradeCode: 0,
      gradeStr: '',
      permition: [{ code: '', depth: 0, enable: 0 }],
    },
  },

  saveUserInfo(data: userStoreProps) {
    localStorage.setItem('mecuteLogin', JSON.stringify(data));

    this.user = toJS(data);
  },
  modifyInfo(data: { code: string; depth: number; enable: number }[]) {
    const originData = localStorage.getItem('mecuteLogin');
    if (originData) {
      const reData = JSON.parse(originData);
      reData.permitions.permition = data;
      this.user.permitions.permition = data;
      localStorage.setItem('mecuteLogin', JSON.stringify(reData));
    }
  },
  clearUserInfo() {
    localStorage.removeItem('mecuteLogin');
    this.user = {
      email: '',
      name: '',
      accessToken: '',
      permitions: {
        grade: '',
        gradeCode: 0,
        gradeStr: '',
        permition: [{ code: '', depth: 0, enable: 0 }],
      },
    };
  },
});

export { userState };
