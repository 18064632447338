import React, { useState, useEffect } from 'react';
import { Col, Row, Button } from 'reactstrap';

import classnames from 'classnames';
import axiosOrigin from 'axios';
import axios from '../../../axios/index';
import PageNav from '../../../components/Pages/PageNav';
import Resource from '../../../utils/Resource';

interface PhotoCheckProps {
  memberPopupOpen: (pk: number) => void;
  dataReload: boolean;
}

interface Photo {
  pk: number;
  userPk: number;
  file: string;
  nick: string;
}
type PhotoMeta = {
  totalItems: number;
  itemCount: number;
  itemsPerPage: number;
  totalPages: number;
  currentPage: number;
};

enum TabType {
  FirstPhoto,
  NotFirstPhoto,
}
const ITEMS_PER_PAGE = 10;

export default function PhotoCheck(props: PhotoCheckProps) {
  const [activeTab, setActiveTab] = useState<TabType>(TabType.FirstPhoto);
  const [photoLists, setPhotoLists] = useState([]);
  const [photoMeta, setPhotoMeta] = useState<PhotoMeta | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(0);
  /** 선택된 Photo 리스트 */
  const [checkedList, setCheckedList] = useState<Photo[]>([]);

  const fetchPhotoLists = async (page: number, tab: TabType) => {
    try {
      const res = await axios.get(
        `/v1/user/profile/unevaluated/list?page=${page + 1}&limit=${ITEMS_PER_PAGE}&isFirst=${activeTab ===
          TabType.FirstPhoto}`,
      );

      if (res?.status === 200) {
        setPhotoLists(res.data.items);
        setPhotoMeta(res.data.meta);
        return;
      }
    } catch (err) {
      console.log('/v1/user/profile/unevaluated/list 에러: ', err);
    }
  };

  const handleCheckItemClick = (
    e: React.ChangeEvent<HTMLInputElement>,
    item: { userPk: number; pk: number; file: string; nick: string },
  ) => {
    if (e.target.checked) {
      setCheckedList(prev => [...prev, item]);
    } else {
      setCheckedList(prev => prev.filter(el => el.pk !== item.pk));
    }
  };

  const handleCheckAllItemsClick = () => {
    if (photoLists.length === checkedList.length) {
      setCheckedList([]);
      return;
    }

    setCheckedList(photoLists);
  };

  const memberDetailPopup = (pk: number) => {
    props.memberPopupOpen(pk);
  };

  const handleDeletePhotoClick = async () => {
    const isFirst = activeTab === TabType.FirstPhoto;
    try {
      const res = await axios.post(
        '/v1/user/profile/reject',
        {
          items: checkedList.map(el => {
            return {
              userPk: el.userPk,
              userProfileImagePk: el.pk,
            };
          }),
          isFirst,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      if (res?.status === 201) {
        alert('삭제되었습니다.');
        await fetchPhotoLists(currentPage, activeTab);
        setCheckedList([]);
      } else {
        alert('삭제에 실패하였습니다.');
      }
    } catch (err) {
      alert(`검수완료 요청 중 에러가 발생했습니다. ${JSON.stringify(err, null, 2)}`);
      console.log('에러에러: err : ', JSON.stringify(err, null, 2));
    }
  };

  const handleApprovePhotoClick = async () => {
    try {
      const res = await axios.post(`/v1/user/profile/${activeTab === TabType.FirstPhoto ? 'first/' : ''}approve`, {
        userProfileImagePks: checkedList.map(el => el.pk),
      });

      if (res?.status === 201) {
        alert('검수완료 처리 되었습니다.');
        await fetchPhotoLists(currentPage, activeTab);
        setCheckedList([]);
      } else {
        alert('검수완료에 실패하였습니다.');
      }
    } catch (err) {
      alert(`검수완료 요청 중 에러가 발생했습니다. ${JSON.stringify(err, null, 2)}`);
    }
  };

  /** fetch 사진 리스트 */
  useEffect(() => {
    fetchPhotoLists(currentPage, activeTab);
  }, [currentPage, activeTab]);

  /** 탭 이동시 초기화 */
  useEffect(() => {
    setCurrentPage(0);
    setCheckedList([]);
  }, [activeTab]);

  return (
    <>
      <div className="body-section">
        <div className="contentsContainer">
          <div className="pageInfo">
            <span className="text-muted">회원 / </span>
            <span className="text-primary">사진검수</span>
          </div>
          <h1 className="pageTitle">사진검수</h1>
          <ul className="contentsTab">
            <li
              className={activeTab === TabType.FirstPhoto ? 'active' : ''}
              onClick={() => setActiveTab(TabType.FirstPhoto)}>
              첫번째 사진
            </li>
            <li
              className={activeTab === TabType.NotFirstPhoto ? 'active' : ''}
              onClick={() => setActiveTab(TabType.NotFirstPhoto)}>
              첫번째 외 나머지 사진
            </li>
          </ul>
          <div className="contentsWrap">
            <>
              <div className="imgList">
                {/* 탭별 상단 notiMessage */}
                <Row className="mb-3">
                  <Col>
                    <span>
                      {activeTab === TabType.FirstPhoto
                        ? '유저의 첫 번째 프로필 사진입니다.'
                        : '유저의 첫 번째 사진을 제외한 프로필 사진입니다.'}
                      <br /> (마스크 가능, 얼굴보이는 전신사진 가능, 풍경 및 뒷모습 불가)
                    </span>
                  </Col>
                </Row>
                {/* 버튼영역 */}
                <Row>
                  <Col lg="6" xs="12">
                    <Button color="default" bssize="sm" outline id="checkBoxAll" onClick={handleCheckAllItemsClick}>
                      전체선택 ({checkedList.length}개 선택됨)
                    </Button>
                    <Button color="primary" bssize="sm" outline onClick={() => handleApprovePhotoClick()}>
                      검수완료
                    </Button>
                    <Button color="danger" bssize="sm" outline onClick={() => handleDeletePhotoClick()}>
                      삭제하기
                    </Button>
                  </Col>
                  <Col lg="2" xs="2" className="text-right pr-0">
                    <Button color="default" bssize="sm" outline onClick={() => setCheckedList([])}>
                      선택 전체 해제
                    </Button>
                  </Col>
                </Row>
                {/* 이미지 리스트 영역 */}
                <Row className="innerScroll">
                  <Col className="pr-0">
                    <ul className="pl-0">
                      {!photoLists.length ? (
                        <li>조회된 결과가 없습니다.</li>
                      ) : (
                        photoLists.map((item: Photo, idx: number) => {
                          return (
                            <li key={item.pk} style={{ width: 220 }}>
                              <div className={classnames('img')}>
                                <div className="checkbox custom-control custom-checkbox labelImg">
                                  <input
                                    className="custom-control-input"
                                    id={'checkBox' + item.pk}
                                    type="checkbox"
                                    checked={checkedList.some(el => el.pk === item.pk)}
                                    onChange={e => {
                                      handleCheckItemClick(e, item);
                                    }}
                                  />
                                  <label className="custom-control-label" htmlFor={'checkBox' + item.pk}>
                                    <img className="pointer" src={Resource.ImgDomain + item.file} />
                                  </label>
                                </div>
                              </div>
                              <div className="userInfo">
                                <div
                                  className={classnames('userImg', 'pointer')}
                                  style={{
                                    backgroundImage: item.file
                                      ? `url(` +
                                        (item.file.indexOf('imgProfileSquar') > -1
                                          ? Resource.DefaultImgDomain
                                          : Resource.ImgDomain) +
                                        item.file +
                                        `)`
                                      : '',
                                  }}
                                  onClick={() => {
                                    memberDetailPopup(item.userPk);
                                  }}></div>
                                <div className="userName">
                                  <span
                                    className="pointer"
                                    title={item.pk.toString()}
                                    onClick={() => {
                                      memberDetailPopup(item.userPk);
                                    }}>{`${item.nick} / userPk: ${item.userPk}`}</span>
                                </div>
                              </div>
                            </li>
                          );
                        })
                      )}
                    </ul>
                  </Col>
                </Row>
              </div>
            </>
          </div>
          {photoMeta && (
            <PageNav current={currentPage} maxPage={photoMeta.totalPages as number} getPageInfo={setCurrentPage} />
          )}
        </div>
      </div>
    </>
  );
}
