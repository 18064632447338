/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { createRef } from 'react';
// react library for routing
import { Route, Switch, Redirect } from 'react-router-dom';
import { routes } from '../routes.js';

interface routesProp {
  state: string;
  collapse: boolean;
  component: any;
  layout: string;
  name: string;
  path: string;
  views: any;
  complete?: boolean;
  icon?: string;
  notshowing?: boolean;
}

class Auth extends React.Component {
  private mainContent = createRef<HTMLDivElement>();
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    if (document.scrollingElement) {
      document.scrollingElement.scrollTop = 0;
    }
    if (this.mainContent.current) {
      this.mainContent.current.scrollTop = 0;
    }
    document.body.classList.add('bg-default');
  }
  componentWillUnmount() {
    document.body.classList.remove('bg-default');
  }
  componentDidUpdate(e: any) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      if (document.scrollingElement) {
        document.scrollingElement.scrollTop = 0;
      }
      if (this.mainContent.current) {
        this.mainContent.current.scrollTop = 0;
      }
    }
  }
  getRoutes = (routes: any) => {
    return routes.map((prop: routesProp, key: number) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === '/auth') {
        return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };
  render() {
    return (
      <>
        <div className="main-content ml-0" ref={this.mainContent}>
          <Switch>
            {this.getRoutes(routes)}
            <Redirect from="*" to="/auth/login" />
          </Switch>
        </div>
      </>
    );
  }
}

export default Auth;
