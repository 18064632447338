import { userState } from './UserState';
import { modalState } from './ModalState';

class RootStore {
  constructor() {
    this.userState = new userState(this);
  }
}

export default {
  RootStore,
  userState,
  modalState,
};
