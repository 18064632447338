import React, { useState, useEffect } from 'react';
import { Col, Row, Button, Card, CardBody, CardTitle, UncontrolledTooltip } from 'reactstrap';
import { userState } from '../../../stores/UserState';
import axios from '../../../axios/index';
import '../../../assets/css/member.scss';
import { numberWithCommas } from '../../../components/Pages/commonFunction';

interface memberStatsProps {
  googleMale: number;
  googleFemale: number;
  iosMale: number;
  iosFemale: number;
  todaySignUpMale: number;
  todaySignUpFemale: number;
  activeMale: number;
  activeFemale: number;
  todayWithdrawalMale: number;
  todayWithdrawalFemale: number;
  todayDormantMale: number;
  todayDormantFemale: number;
}

export default function MemberDash() {
  const [memberStats, setMemberStats] = useState<memberStatsProps | null>();
  useEffect(() => {
    axios.get(`/v1/member/statistics`).then(res => {
      if (res === undefined) {
        userState.clearUserInfo();
        window.location.href = '/auth/login';
        localStorage.removeItem('mecuteLastActiveTime');
        return;
      }
      setMemberStats(res.data);
    });
  }, []);

  return (
    <>
      <div className="body-section">
        <div className="contentsContainer">
          <div className="pageInfo">
            <span className="text-muted">회원 / </span>
            <span className="text-primary">대시보드</span>
          </div>
          <h2 className="pageTitle">
            회원 정보
            <Button className="linkBtn rightBtn" bssize="sm" color="default" outline>
              <a href="https://firebase.google.com/" target="_blank" rel="noreferrer">
                회원 현황 확인
              </a>
            </Button>
          </h2>
          <div className="contentsWrap mb-4">
            <Row>
              <Col xl="3" lg="6">
                <Card className="card-stats h-100">
                  <CardBody>
                    <Row>
                      <div className="text-center col mb-0">
                        <CardTitle tag="h5" className="text-left text-uppercase text-muted mb-0">
                          총 회원 수
                          <span className="ml-2 tip float-right" id="tooltip01">
                            <small>
                              <i className="far fa-question-circle"></i>
                            </small>
                            <UncontrolledTooltip delay={0} trigger="hover focus" target="tooltip01">
                              현재까지 가입 이력이 있는 모든 회원 수
                            </UncontrolledTooltip>
                          </span>
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {memberStats &&
                            numberWithCommas(
                              memberStats.googleMale +
                                memberStats.googleFemale +
                                memberStats.iosMale +
                                memberStats.iosFemale,
                            )}{' '}
                          <small>명</small>
                        </span>
                        <div>
                          <small className="align-top">
                            google play -
                            {memberStats && numberWithCommas(memberStats.googleMale + memberStats.googleFemale)}명 (
                            <span className="text-primary">
                              {memberStats && numberWithCommas(memberStats.googleMale)}
                            </span>
                            <span> /</span>{' '}
                            <span className="text-danger">
                              {memberStats && numberWithCommas(memberStats.googleFemale)}
                            </span>
                            )
                          </small>
                        </div>
                        <div>
                          <small className="align-top">
                            app store -{memberStats && numberWithCommas(memberStats.iosMale + memberStats.iosFemale)}명
                            (
                            <span className="text-primary">{memberStats && numberWithCommas(memberStats.iosMale)}</span>
                            <span> /</span>{' '}
                            <span className="text-danger">
                              {memberStats && numberWithCommas(memberStats.iosFemale)}
                            </span>
                            )
                          </small>
                        </div>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="3" lg="6">
                <Card className="card-stats h-100 backgroundPink">
                  <CardBody>
                    <Row>
                      <div className="text-center col">
                        <CardTitle tag="h5" className="text-left text-uppercase text-muted mb-0">
                          신규(New)
                          <span className="ml-2 tip float-right" id="tooltip02">
                            <small>
                              <i className="far fa-question-circle"></i>
                            </small>
                            <UncontrolledTooltip delay={0} trigger="hover focus" target="tooltip02">
                              최근 24시간 기준 새로 가입한 회원 수 - 탈퇴
                            </UncontrolledTooltip>
                          </span>
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {memberStats && numberWithCommas(memberStats.todaySignUpMale + memberStats.todaySignUpFemale)}{' '}
                          <small>명</small>
                        </span>
                        <br />
                        <span className="ml-2">
                          <small className="text-primary">
                            남 ({memberStats && numberWithCommas(memberStats.todaySignUpMale)})
                          </small>{' '}
                          <small>/</small>{' '}
                          <small className="text-danger">
                            여 ({memberStats && numberWithCommas(memberStats.todaySignUpFemale)})
                          </small>
                        </span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="3" lg="6">
                <Card className="card-stats h-100">
                  <CardBody>
                    <Row>
                      <div className="text-center col">
                        <CardTitle tag="h5" className="text-left text-uppercase text-muted mb-0">
                          진성회원수
                          <span className="ml-2 tip float-right" id="tooltip03">
                            <small>
                              <i className="far fa-question-circle"></i>
                            </small>
                            <UncontrolledTooltip delay={0} trigger="hover focus" target="tooltip03">
                              총 회원 수 - (탈퇴+휴면+블랙리스트)
                            </UncontrolledTooltip>
                          </span>
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {/* 활동회원 수 = 총회원 수 - ( 휴면 + 탈퇴) */}
                          {memberStats && numberWithCommas(memberStats.activeMale + memberStats.activeFemale)}
                          <small>명</small>
                        </span>
                        <br />
                        <span className="ml-2">
                          <small className="text-primary">
                            남 ({memberStats && numberWithCommas(memberStats.activeMale)})
                          </small>{' '}
                          <small>/</small>{' '}
                          <small className="text-danger">
                            여 ({memberStats && numberWithCommas(memberStats.activeFemale)})
                          </small>
                        </span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              <Col xl="3" lg="6">
                <Card className="card-stats h-100">
                  <CardBody>
                    <Row>
                      <div className="text-center col mb-0">
                        <CardTitle tag="h5" className="text-left text-uppercase text-muted mb-0 ">
                          탈퇴
                          <span className="ml-2 tip float-right" id="tooltip10">
                            <small>
                              <i className="far fa-question-circle"></i>
                            </small>
                            <UncontrolledTooltip delay={0} trigger="hover focus" target="tooltip10">
                              최근 24시간 기준 탈퇴한 총 회원 수
                            </UncontrolledTooltip>
                          </span>
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {memberStats &&
                            numberWithCommas(memberStats.todayWithdrawalMale + memberStats.todayWithdrawalFemale)}{' '}
                          <small>명</small>
                        </span>
                        <br />
                        <span className="ml-2">
                          <small className="text-primary">
                            남 ({memberStats && numberWithCommas(memberStats.todayWithdrawalMale)})
                          </small>{' '}
                          <small>/</small>{' '}
                          <small className="text-danger">
                            여 ({memberStats && numberWithCommas(memberStats.todayWithdrawalFemale)})
                          </small>
                        </span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="3" lg="6">
                <Card className="card-stats h-100">
                  <CardBody>
                    <Row>
                      <div className="text-center col mb-0">
                        <CardTitle tag="h5" className="text-left text-uppercase text-muted mb-0 ">
                          휴면
                          <span className="ml-2 tip float-right" id="tooltip11">
                            <small>
                              <i className="far fa-question-circle"></i>
                            </small>
                            <UncontrolledTooltip delay={0} trigger="hover focus" target="tooltip11">
                              오늘 기준 휴면한 총 회원 수
                            </UncontrolledTooltip>
                          </span>
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {memberStats &&
                            numberWithCommas(memberStats.todayDormantMale + memberStats.todayDormantFemale)}{' '}
                          <small>명</small>
                        </span>
                        <br />
                        <span className="ml-2">
                          <small className="text-primary">
                            남 ({memberStats && numberWithCommas(memberStats.todayDormantMale)})
                          </small>{' '}
                          <small>/</small>{' '}
                          <small className="text-danger">
                            여 ({memberStats && numberWithCommas(memberStats.todayDormantFemale)})
                          </small>
                        </span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}
