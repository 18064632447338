import React, { useEffect, useState } from 'react';
import { Row, Col, FormGroup, Card, CardBody, CardTitle, Table, Button, Input } from 'reactstrap';
import { useHistory } from 'react-router';
import axios from '../../../axios/index';
import PageNav from '../../../components/Pages/PageNav';
import Resource from '../../../utils/Resource';
import DateSetting from '../../../components/Pages/DatePicker';
import useStore from '../../../stores/useStore';
import { numberWithCommas } from '../../../components/Pages/commonFunction';
import { excelTable } from '../../../components/Pages/commonFunction';
import dayjs from 'dayjs';

interface payDashProps {
  memberPopupOpen: (pk: number) => void;
}

type FilterType = {
  gender: 'all' | 'male' | 'female';
};

interface ItemProps {
  withdrawalTime: string;
  pk: number;
  nick: string;
  gender: string;
  profileImage: string;
}

interface ListProps {
  items: ItemProps[];
  meta: {
    currentPage: number;
    itemCount: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  };
}

const initialFilterStatus: FilterType = {
  gender: 'all',
};

export default function WithdrawalMember(props: payDashProps) {
  const { userState } = useStore();
  const [pickDate, setPickDate] = useState({ pin: '', start: '', limit: '' });
  const [withdrawStatus, setWithdrawStatus] = useState({
    total: 0,
    male: 0,
    female: 0,
  });
  const [withdrawLists, setWithdrawLists] = useState<ListProps>({
    items: [],
    meta: { currentPage: 0, itemCount: 0, itemsPerPage: 0, totalItems: 0, totalPages: 0 },
  });
  const [listPage, setListPage] = useState({ current: 1, max: 0 });
  const [filterValue, setFilterValue] = useState<FilterType>(initialFilterStatus);
  const [withdrawalNickSearch, setWithdrawalNickSearch] = useState({ nick: '', search: '' });
  const [inputs, setInputs] = useState({ search: '' });

  const LIST_LENGTH = 50;

  const history = useHistory();

  //엑셀다운로드
  const excelDown = (id: string, title: string) => {
    let table = '';
    const thead = ['No', '탈퇴 시간', '닉네임', '성별'];

    withdrawLists.items.map((item: ItemProps, idx: number) => {
      const num = '<td>' + (idx + 1) + '</td>';
      const withdrawalTime = '<td>' + dayjs(item.withdrawalTime).format('YYYY-MM-DD HH:mm:ss') + '</td>';
      const nick = '<td>' + item.nick + '</td>';
      const gender = '<td>' + item.gender + '</td>';

      table = table + '<tr>' + num + withdrawalTime + nick + gender + '</tr>';
    });

    excelTable(thead, table, title, '탈퇴');
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value, name } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  //검색 엔터
  const searchingKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      getWithdrawList(1, inputs.search);
      setInputs({ ...inputs, search: '' });
      setFilterValue({ gender: 'all' });
    }
  };

  const getDate = (date?: string) => {
    if (date) {
      const dateParse = JSON.parse(date);
      setPickDate({
        pin: dateParse.pinDate,
        start: dateParse.startDate,
        limit: dateParse.endDate,
      });
    }
  };

  //탈퇴 통계
  const getWithdrawStatus = () => {
    axios
      .get(
        '/member/withdrawal/statistics?startDate=' +
          pickDate.start.replace(/\./g, '-') +
          '&endDate=' +
          pickDate.limit.replace(/\./g, '-'),
      )
      .then(res => {
        setWithdrawStatus(res.data);
      })
      .catch(err => {
        console.log('탈퇴 회원 통계 GET 에러: ', err?.code, err?.message);
      });
  };

  const getPageInfo = (page: number) => {
    getWithdrawList(page);
  };

  //탈퇴 리스트
  const getWithdrawList = (page: number, search?: string, filter?: string, filterValue?: string | number) => {
    if (search) {
      setWithdrawalNickSearch({ ...withdrawalNickSearch, nick: search });
    } else {
      setWithdrawalNickSearch({ ...withdrawalNickSearch, nick: '' });
    }
    axios
      .get(
        '/member/withdrawal/list?page=' +
          page +
          '&startDate=' +
          pickDate.start.replace(/\./g, '-') +
          '&endDate=' +
          pickDate.limit.replace(/\./g, '-') +
          (search ? '&nick=' + search : '') +
          (filter && filterValue ? '&' + filter + '=' + filterValue : ''),
      )
      .then(res => {
        const result = res.data;
        if (result.meta.itemCount > 0) setWithdrawLists(result);
        else
          setWithdrawLists({
            items: [],
            meta: { currentPage: 0, itemCount: 0, itemsPerPage: 0, totalItems: 0, totalPages: 0 },
          });
        setListPage({
          current: page - 1,
          max: result.meta.totalPages,
        });
      })
      .catch(err => {
        console.log('탈퇴 회원 리스트 GET 에러: ', err?.code, err?.message);
      });
  };

  //탈퇴 필터값 변경
  const filterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value, name } = e.target;
    setFilterValue({ ...initialFilterStatus, [name]: value });
    getWithdrawList(1, inputs.search, name, value);
  };

  useEffect(() => {
    //날짜변경되면 필터&검색 초기화
    if (pickDate.pin) {
      getWithdrawStatus();
      getWithdrawList(1);
      setFilterValue({ gender: 'all' });
      setInputs({ search: '' });
    }
  }, [pickDate]);

  useEffect(() => {
    getDate();
  }, []);

  return (
    <>
      <div className="body-section">
        <div className="contentsContainer">
          <div className="pageInfo">
            <span className="text-muted">회원 / </span>
            <span className="text-primary">탈퇴</span>
          </div>
          <h1 className="pageTitle">탈퇴</h1>
          <div className="contentsWrap">
            {userState.user.permitions.gradeCode === 1 && <p>권한이 없습니다.</p>}
            {userState.user.permitions.gradeCode !== 1 && (
              <>
                <Row>
                  <Col md="10">
                    <FormGroup className="row mb-0">
                      <DateSetting getDate={getDate} />
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <Button
                      className="float-right"
                      bssize="sm"
                      color="default"
                      outline
                      onClick={() => excelDown('table', '탈퇴')}>
                      <i className="fas fa-file-excel"></i>
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <Card className="card-stats h-100">
                      <CardBody>
                        <Row className="border-bottom">
                          <Col className="col text-center">
                            <CardTitle tag="h5" className="text-left text-uppercase text-muted mb-0">
                              탈퇴
                            </CardTitle>
                            <span className="h1 font-weight-bold mb-0">
                              {numberWithCommas(withdrawStatus.total)}
                              <small>건</small>
                            </span>
                          </Col>
                        </Row>
                        <Row className="pt-4">
                          <Col lg="6">
                            <CardTitle tag="h5" className="text-left text-uppercase text-primary mb-0">
                              남
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {numberWithCommas(withdrawStatus.male)}
                              <small>건</small>
                            </span>
                          </Col>
                          <Col lg="6">
                            <CardTitle tag="h5" className="text-left text-uppercase text-danger mb-0">
                              여
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {numberWithCommas(withdrawStatus.female)}
                              <small>건</small>
                            </span>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </>
            )}
          </div>
          <div className="contentsWrap">
            {userState.user.permitions.gradeCode === 1 && <p>권한이 없습니다.</p>}
            {userState.user.permitions.gradeCode !== 1 && (
              <>
                <h2>탈퇴 내역</h2>
                <div className="includeTable">
                  <Row>
                    <Col md="7" xs="4"></Col>
                    <Col md="5" xs="8">
                      <div className="includeBtnWrap">
                        <Input
                          bssize="sm"
                          type="text"
                          autoComplete="off"
                          name="search"
                          placeholder="닉네임을 입력하세요."
                          value={inputs.search}
                          onChange={onChange}
                          onKeyPress={searchingKeyPress}
                        />
                        <Button
                          bssize="sm"
                          disabled={!inputs.search}
                          className={inputs.search ? 'cursor' : 'notAllow'}
                          onClick={() => {
                            getWithdrawList(1, inputs.search);
                            setInputs({ ...inputs, search: '' });
                            setFilterValue({
                              gender: 'all',
                            });
                          }}>
                          검색
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {withdrawalNickSearch.nick.length > 0 && (
                        <div>
                          <small>'{withdrawalNickSearch.nick}'에 대해 검색한 결과입니다.</small>
                          <Button
                            bssize="sm"
                            className="float-right"
                            onClick={() => {
                              getWithdrawList(1);
                            }}>
                            전체내역
                          </Button>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Table className="align-items-center table-flush text-center" responsive>
                    <thead>
                      <tr>
                        <th>순번</th>
                        <th>탈퇴 시간</th>
                        <th>프로필</th>
                        <th>닉네임</th>
                        <th>
                          성별
                          <select
                            className="selectFont0"
                            name="gender"
                            onChange={e => filterChange(e)}
                            value={filterValue.gender}>
                            <option value={'all'}>전체</option>
                            <option value={'male'}>M</option>
                            <option value={'female'}>F</option>
                          </select>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {withdrawLists.meta.itemCount === 0 && (
                        <tr>
                          <td colSpan={9}>조회된 내역이 없습니다.</td>
                        </tr>
                      )}
                      {withdrawLists.items.map((item: ItemProps, itemIdx: number) => (
                        <tr key={'withdrawLists' + itemIdx + 'item' + itemIdx}>
                          <td>{itemIdx + listPage.current * LIST_LENGTH + 1}</td>
                          <td>{dayjs(item.withdrawalTime).format('YYYY-MM-DD HH:mm:ss')}</td>
                          <td>
                            {' '}
                            <span
                              className={'userImg pointer'}
                              style={{
                                backgroundImage: item.profileImage
                                  ? 'url(' +
                                    (item.profileImage.indexOf('imgProfileSquar') > -1
                                      ? Resource.DefaultImgDomain
                                      : Resource.ImgDomain) +
                                    item.profileImage +
                                    ')'
                                  : '',
                              }}></span>
                          </td>
                          <td>
                            <a
                              className="pointer"
                              href={window.location.href + '?member=' + item.pk}
                              target="_blank"
                              rel="noreferrer">
                              {item.nick}
                            </a>
                          </td>
                          <td>{item.gender}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Row className="mt-4">
                    <Col>
                      <PageNav
                        getPageInfo={(page: number) => getWithdrawList(page + 1)}
                        current={listPage.current}
                        maxPage={listPage.max}
                      />
                    </Col>
                  </Row>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
