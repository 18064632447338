import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { throttle } from 'lodash';
import { Col, Row, Button } from 'reactstrap';
import axiosInstance from '../../../axios';
import PageNav from '../../../components/Pages/PageNav';
import { getS3SourceUri } from '../../../utils/utils';

interface NotRankedItem {
  userPk: number;
  nick: string;
  age: number;
  sex: 'male' | 'female';
  imageUrl: string;
  isDormant: boolean;
  createdAt: string;
}
interface RankedItem extends NotRankedItem {
  rank: 'A' | 'B' | 'C' | 'D';
}
interface List {
  items: (NotRankedItem & RankedItem)[];
  meta: {
    totalItems: number;
    itemCount: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
  };
}
type Filter = {
  key: FilterQuery;
  value: string;
};
type ConfirmPopUp = {
  isVisible: boolean;
  rank: 'A' | 'B' | 'C' | 'D';
};

enum Tab {
  NoRank = 'noRank',
  Rank = 'rank',
}
enum RankUserTab {
  ALL = 'all',
  // rank 등록 API 랭크 body 값을 대문자로 보내기 때문에 카멜케이스가 아닌 대문자로 할당
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
}
enum FilterQuery {
  Nick = 'nick',
  Tel = 'tel',
}

const ITEMS_PER_PAGE = 100;

function RankPage() {
  const [activeTab, setActiveTab] = useState<Tab>(Tab.NoRank);
  /** rank 유저 내 등급별 모아보기 탭 */
  const [activeRankTab, setActiveRankTab] = useState<RankUserTab>(RankUserTab.ALL);
  const [list, setList] = useState<List | null>(null);
  const [selectedPks, setSelectedPks] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [filter, setFilter] = useState<Filter | null>(null);
  const [confirmPopUp, setConfirmPopUp] = useState<ConfirmPopUp>({
    isVisible: false,
    rank: 'A',
  });
  const { register, handleSubmit } = useForm();

  const getNotRankedList = useCallback(async (page: number, filter: Filter | null) => {
    try {
      const result = await axiosInstance.get(
        `/v1/user/unrank/list?page=${page + 1}&limit=${ITEMS_PER_PAGE}${
          !!filter?.value ? `&${filter.key}=${filter.value}` : ''
        }`,
      );
      if (result?.status !== 200) {
        alert('통신 중 에러가 발생했습니다. 에러가 지속될 경우 개발팀에 문의해주세요.');
        return;
      }

      setList(result.data);
    } catch (err) {
      console.error('회원등급 리스트 조회 에러: ', err);
    }
  }, []);

  const getRankedList = useCallback(async (page: number, filter: Filter | null, targetRank: RankUserTab) => {
    try {
      const result = await axiosInstance.get(
        `/v1/user/rank/list?page=${page + 1}&limit=${ITEMS_PER_PAGE}${
          !!filter?.value ? `&${filter.key}=${filter.value}` : ''
        }${targetRank === RankUserTab.ALL ? '' : `&rank=${targetRank}`}`,
      );

      if (result?.status !== 200) {
        alert('통신 중 에러가 발생했습니다. 에러가 지속될 경우 개발팀에 문의해주세요.');
        return;
      }

      setList(result.data);
    } catch (err) {
      console.error(err);
    }
  }, []);

  const rankUser = useCallback(
    async (userPks: number[], rank: 'A' | 'B' | 'C' | 'D') => {
      try {
        if (!userPks.length) {
          alert('선택된 유저가 없습니다.');
          return;
        }

        const result = await axiosInstance.post('/v1/user/rank', {
          userPks,
          rank,
        });

        if (result?.status !== 201) {
          alert('등급 매기기에 실패했습니다. 동일 현상이 반복되는 경우 개발팀에 문의해주세요.');
          return;
        }

        // 초기화 및 리스트 갱신
        setSelectedPks([]);
        if (activeTab === Tab.Rank) {
          getRankedList(currentPage, filter, activeRankTab);
        } else {
          getNotRankedList(currentPage, filter);
        }
      } catch (err) {
        alert('등급 지정 중 에러가 발생했습니다. 동일 현상이 반복되는 경우 개발팀에 문의해주세요.');
        console.error('등급지정 에러: ', err);
      }
    },
    [currentPage, filter, activeRankTab],
  );

  const handleClassRegisterButtonClick = (rank: 'A' | 'B' | 'C' | 'D') => {
    if (!selectedPks.length) {
      alert('선택된 유저가 없어 등급지정이 불가능합니다.');
      return;
    }

    setConfirmPopUp({
      rank,
      isVisible: true,
    });
  };

  const handleRegisterRankConfirmButtonClick = throttle(async () => {
    await rankUser(selectedPks, confirmPopUp.rank);
    setConfirmPopUp(prev => ({ ...prev, isVisible: false }));
  }, 1000);

  const handleSelectAllButtonClick = () => {
    if (!list) {
      alert('불러온 리스트가 없습니다.');
      return;
    }

    if (list.meta.itemCount === selectedPks.length) {
      setSelectedPks([]);
    } else {
      setSelectedPks(list.items.map(item => item.userPk));
    }
  };

  const resetCurrentPageAndSelectedPks = () => {
    setCurrentPage(0);
    setSelectedPks([]);
  };

  const getColor = (rank: 'A' | 'B' | 'C' | 'D') => {
    switch (rank) {
      case 'A':
        return '#FAC25B';
      case 'B':
        return '#FF239E';
      case 'C':
        return '#40A3FF';
      case 'D':
        return '#000000';
    }
  };

  /** 페이지네이션 API 호출 */
  useEffect(() => {
    if (activeTab === Tab.Rank) {
      getRankedList(currentPage, filter, activeRankTab);
    } else {
      getNotRankedList(currentPage, filter);
    }
  }, [currentPage, filter, activeTab, activeRankTab]);

  return (
    <div className="body-section">
      <div className="contentsContainer">
        <div className="pageInfo">
          <span className="text-muted">회원 / </span>
          <span className="text-primary">회원등급</span>
        </div>
        <h1 className="pageTitle">회원등급</h1>
        <ul className="contentsTab">
          <li
            className={activeTab === Tab.NoRank ? 'active' : ''}
            onClick={() => {
              setActiveTab(Tab.NoRank);
              resetCurrentPageAndSelectedPks();
            }}>
            등급미정 유저
          </li>
          <li
            className={activeTab === Tab.Rank ? 'active' : ''}
            onClick={() => {
              setActiveTab(Tab.Rank);
              setActiveRankTab(RankUserTab.ALL);
              resetCurrentPageAndSelectedPks();
            }}>
            등급유저
          </li>
        </ul>
        <div
          className="contentsWrap"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <div style={{ display: 'flex', width: '100%', maxWidth: 1900, justifyContent: 'space-between' }}>
            <div>
              <p style={{ margin: 0, padding: 0, fontWeight: 600, color: '#666666' }}>회원 클래스 프로필 노출 기준</p>
              <p style={{ margin: 0, padding: 0, fontWeight: 600, color: '#666666' }}>
                A class - 최우선으로 데일리 카드에 노출
              </p>
              <p style={{ margin: 0, padding: 0, fontWeight: 600, color: '#666666' }}>
                B class - A등급 이후 순차적으로 노출
              </p>
              <p style={{ margin: 0, padding: 0, fontWeight: 600, color: '#666666' }}>
                C class - B등급 이후 순차적으로 노출
              </p>
              <p style={{ margin: 0, padding: 0, fontWeight: 600, color: '#666666' }}>D class - 노출 안됨</p>
            </div>
            <form
              onSubmit={handleSubmit(async (data: any) => {
                try {
                  setFilter({ key: data.filterCategory, value: data.filterValue });
                  resetCurrentPageAndSelectedPks();
                } catch (err) {
                  console.error('필터검색 에러', err);
                }
              })}>
              <select
                {...register('filterCategory')}
                style={{ padding: 10, border: '1px solid black', borderRadius: 8 }}>
                <option value={FilterQuery.Nick}>닉네임</option>
                <option value={FilterQuery.Tel}>연락처</option>
              </select>
              <input
                {...register('filterValue')}
                type="text"
                placeholder="검색어 입력.."
                style={{
                  marginLeft: 4,
                  padding: 10,
                  border: '1px solid black',
                  borderRadius: 8,
                }}
              />
              <button
                style={{
                  marginLeft: 8,
                  padding: '10px 20px',
                  border: 0,
                  borderRadius: 8,
                  background: '#000',
                  color: '#FFF',
                }}
                type="submit">
                검색
              </button>
            </form>
          </div>
          {/* 버튼 box */}
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-end',
              gap: 10,
            }}>
            <button
              style={{
                padding: 16,
                border: '2px solid #000000',
                borderRadius: 10,
                backgroundColor: '#FFF',
                color: '#000000',
                cursor: 'pointer',
              }}
              onClick={handleSelectAllButtonClick}>{`전체 선택(${selectedPks.length}개 선택됨)`}</button>
            <button
              style={{
                padding: 16,
                border: '2px solid #000000',
                borderRadius: 10,
                backgroundColor: '#FFF',
                color: '#000000',
                cursor: 'pointer',
              }}
              onClick={() => setSelectedPks([])}>
              전체 선택 해제
            </button>
            <button
              style={{
                padding: 16,
                border: `2px solid ${getColor(RankUserTab.A)}`,
                borderRadius: 10,
                backgroundColor: '#FFF',
                color: getColor(RankUserTab.A),
                cursor: 'pointer',
              }}
              onClick={() => {
                handleClassRegisterButtonClick(RankUserTab.A);
              }}>
              A 클래스
            </button>
            <button
              style={{
                padding: 16,
                border: `2px solid ${RankUserTab.B}`,
                borderRadius: 10,
                backgroundColor: '#FFF',
                color: getColor(RankUserTab.B),
                cursor: 'pointer',
              }}
              onClick={() => {
                handleClassRegisterButtonClick(RankUserTab.B);
              }}>
              B 클래스
            </button>
            <button
              style={{
                padding: 16,
                border: `2px solid ${getColor(RankUserTab.C)}`,
                borderRadius: 10,
                backgroundColor: '#FFF',
                color: getColor(RankUserTab.C),
                cursor: 'pointer',
              }}
              onClick={() => {
                handleClassRegisterButtonClick(RankUserTab.C);
              }}>
              C 클래스
            </button>
            <button
              style={{
                padding: 16,
                border: `2px solid ${getColor(RankUserTab.D)}`,
                borderRadius: 10,
                backgroundColor: '#FFF',
                color: getColor(RankUserTab.D),
                cursor: 'pointer',
              }}
              onClick={() => {
                handleClassRegisterButtonClick(RankUserTab.D);
              }}>
              D 클래스
            </button>
          </div>
          {/* 랭크유저 클래스별 리스트 탭 */}
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: 100 }}>
            {activeTab === Tab.Rank && (
              <ul style={{ display: 'flex', width: '100%', justifyContent: 'flex-start' }}>
                <li
                  style={{
                    display: 'flex',
                    width: 130,
                    height: 50,
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    border: activeRankTab === RankUserTab.ALL ? '2px solid #BABABA' : undefined,
                    backgroundColor: activeRankTab === RankUserTab.ALL ? '#FFF' : '#BABABA',
                    color: activeRankTab === RankUserTab.ALL ? '#000' : '#FFF',
                  }}
                  onClick={() => {
                    setActiveRankTab(RankUserTab.ALL);
                    resetCurrentPageAndSelectedPks();
                  }}>
                  전체 유저
                </li>
                <li
                  style={{
                    display: 'flex',
                    width: 130,
                    height: 50,
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    border: activeRankTab === RankUserTab.A ? '2px solid #BABABA' : undefined,
                    backgroundColor: activeRankTab === RankUserTab.A ? '#FFF' : '#BABABA',
                    color: activeRankTab === RankUserTab.A ? '#000' : '#FFF',
                  }}
                  onClick={() => {
                    setActiveRankTab(RankUserTab.A);
                    resetCurrentPageAndSelectedPks();
                  }}>
                  A 유저
                </li>
                <li
                  style={{
                    display: 'flex',
                    width: 130,
                    height: 50,
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    border: activeRankTab === RankUserTab.B ? '2px solid #BABABA' : undefined,
                    backgroundColor: activeRankTab === RankUserTab.B ? '#FFF' : '#BABABA',
                    color: activeRankTab === RankUserTab.B ? '#000' : '#FFF',
                  }}
                  onClick={() => {
                    setActiveRankTab(RankUserTab.B);
                    resetCurrentPageAndSelectedPks();
                  }}>
                  B 유저
                </li>
                <li
                  style={{
                    display: 'flex',
                    width: 130,
                    height: 50,
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    border: activeRankTab === RankUserTab.C ? '2px solid #BABABA' : undefined,
                    backgroundColor: activeRankTab === RankUserTab.C ? '#FFF' : '#BABABA',
                    color: activeRankTab === RankUserTab.C ? '#000' : '#FFF',
                  }}
                  onClick={() => {
                    setActiveRankTab(RankUserTab.C);
                    setCurrentPage(0);
                    setSelectedPks([]);
                  }}>
                  C 유저
                </li>
                <li
                  style={{
                    display: 'flex',
                    width: 130,
                    height: 50,
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    border: activeRankTab === RankUserTab.D ? '2px solid #BABABA' : undefined,
                    backgroundColor: activeRankTab === RankUserTab.D ? '#FFF' : '#BABABA',
                    color: activeRankTab === RankUserTab.D ? '#000' : '#FFF',
                  }}
                  onClick={() => {
                    setActiveRankTab(RankUserTab.D);
                    resetCurrentPageAndSelectedPks();
                  }}>
                  D 유저
                </li>
              </ul>
            )}
            {/* 사진 리스트 */}
            <ul
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: 1900,
                gap: 30,
                flexWrap: 'wrap',
              }}>
              {list && list.items.length > 0 ? (
                list.items.map((item, idx) => (
                  <li
                    key={item.userPk}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flexBasis: '16%',
                      minWidth: 200,
                      cursor: 'pointer',
                    }}>
                    <div
                      style={{ position: 'relative' }}
                      onClick={() => {
                        setSelectedPks(prev => {
                          if (!prev.includes(item.userPk)) {
                            return [...prev, item.userPk];
                          }
                          const copy = prev.slice();
                          copy.splice(
                            copy.findIndex(pk => pk === item.userPk),
                            1,
                          );
                          return copy;
                        });
                      }}>
                      <img
                        style={{ width: '100%', height: '100%' }}
                        src={getS3SourceUri(item.imageUrl)}
                        alt={'프로필 이미지'}
                      />
                      {/* 체크박스 */}
                      <input
                        checked={selectedPks.includes(item.userPk)}
                        type="checkbox"
                        style={{ position: 'absolute', top: 10, right: 10, width: 15, height: 15, zIndex: 10 }}
                        // onChange={() => {}}
                      />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
                      {item?.rank && <span style={{ color: getColor(item.rank), fontWeight: 600 }}>{item?.rank}</span>}
                      <span>{`${item.nick} / ${item.age}살`}</span>
                      <span>{`userPk: ${item.userPk}`}</span>
                    </div>
                  </li>
                ))
              ) : (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    height: 500,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <h3>내역이 없습니다.</h3>
                </div>
              )}
            </ul>
          </div>
        </div>
        {list?.meta && <PageNav current={currentPage} getPageInfo={setCurrentPage} maxPage={list.meta?.totalPages} />}
      </div>

      {confirmPopUp.isVisible && (
        <div className="popupDefault">
          <div className="layer" style={{ whiteSpace: 'break-spaces' }}>
            <Row>
              <Col>{`${selectedPks.length}명의 유저를\n${confirmPopUp.rank}등급에 등록하겠습니까?`}</Col>
            </Row>
            <Row className="btnWrap">
              <Button
                size="sm"
                className="btn"
                onClick={() => {
                  setConfirmPopUp(prev => ({ ...prev, isVisible: false }));
                }}>
                취소
              </Button>
              <Button size="sm" className="btn-default" onClick={handleRegisterRankConfirmButtonClick}>
                확인
              </Button>
            </Row>
          </div>
        </div>
      )}
    </div>
  );
}

export default RankPage;
