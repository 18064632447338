/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
// react library for routing
import { RouteComponentProps, NavLink as NavLinkRRD, Link } from 'react-router-dom';
// nodejs library that concatenates classes
import classnames from 'classnames';
// nodejs library to set properties for components
// react library that creates nice scrollbar on windows devices
import PerfectScrollbar from 'react-perfect-scrollbar';
// reactstrap components
import { Collapse, NavbarBrand, Navbar, NavItem, NavLink, Nav, Button, Row, Col } from 'reactstrap';
import { observer, inject } from 'mobx-react';

import axios from '../../axios/index';
import { userState } from '../../stores/UserState';
import devLogo from '../../assets/img/brand/mecute-textlogo.png';
import realLogo from '../../assets/img/brand/mecute-real-logo.svg';
interface SidebarProps {
  logo: { innerLink: string; imgSrc: any; imgAlt: string };
  routes?: any;
  rtlActive?: boolean | undefined | null;
  sidenavOpen: boolean;
  toggleSidenav: () => void;
}

class Sidebar extends React.Component<RouteComponentProps & SidebarProps & any> {
  state: any = {
    collapseOpen: false,
  };

  logout = async () => {
    const loginInfo = localStorage.getItem('mecuteLogin');
    const token = loginInfo && JSON.parse(loginInfo)?.token;
    try {
      axios
        .post(`/v1/manager/sign-out`, {
          headers: {
            token: token,
          },
        })
        .then(res => {
          if (res.status === 200 || res.status === 201) {
            userState.clearUserInfo();
            window.location.href = '/auth/login';
            localStorage.removeItem('mecuteLastActiveTime');
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName: string) => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  };
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false,
    });
  };

  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes: any) => {
    let initialState = {};
    routes.map((prop: any, key: any) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };

  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  getCollapseInitialState(routes: any) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // this is used on mobile devices, when a user navigates
  // the sidebar will autoclose
  closeSidenav = () => {
    if (window.innerWidth < 1200) {
      this.props.toggleSidenav();
    }
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes: any) => {
    return routes.map((prop: any, key: any) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.notshowing) {
        return null;
      } else if (prop.collapse && prop.show !== false) {
        const st: any = {};
        st[prop['state']] = !this.state[prop.state];
        return (
          <NavItem key={key}>
            <NavLink
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={this.state[prop.state]}
              className={classnames({
                active: this.getCollapseInitialState(prop.views),
              })}
              onClick={(e: any) => {
                e.preventDefault();
                this.setState(st);
              }}>
              {prop.icon ? (
                <>
                  <i className={prop.icon} />
                  <span className={classnames('nav-link-text', { 'word-strik': !prop.complete })}>{prop.name}</span>
                </>
              ) : prop.miniName ? (
                <>
                  <span className="sidenav-mini-icon"> {prop.miniName} </span>
                  <span className="sidenav-normal"> {prop.name} </span>
                </>
              ) : null}
            </NavLink>
            <Collapse isOpen={this.state[prop.state]}>
              <Nav className="nav-sm flex-column">{this.createLinks(prop.views)}</Nav>
            </Collapse>
          </NavItem>
        );
      }
      return (
        <NavItem className={this.activeRoute(prop.layout + prop.path)} key={key}>
          <NavLink to={prop.layout + prop.path} activeClassName="" onClick={this.closeSidenav} tag={NavLinkRRD}>
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <span className={classnames('nav-link-text', { 'word-strik': !prop.complete })}>{prop.name}</span>
              </>
            ) : prop.miniName !== undefined ? (
              <>
                <span className="sidenav-mini-icon"> {prop.miniName} </span>
                <span className="sidenav-normal"> {prop.name} </span>
              </>
            ) : (
              prop.name
            )}
          </NavLink>
        </NavItem>
      );
    });
  };
  render() {
    const { userState } = this.props;
    if (userState.user?.accessToken === '') {
      //store값 없을때
      const loginInfo = localStorage.getItem('mecuteLogin');
      if (loginInfo) {
        //로컬값이 있다면 > 다시 store 저장
        if (loginInfo !== null) {
          userState.saveUserInfo(JSON.parse(loginInfo));
        }
      } else {
        // 로그인 페이지로 이동
        window.location.href = '/auth/login';
      }
    }
    const { routes, logo } = this.props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link,
      };
    }

    const scrollBarInner = (
      <div className="scrollbar-inner">
        <div className="sidenav-header d-flex align-items-center">
          {logo ? (
            <NavbarBrand {...navbarBrandProps}>
              <p className="mb-0">
                <small>{process.env.REACT_APP_MODE === 'development' ? 'test' : 'real'}</small>
              </p>{' '}
              <img
                alt={logo.imgAlt}
                className="navbar-brand-img"
                src={process.env.REACT_APP_MODE === 'development' ? devLogo : realLogo}
              />
            </NavbarBrand>
          ) : null}

          <div className="ml-auto">
            <div
              className={classnames('sidenav-toggler pr-4 d-xl-block', {
                active: this.props.sidenavOpen,
              })}
              onClick={this.props.toggleSidenav}>
              <div className="sidenav-toggler-inner">
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
              </div>
            </div>
          </div>
        </div>
        <div className="loginInfo">
          <p className="text-muted">
            {userState.user.name}님
            <Button bssize="sm" color="primary" outline onClick={this.logout}>
              로그아웃
            </Button>
          </p>
        </div>
        <div className="navbar-inner">
          <Collapse navbar isOpen={true}>
            <Nav navbar>{this.createLinks(routes)}</Nav>
          </Collapse>
        </div>
      </div>
    );
    return (
      <>
        <Navbar
          className={
            'sidenav navbar-vertical navbar-expand-xs navbar-light bg-white ' +
            (this.props.rtlActive ? '' : 'fixed-left')
          }>
          {navigator.platform.indexOf('Win') > -1 ? (
            <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
          ) : (
            scrollBarInner
          )}
        </Navbar>
      </>
    );
  }
}

export default inject('userState')(observer(Sidebar));
