import React, { useState, useEffect } from 'react';
import { Button, Input, Table } from 'reactstrap';

import '../../../assets/css/grade.scss';
import axios from '../../../axios/index';
import { formatKoreanLocalTime } from '../../../utils/utils';

interface Manager {
  created: string;
  email: string;
  id: number;
  name: string;
  valid: boolean;
}

export default function GradeSetting() {
  const [emailInput, setEmailInput] = useState<string>('');
  const [nameInput, setNameInput] = useState<string>('');
  const [managerList, setManagerList] = useState<Array<Manager>>([]);

  const fetchManagerList = async () => {
    const response = await axios.get('/v1/manager/list');

    if (response.status === 200) {
      setManagerList(response.data);
    }
  };

  const handleTextInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (e.target.name) {
      case 'emailInput':
        setEmailInput(e.target.value);
        break;
      case 'nameInput':
        setNameInput(e.target.value);
        break;
    }
  };

  const handleManagerRegisterClick = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const emailReg = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;

    if (!emailReg.test(emailInput)) {
      alert('이메일 형식이 올바르지 않습니다.');
      return;
    }
    if (!emailInput.includes('konglabs.net')) {
      alert('konglabs.net 도메인 이메일만 등록 가능합니다.');
      return;
    }

    const response = await axios.post('/v1/manager', {
      email: emailInput,
      name: nameInput,
    });

    if (!response || response?.status === 400 || response?.status === 500) {
      setEmailInput('');
      setNameInput('');
      alert('이메일 등록에 실패했습니다.');
      return;
    }

    setEmailInput('');
    setNameInput('');
    fetchManagerList();
    alert('등록이 완료되었습니다.');
  };

  const deleteManager = async (id: number) => {
    const response = await axios.delete(`/v1/manager/${id}`);
    if (response.status === 200) {
      fetchManagerList();
      alert('삭제가 완료되었습니다.');
      return;
    }
  };

  // 운영진 리스트 가져오기
  useEffect(() => {
    fetchManagerList();
  }, []);

  return (
    <>
      <div className="body-section">
        <div className="contentsContainer">
          <div className="pageInfo">
            <span className="text-muted">관리자 및 앱 / </span>
            <span className="text-primary">관리자 설정</span>
          </div>
          <h1 className="pageTitle">관리자 설정</h1>
          <div className="contentsWrap">
            <h2>계정 추가</h2>
            <div className="includeBtnWrap">
              <Input
                size="sm"
                type="text"
                autoComplete="off"
                onChange={handleTextInputChange}
                value={emailInput}
                name="emailInput"
                placeholder="추가할 계정의 메일 주소를 입력해주세요"
              />
              <Input
                size="sm"
                type="text"
                autoComplete="off"
                onChange={handleTextInputChange}
                value={nameInput}
                name="nameInput"
                placeholder="이름을 입력해주세요"
              />
              <Button
                size="sm"
                onClick={handleManagerRegisterClick}
                disabled={emailInput.length && nameInput.length ? false : true}
                className={emailInput.length && nameInput.length ? 'cursor' : 'notAllow'}>
                등록
              </Button>
            </div>
          </div>

          <div className="contentsWrap">
            <h2>계정 목록</h2>
            <div className="tableWrap">
              <Table bordered>
                <thead>
                  <tr>
                    <th>순번</th>
                    <th>이름</th>
                    <th>이메일</th>
                    <th>가입일</th>
                    <th>상태</th>
                    <th>관리</th>
                  </tr>
                </thead>
                <tbody>
                  {managerList.length ? (
                    managerList.map((manager, index) => (
                      <tr key={manager.id}>
                        <td>{index + 1}</td>
                        <td>{manager.name}</td>
                        <td>{manager.email}</td>
                        <td>{formatKoreanLocalTime(manager.created)}</td>
                        <td>{manager.valid ? '활성' : '비활성'}</td>
                        <td>
                          <Button size="sm" color="danger" onClick={() => deleteManager(manager.id)}>
                            삭제
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <h2>운영진 멤버 정보가 없습니다.</h2>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
