import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { Navbar, NavItem, Container } from 'reactstrap';
import { useHistory, Link } from 'react-router-dom';
interface headerProps {
  theme: string;
  sidenavOpen: boolean;
  toggleSidenav: (event: any) => void;
}

export default function HeaderBar(props: headerProps) {
  const history = useHistory();
  const [infoPopup, setInfoPopup] = useState(false);

  return (
    <>
      <Navbar className="headerContainer">
        <NavItem className="d-xl-none">
          <div
            className={classnames(
              'pr-3 sidenav-toggler',
              { active: props.sidenavOpen },
              { 'sidenav-toggler': props.theme === 'dark' },
            )}
            onClick={props.toggleSidenav}>
            <div className="sidenav-toggler-inner">
              <i className="sidenav-toggler-line" />
              <i className="sidenav-toggler-line" />
              <i className="sidenav-toggler-line" />
            </div>
          </div>
        </NavItem>
        <Container fluid className="justify-content-end ">
          <ul className="headerNavbar">
            <li className="line">
              <Link to="/pay/pay-dashboard">오늘번돈 +123,456,789</Link>
            </li>
            <li>
              <Link to="/matching/matching">매칭 50</Link>
            </li>
            <li>
              <Link to="/pay/pay-dashboard">결제 30건</Link>
            </li>
            <li>
              <Link to="/balloon/balloon-dashboard">
                <img src={require('../../assets/img/ic_heart.svg')} />
                풍선 100
              </Link>
            </li>
            <li>
              <Link to="/subs/subs-dashboard">
                <img src={require('../../assets/img/ic_premium_grade.svg')} />
                구독 1000
              </Link>
            </li>
            <li>
              <p className="mb-0">
                <small>{process.env.REACT_APP_MODE === 'development' ? 'test' : 'real'}</small>
              </p>
            </li>
          </ul>
        </Container>
      </Navbar>
    </>
  );
}
