/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import MemberDash from './views/pages/member/MemberDashboard';
import MemberList from './views/pages/member/MemberList';
import PhotoCheck from './views/pages/member/PhotoCheck';
import UserVerification from './views/pages/member/UserVerification';
import MemberReport from './views/pages/member/MemberReport';
import WithdrawalMember from './views/pages/member/WithdrawalMember';
import MemberDeviceVerification from './views/pages/member/DeviceVerificationList';
import BalloonHistory from './views/pages/balloon/BalloonList';
import GradeSetting from './views/pages/admin/gradeSetting';
import appSetting from './views/pages/admin/appSetting';
import BannerSetting from './views/pages/admin/BannerSetting';
import ProductSetting from './views/pages/admin/ProductSetting';
import ApiUtil from './views/pages/utility/ApiUtil';
import Login from './views/pages/auth/Login';
import PaymentPage from './views/pages/pay/PaymentPage';
import RankPage from './views/pages/member/RankPage';

export const paths = [
  '/member',
  '/pay',
  '/coffee',
  '/balloon',
  '/subs',
  '/audit',
  '/admin',
  '/matching',
  '/util',
  '/event',
];

/**
 *  SideMenu에 Display
 * 
 *  {
 *    collapse: 하위메뉴가 있는경우 true / 없는경우 필수값 아님 (매칭,API유틸 참고)
 *    notShowing: 메뉴에 보여지지 않을경우 true / 메뉴에 보여져야 할 경우 필수값 아님
 *    name: 상위메뉴이름
 *    complete: false인경우 메뉴에 취소선
 *    icon: 상위메뉴의 class
 *    state: collapse값 true인경우 collapseName
 *    layout: path depth1
 *    views:[
        {
          path: path depth2,
          name: 하위메뉴이름,
          component: route될 컴포넌트,
          layout: 상위 layout 
        },
 *    ],
 *    
 *    url = baseUrl + layout + path
 * */
export const routes = [
  {
    collapse: true,
    name: '회원',
    complete: true,
    icon: 'ic member',
    state: 'memberCollapse',
    layout: '/member',
    views: [
      {
        path: '/dash',
        name: '대시보드',
        component: MemberDash,
        layout: '/member',
      },
      {
        path: '/member-list',
        name: '회원내역',
        component: MemberList,
        layout: '/member',
      },
      {
        path: '/photocheck',
        name: '사진검수',
        component: PhotoCheck,
        layout: '/member',
      },
      {
        path: '/user-verification',
        name: '직장인증',
        component: UserVerification,
        layout: '/member',
      },
      {
        path: '/rank',
        name: '회원등급',
        component: RankPage,
        layout: '/member',
      },
      {
        path: '/member-report',
        name: '신고/차단',
        component: MemberReport,
        layout: '/member',
      },
      {
        path: '/member/device',
        name: '본인 기기인증',
        component: MemberDeviceVerification,
        layout: '/member',
      },
      {
        path: '/withdrawal',
        name: '탈퇴',
        component: WithdrawalMember,
        layout: '/member',
      },
    ],
  },
  {
    collapse: true,
    name: '결제',
    complete: true,
    icon: 'ic pay',
    state: 'payCollapse',
    layout: '/pay',
    views: [
      {
        path: '/pay-dashboard',
        name: '결제 요약',
        component: PaymentPage,
        layout: '/pay',
      },
    ],
  },
  // {
  //   collapse: true,
  //   name: '풍선',
  //   complete: true,
  //   icon: 'ic balloon',
  //   state: 'balloonCollapse',
  //   layout: '/balloon',
  //   views: [
  //     {
  //       path: '/balloon-dashboard',
  //       name: '사용량',
  //       component: BalloonDashboard,
  //       layout: '/balloon',
  //     },
  //     {
  //       path: '/balloon-history',
  //       name: '사용 내역',
  //       component: BalloonHistory,
  //       layout: '/balloon',
  //     },
  //     {
  //       path: '/provide-history',
  //       name: '지급 내역',
  //       component: ProvideBalloon,
  //       layout: '/balloon',
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: '심사',
  //   complete: true,
  //   icon: 'ic audit',
  //   state: 'auditCollapse',
  //   layout: '/audit',
  //   views: [
  //     {
  //       path: '/audit-dashboard',
  //       name: '사용량 및 관리',
  //       component: AuditDashboard,
  //       layout: '/audit',
  //     },
  //     {
  //       path: '/audit-list',
  //       name: '심사 내역',
  //       component: AuditList,
  //       layout: '/audit',
  //     },
  //   ],
  // },
  {
    collapse: true,
    name: '관리자 및 앱',
    complete: true,
    icon: 'ic set',
    state: 'adminCollapse',
    layout: '/admin',
    views: [
      {
        path: '/grade',
        name: '관리자 설정',
        component: GradeSetting,
        layout: '/admin',
      },
      {
        path: '/app',
        name: '앱 버전 관리',
        component: appSetting,
        layout: '/admin',
      },
    ],
  },
  // {
  //   path: '/matching',
  //   name: '매칭',
  //   complete: true,
  //   icon: 'ic matching',
  // component: MatchingDashboard,
  //   layout: '/matching',
  // },
  // {
  //   path: '/util',
  //   name: 'API 유틸',
  //   complete: true,
  //   icon: 'ic matching',
  //   component: ApiUtil,
  //   layout: '/util',
  // },
  {
    collapse: true,
    name: '이벤트',
    complete: true,
    icon: 'ic event',
    state: 'eventCollapse',
    layout: '/event',
    views: [
      {
        path: '/banner',
        name: '배너 관리',
        component: BannerSetting,
        layout: '/event',
      },
      // {
      //   path: '/product',
      //   name: '상품 관리',
      //   component: ProductSetting,
      //   layout: '/event',
      // {
      //   path: '/app',
      //   name: '푸시',
      //   component: appSetting,
      //   layout: '/event',
      // },
    ],
  },
  {
    notshowing: true,
    layout: '/auth',
    path: '/login',
    name: '로그인',
    complete: false,
    icon: 'ic',
    component: Login,
  },
];
