import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import Resource from '../../../utils/Resource';
import { formatKoreanLocalTime } from '../../../utils/utils';
import '../../../assets/css/member.scss';
interface UserInfo {
  pk: number;
  tel: string;
  email: string;
  nick: string;
  gender: 'male' | 'female';
  age: number;
  birth: string; // Date
  dormant: number;
  withdrawal: string; // Date
  disabled: number;
  public: number;
  lastSignIn: string; // Date
  created: string; // Date
  height: number;
  religion: string;
  drink: string;
  relation: string;
  smoke: string;
  mbti: string;
  freeQna: string;
  city: string;
  address: string;
  homeTown: string;
  profileImages: any[];
  career: string;
  pet: string;
}

interface MemberProfileProps {
  userInfo?: UserInfo;
  userPk: number;
  changeData: () => void;
}

export default function MemberProfile(props: MemberProfileProps) {
  const [profileImgPopup, setProfilImgPopup] = useState({ view: false, url: '' });

  return (
    <>
      <div>
        <div className="contentsBorder">
          <Row className="mb-4">
            <Col md="12">
              <span className="mr-3 bold-500">기본 정보</span>
            </Col>
          </Row>
          <Row className="mb-4 border-bottom">
            <Col xl="2" lg="4" md="4">
              <div className="imgWrap text-left">
                <div
                  className={'userImg'}
                  style={{
                    backgroundImage:
                      props?.userInfo?.profileImages && props?.userInfo?.profileImages?.length > 0
                        ? `url(${
                            props?.userInfo?.profileImages[0]?.file ? Resource.ImgDomain : Resource.DefaultImgDomain
                          }${props?.userInfo.profileImages[0]?.file})`
                        : undefined,
                  }}></div>
              </div>
            </Col>
            <Col xl="5" lg="8" md="8" className="border-right h-100">
              <div className="userInfo">
                <div>
                  <span className="infoTitle">닉네임</span>
                  <span className="infoText" title={props?.userInfo?.nick} style={{ fontWeight: 300 }}>
                    {props?.userInfo?.nick}
                  </span>
                </div>
                <div>
                  <span className="infoTitle">성별</span>
                  <span className="infoText" title={props?.userInfo?.gender}>
                    {props?.userInfo?.gender}
                  </span>
                </div>
                <div>
                  <span className="infoTitle">직업</span>
                  <span className="infoText" title={props?.userInfo?.career}>
                    {props?.userInfo?.career}
                  </span>
                </div>
              </div>
            </Col>
            <Col xl="5" lg="6" md="12" className="h-100">
              <Row>
                <Col xl="8" xs="8">
                  <div className="userInfo">
                    <div>
                      <span className="infoTitle">전화번호</span>
                      <span className="infoText" title={props?.userInfo?.tel ? props?.userInfo.tel : undefined}>
                        {props?.userInfo?.tel ? props?.userInfo.tel : '-'}
                      </span>
                    </div>
                    <div>
                      <span className="infoTitle">이메일</span>
                      <span className="infoText" title={props?.userInfo?.email ? props?.userInfo.email : undefined}>
                        {props?.userInfo?.email ? props?.userInfo.email : '-'}
                      </span>
                    </div>
                    <div>
                      <span className="infoTitle">생년월일</span>
                      <span className="infoText" title={props?.userInfo?.birth ? props?.userInfo?.birth : undefined}>
                        {props?.userInfo?.birth?.split('T')[0]}
                      </span>
                    </div>
                    <div>
                      <span className="infoTitle">나이</span>
                      <span className="infoText" title={props?.userInfo?.age.toString()}>
                        {props?.userInfo?.age}
                      </span>
                    </div>
                    <div>
                      <span className="infoTitle">가입 날짜</span>
                      {props?.userInfo && (
                        <span className="infoText" title={formatKoreanLocalTime(props?.userInfo.created)}>
                          {formatKoreanLocalTime(props?.userInfo.created)}
                        </span>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className="mb-0" md="12">
              <ul className="photoList mb-0 mt-4">
                {props?.userInfo?.profileImages &&
                  props.userInfo.profileImages?.length > 0 &&
                  props?.userInfo?.profileImages?.map((profile: any, idx: number) => {
                    return (
                      <li key={profile?.file}>
                        <img
                          className="zoomIn"
                          src={
                            (profile?.file.indexOf('imgProfileSquare') > -1
                              ? Resource.DefaultImgDomain
                              : Resource.ImgDomain) + profile?.file
                          }
                          onClick={e => {
                            e.preventDefault();
                            setProfilImgPopup({ view: true, url: Resource.ImgDomain + profile?.file });
                          }}
                          alt="프로필이미지"
                        />
                        {profile.step === 1 && <div className="badge">첫번째</div>}
                      </li>
                    );
                  })}
              </ul>
            </Col>
          </Row>
        </div>
        <Row className="mt-3">
          <Col xl="12" lg="12">
            <div className="contentsBorder">
              <span>추가정보</span>
              <Row className="mt-3">
                <Col xl="5" lg="6">
                  <div className="userInfo border-right h-100">
                    <div>
                      <span className="infoTitle">거주지역</span>
                      <span className="infoText">
                        {props?.userInfo?.city && props?.userInfo?.city}
                        {props?.userInfo?.city && props?.userInfo?.address && ', '}
                        {props?.userInfo?.address && props?.userInfo?.address}
                        {!props?.userInfo?.city && !props?.userInfo?.address && '-'}
                      </span>
                    </div>
                    <div>
                      <span className="infoTitle">키</span>
                      <span className="infoText">
                        {!props?.userInfo?.height ? '-' : props?.userInfo?.height + `cm`}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col xl="7" lg="6">
                  <div className="userInfo">
                    <div>
                      <span className="infoTitle">종교</span>
                      <span className="infoText">{props?.userInfo?.religion}</span>
                    </div>
                    <div>
                      <span className="infoTitle">음주</span>
                      <span className="infoText">{props?.userInfo?.drink}</span>
                    </div>
                    <div>
                      <span className="infoTitle">반려동물</span>
                      <span className="infoText">{props?.userInfo?.pet}</span>
                    </div>
                    <div>
                      <span className="infoTitle">어떤사이</span>
                      <span className="infoText">{props?.userInfo?.relation}</span>
                    </div>
                    <div>
                      <span className="infoTitle">계정 상태</span>
                      <span className="infoText">
                        {props?.userInfo?.dormant && props?.userInfo?.dormant > 0 && '휴면'}
                        {props?.userInfo?.public && props?.userInfo?.public > 0 && '정상'}
                        {props?.userInfo?.disabled && props?.userInfo?.disabled > 0 && '영구정지'}
                        {props?.userInfo?.withdrawal &&
                          `
                            탈퇴
                            ${props?.userInfo?.withdrawal !== null && '(' + props?.userInfo?.withdrawal + ')'}
                          `}
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>

      {/* 개별 프로필 이미지 클릭시 */}
      {profileImgPopup.view && (
        <div
          className="popupDefault showing"
          onClick={() => {
            setProfilImgPopup({ view: false, url: '' });
          }}>
          <img src={profileImgPopup.url} />
        </div>
      )}
    </>
  );
}
