import React, { useState, useRef, useEffect } from 'react';
import { FormGroup, Col, Row, Button, Input, Table } from 'reactstrap';

import axios from '../../../axios/index';
import '../../../assets/css/member.scss';
import PageNav from '../../../components/Pages/PageNav';
import Resource from '../../../utils/Resource';
import { excelTable } from '../../../components/Pages/commonFunction';

interface MemberListProps {
  memberPopupOpen: (pk: number) => void;
  dataReload: boolean;
}
interface MemberProps {
  gender: 'male' | 'female';
  dormant: number;
  disabled: number;
  nick: string;
  pk: number;
  profileImage: string;
  age: number;
  tel: string;
  email: string;
  withdrawal: string | null;
}

export default function MemberList(props: MemberListProps) {
  const searchingRef = useRef<HTMLInputElement>(null);

  const [memberLists, setMemberLists] = useState<Array<MemberProps>>([]);
  const [memberPage, setMemberPage] = useState(0);
  const [memberSearch, setMemberSearch] = useState({ search: 'nick', keyword: '' });
  const [memberFilter, setMemberFilter] = useState({ type: '', value: '' });
  const listLeng = 10;
  const [maxLeng, setMaxLeng] = useState(0);
  const [inputs, setInputs] = useState({
    search: '',
    balloonSendLength: '',
    balloonSendMessage: '',
  });

  const [filterInputs, setFilterInputs] = useState<{
    status: 'all' | 'withdrawal' | 'original' | 'new' | 'dormant' | 'disabled';
    gender: 'male' | 'female';
  }>({
    status: 'all',
    gender: 'male',
  });

  const { search } = inputs;
  const { status, gender } = filterInputs;

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value, name } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const searchingKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      getMemberList(0, memberSearch.search ? memberSearch.search : 'nick', search);
      setInputs({ ...inputs, search: '' });
      setFilterInputs({
        status: 'all',
        gender: 'male',
      });
      if (searchingRef && searchingRef.current && searchingRef.current.blur) searchingRef.current.blur();
    }
  };

  const filterChange = (e: React.ChangeEvent<HTMLSelectElement>, type: string) => {
    const { value, name } = e.target;
    setFilterInputs({
      status: 'all',
      gender: 'male',
      [name]: value,
    });
    setMemberFilter({ type: type, value: e.target.value });
    getMemberList(0, memberSearch.search, memberSearch.keyword, type, e.target.value);
  };

  const getPageInfo = (page: number) => {
    getMemberList(page, memberSearch.search, memberSearch.keyword, memberFilter.type, memberFilter.value);
  };

  //회원내역 리스트
  const getMemberList = (page: number, search?: string, keyword?: string, option?: string, optionValue?: string) => {
    if (keyword) {
      setMemberSearch({
        search: search ? search : 'nick',
        keyword: keyword,
      });
    } else {
      setMemberSearch({
        search: '',
        keyword: '',
      });
    }
    axios
      .get(
        `/v1/member/list?search=` +
          (search ? search : 'nick') +
          `&keyword=` +
          (keyword ? keyword : '') +
          `&page=` +
          (page + 1) +
          `&limit=` +
          listLeng +
          (option && !(option === 'gender' && optionValue === 'all') ? `&` + option + `=` + optionValue : ''),
      )
      .then(result => {
        setMaxLeng(result?.data?.meta?.totalPages);
        const memberLists: MemberProps[] = result?.data?.items;
        setMemberLists(memberLists);
        setMemberPage(page);
      })
      .catch(error => {
        console.log(error.message);
      });
  };

  //회원내역 엑셀다운로드
  const excelDown = (id: string, title: string) => {
    let table = '';
    const thead = ['No', '구분', '닉네임', '성별', '나이', '전화번호', '이메일'];

    memberLists.map((item: MemberProps, idx: number) => {
      const num = `<td>` + (idx + 1) + `</td>`;
      const isNew =
        `<td>` + (item.disabled === 1 ? '삭제' : item.dormant === 1 ? '휴면' : item.withdrawal ?? '기존') + `</td>`;
      const nick = `<td>` + item.nick + `</td>`;
      const gender = `<td>` + item.gender + `</td>`;
      const age = `<td>` + item.age + `</td>`;
      const telString = item.tel === null ? '-' : item.tel;
      const tel =
        `<td>` +
        (telString === '-'
          ? telString
          : telString.slice(0, telString.length - 8) +
            `-` +
            telString.slice(telString.length - 8, telString.length - 4) +
            `-` +
            telString.slice(telString.length - 4, telString.length)) +
        `</td>`;
      const email = `<td>` + (item.email === null ? '-' : item.email) + `</td>`;
      //+9hour
      const withdrawalDate = !!item.withdrawal ? new Date(Date.parse(item.withdrawal) + 1000 * 60 * 60 * 9) : '';
      const withdrawal =
        `<td>` +
        (!!withdrawalDate
          ? withdrawalDate.getFullYear() +
            '.' +
            (withdrawalDate.getMonth() + 1) +
            '.' +
            withdrawalDate.getDate() +
            ' ' +
            withdrawalDate.getHours() +
            ':' +
            withdrawalDate.getMinutes()
          : '') +
        `</td>`;

      table = table + `<tr>` + num + isNew + nick + gender + age + tel + email + withdrawal + `</tr>`;
    });
    excelTable(thead, table, title, '회원내역');
  };

  // 회원 상태
  const userStatusText = (disabled: number, dormant: number, withdrawal: string | null) => {
    return (
      <>
        {disabled === 1 ? (
          <span className="text-red">삭제</span>
        ) : (
          <span>{dormant === 1 ? '휴면' : withdrawal && withdrawal.length > 0 ? withdrawal : '기존'}</span>
        )}
      </>
    );
  };

  const memberDetailPopup = (pk: number) => {
    props.memberPopupOpen(pk);
  };

  useEffect(() => {
    getMemberList(0);
  }, []);

  useEffect(() => {
    if (!props.dataReload) {
      getMemberList(memberPage, memberSearch.search, memberSearch.keyword, memberFilter.type, memberFilter.value);
    }
  }, [props.dataReload]);

  return (
    <>
      <div className="body-section">
        <div className="contentsContainer">
          <div className="pageInfo">
            <span className="text-muted">회원 / </span>
            <span className="text-primary">회원내역</span>
          </div>
          <div className="contentsWrap">
            <h2>회원 내역</h2>
            <div className="includeTable">
              <Row className="mb-3">
                <Col xl="6" md="4" xs="12"></Col>
                <Col xl="1" md="1" xs="4"></Col>
                <Col xl="2" md="2" xs="3">
                  <FormGroup>
                    <Input
                      bssize="sm"
                      type="select"
                      value={memberSearch.search}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setMemberSearch({ ...memberSearch, search: e.target.value });
                      }}>
                      <option value="nick">닉네임</option>
                      <option value="email">이메일</option>
                      <option value="tel">전화번호</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col xl="3" md="5" xs="5">
                  <div className="includeBtnWrap">
                    <Input
                      placeholder="닉네임을 검색하세요."
                      bssize="sm"
                      type="text"
                      autoComplete="off"
                      name="search"
                      value={search}
                      ref={searchingRef}
                      onChange={onChange}
                      onKeyPress={searchingKeyPress}
                    />
                    <Button
                      bssize="sm"
                      disabled={!inputs.search}
                      className={inputs.search ? 'cursor' : 'notAllow'}
                      onClick={() => {
                        getMemberList(0, memberSearch.search, inputs.search);
                        setInputs({ ...inputs, search: '' });
                        setFilterInputs({
                          status: 'all',
                          gender: 'male',
                        });
                      }}>
                      검색
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  {memberSearch.keyword.length > 0 && (
                    <div>
                      <small>`{memberSearch.keyword}`에 대한 검색결과입니다.</small>
                      <Button
                        bssize="sm"
                        className="float-right"
                        onClick={() => {
                          getMemberList(0);
                        }}>
                        전체내역
                      </Button>
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col className="text-right">
                  <Button bssize="sm" color="default" outline onClick={() => excelDown('table', '회원내역')}>
                    <i className="fas fa-file-excel"></i>
                  </Button>
                </Col>
              </Row>
              <Table className="align-items-center table-flush text-center " responsive>
                <thead>
                  <tr>
                    <th style={{ width: '5%' }}>PK</th>
                    <th style={{ width: '7%' }}>
                      구분
                      <select
                        name="status"
                        value={status}
                        className="selectFont0"
                        onChange={e => {
                          filterChange(e, 'status');
                        }}>
                        <option value="all">전 체</option>
                        <option value="withdrawal">탈 퇴</option>
                        <option value="original">기 존</option>
                        <option value="new">NEW</option>
                        <option value="dormant">휴 면</option>
                        <option value="disabled">삭 제</option>
                      </select>
                    </th>
                    <th style={{ width: '8%' }}>프로필</th>
                    <th style={{ width: '25%' }}>닉네임</th>
                    <th style={{ width: '5%' }}>
                      성별
                      <select
                        name="gender"
                        value={gender}
                        className="selectFont0"
                        onChange={e => {
                          filterChange(e, 'gender');
                        }}>
                        <option value="all">전체</option>
                        <option value="male">남자(M)</option>
                        <option value="female">여자(F)</option>
                      </select>
                    </th>
                    <th>나이</th>
                    <th>전화번호</th>
                    <th>이메일</th>
                  </tr>
                </thead>
                <tbody>
                  {memberLists.length === undefined && (
                    <tr style={{ width: '100%' }}>
                      <td colSpan={9}>조회된 결과가 없습니다.</td>
                    </tr>
                  )}
                  {memberLists.length > 0 &&
                    memberLists.map((member: MemberProps, idx: number) => (
                      <tr key={member.pk}>
                        <td>{member.pk}</td>
                        <td>{userStatusText(member.disabled, member.dormant, member.withdrawal)}</td>
                        <td>
                          <span
                            onClick={() => {
                              memberDetailPopup(member.pk);
                            }}
                            className={'userImg pointer'}
                            style={{
                              backgroundImage: member.profileImage
                                ? `url(` +
                                  (member.profileImage.indexOf('imgProfileSquar') > -1
                                    ? Resource.DefaultImgDomain
                                    : Resource.ImgDomain) +
                                  member.profileImage +
                                  `)`
                                : '',
                            }}></span>
                        </td>
                        <td>
                          <span
                            className="pointer"
                            onClick={() => {
                              memberDetailPopup(member.pk);
                            }}
                            title={member.nick}
                            dangerouslySetInnerHTML={{ __html: member.nick }}></span>
                        </td>
                        <td>{member.gender === 'male' ? '남자' : '여자'}</td>
                        <td>{member.age}</td>
                        <td>{member.tel}</td>
                        <td>
                          {member.email === null || member.email === ''
                            ? '-'
                            : (member.email || '').split('-withdrawal:user')[0]}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              <Row className="mt-4">
                <Col md="4"></Col>
                <Col md="4">
                  <PageNav getPageInfo={getPageInfo} current={memberPage} maxPage={maxLeng} />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
