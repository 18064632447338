import React, { useEffect, useState } from 'react';
import { Col, Row, Button, Input, Table } from 'reactstrap';

import axios from '../../../axios/index';
import '../../../assets/css/member.scss';
import PageNav from '../../../components/Pages/PageNav';
import TableText from '../../../components/Pages/TableText';
import { formatKoreanLocalTime } from '../../../utils/utils';

interface MemberReportProps {
  memberPopupOpen: (pk: number) => void;
  dataReload: boolean;
}

interface reportProps {
  pk: number;
  reporterPk: number;
  otherPk: number;
  otherNick: string;
  reporterNick: string;
  source: string;
  subType: string;
  memo: string;
  created: string;
  profileImage: string;
  reportCount: number; // 누적횟수
}

const ITEMS_PER_PAGE = 10;

export default function MemberReport(props: MemberReportProps) {
  const [activeTab, setActiveTab] = useState(0);
  const [reportLists, setReportLists] = useState([]);
  const [reportPage, setReportPage] = useState({ current: 0, max: 0 });
  const [reportFind, setReportFind] = useState({ search: 'nick', nick: '' });

  const [inputs, setInputs] = useState({
    search: '',
  });

  const { search } = inputs;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const searchingKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      getReportLists(0, activeTab, search);
      setInputs({ search: '' });
    }
  };

  const memberDetailPopup = (pk: number) => {
    props.memberPopupOpen(pk);
  };

  const getPageInfo = (page: number) => {
    getReportLists(page, activeTab, reportFind.nick);
  };

  const getReportLists = (page: number, tab: number, nick?: string) => {
    if (nick) {
      setReportFind(prev => ({
        ...prev,
        nick: nick,
      }));
    } else {
      setReportFind({
        search: '',
        nick: '',
      });
    }
    //신고리스트
    if (tab === 0) {
      const nickName = nick && nick.length > 0 ? `&nick=${nick}` : '';
      axios.get('/v1/reports/list?page=' + (page + 1) + '&limit=' + ITEMS_PER_PAGE + nickName).then(res => {
        if (res.status === 200) {
          const result = res.data;
          const totalPages = result.meta.totalPages;
          if (result.meta?.totalItems === 0) setReportLists([]);
          else setReportLists(result.items);
          setReportPage({
            current: page,
            max: totalPages > 0 ? totalPages : 1,
          });

          if (activeTab !== tab) {
            setActiveTab(tab);
          }
        }
      });
    }
  };

  useEffect(() => {
    getReportLists(0, 0);
  }, []);

  return (
    <>
      <div className="body-section">
        <div className="contentsContainer">
          <div className="pageInfo">
            <span className="text-muted">회원 / </span>
            <span className="text-primary">신고/차단</span>
          </div>
          <h1 className="pageTitle">신고/차단 </h1>
          <ul className="contentsTab">
            <li className={activeTab === 0 ? 'active' : ''} onClick={() => getReportLists(0, 0)}>
              신고
            </li>
          </ul>
          <div className="contentsWrap">
            <div className="includeTable">
              <Row className="mb-3">
                <Col sm="6"></Col>
                <Col sm="2">
                  <Input
                    bssize="sm"
                    type="select"
                    value={reportFind.search}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setReportFind({ ...reportFind, search: e.target.value });
                    }}>
                    <option value="nick">닉네임</option>
                  </Input>
                </Col>
                <Col sm="4">
                  <div className="includeBtnWrap">
                    <Input
                      bssize="sm"
                      type="text"
                      autoComplete="off"
                      name="search"
                      value={search}
                      onChange={onChange}
                      onKeyPress={searchingKeyPress}
                    />
                    <Button
                      bssize="sm"
                      disabled={!inputs.search}
                      className={inputs.search ? 'cursor' : 'notAllow'}
                      onClick={() => {
                        getReportLists(0, activeTab, search);
                        setInputs({ ...inputs, search: '' });
                      }}>
                      검색
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  {reportFind.nick.length > 0 && (
                    <div>
                      <small>닉네임 '{reportFind.nick}'에 대해 검색합니다.</small>
                      <Button
                        bssize="sm"
                        className="float-right"
                        onClick={() => {
                          getReportLists(0, activeTab);
                        }}>
                        전체내역
                      </Button>
                    </div>
                  )}
                </Col>
              </Row>
              <Table className="align-items-center table-flush text-center" responsive>
                <thead>
                  {activeTab === 0 && (
                    <tr>
                      <th>순번</th>
                      <th style={{ minWidth: '270px', maxWidth: '270px' }}>닉네임</th>
                      <th style={{ minWidth: '270px', maxWidth: '270px' }}>신고자</th>
                      <th style={{ minWidth: '80px', maxWidth: '80px' }}>경로</th>
                      <th style={{ minWidth: '320px', maxWidth: '320px' }}>신고사유</th>
                      <th>누적횟수</th>
                      <th>신고시간</th>
                    </tr>
                  )}
                </thead>
                <tbody>
                  {reportLists.length === 0 && (
                    <tr>
                      <td colSpan={9}>조회된 결과가 없습니다.</td>
                    </tr>
                  )}
                  {activeTab === 0 &&
                    reportLists.length > 0 &&
                    reportLists.map((item: reportProps, idx: number) => (
                      <tr key={item.pk}>
                        <td>{ITEMS_PER_PAGE * reportPage.current + idx + 1}</td>
                        <td>
                          <span
                            dangerouslySetInnerHTML={{ __html: item.otherNick }}
                            className="pointer"
                            onClick={() => memberDetailPopup(item.otherPk)}></span>
                        </td>
                        <td>
                          <span
                            dangerouslySetInnerHTML={{ __html: item.reporterNick }}
                            className="pointer"
                            onClick={() => memberDetailPopup(item.reporterPk)}></span>
                        </td>
                        <td>{item.source}</td>
                        <td>
                          <TableText text={item.memo} />
                        </td>
                        <td>{'필요 시 요청'}</td>
                        <td>{formatKoreanLocalTime(item.created)}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              <Row className="mt-4">
                <Col>
                  <PageNav getPageInfo={getPageInfo} current={reportPage.current} maxPage={reportPage.max} />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
