import React, { useState } from 'react';
import { useEffect } from 'react';
import Lottie from 'react-lottie';
import ThumbsUp from '../../assets/lottie/data.json';

export default function LottiePage() {
  const lottieOptions = {
    animationData: ThumbsUp,
    loop: true,
    autoplay: true,
    rendererSettings: {
      className: 'add-class', // svg에 적용
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const [isStopped, SetIsStopped] = useState(false);
  const [isPaused, SetIsPaused] = useState(true);

  return (
    <div className="loader">
      <Lottie options={lottieOptions} style={{ width: '150px', height: '150px' }} />
    </div>
  );
}
