export default function() {
  function getToken() {
    return localStorage.getItem('token');
  }
  function setToken(token: string) {
    localStorage.setItem('token', token);
  }
  return {
    token: getToken(),
    setToken: setToken,
  };
}
