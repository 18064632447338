import React, { useEffect, useState } from 'react';
import { Col, Row, Button } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';

import axios from '../../../../../axios';
import { formatDateTimeLocal } from '../../../../../utils/utils';

interface NewBannerProps {
  id?: number;
  fetchEventBanners: () => Promise<void>;
}

function NewBanner(props: NewBannerProps) {
  const { id, fetchEventBanners } = props;

  const [isEditMode, _] = useState<boolean>(id ? true : false);
  const [resultPopUp, setResultPopUp] = useState({
    visible: false,
    message: '',
  });
  const { register, handleSubmit, formState, reset } = useForm();
  const history = useHistory();

  const handleCreateEventBannerSubmit = async (data: any) => {
    const formData = new FormData();

    Object.entries(data).forEach(keyValuePair => {
      const [key, value] = keyValuePair;

      if (!value) return;
      if (key === 'file' && value instanceof FileList) {
        formData.append(key, value[0]);
        return;
      }
      if ((key === 'startedAt' || key === 'endedAt') && typeof value === 'string') {
        formData.append(key, new Date(value).toISOString());
        return;
      }
      if (typeof value === 'string') {
        formData.append(key, value);
      }
    });

    try {
      const response = await axios.post('/v1/announce-banner', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response?.status === 201) {
        setResultPopUp({
          visible: true,
          message: '이벤트 배너가 생성되었습니다.',
        });
      } else {
        setResultPopUp({
          visible: true,
          message: '이벤트 배너 생성에 실패했습니다.',
        });
      }
    } catch (err) {
      setResultPopUp({
        visible: true,
        message: '이벤트 배너 생성 중 에러가 발생했습니다.',
      });
    }
  };

  const handleEditEventBannerSubmit = async (data: any) => {
    const payload: any = { id };

    Object.entries(data).forEach(keyValuePair => {
      const [key, value] = keyValuePair;

      if (key === 'file') return;
      if ((key === 'startedAt' || key === 'endedAt' || key === 'createdAt') && typeof value === 'string') {
        payload[key] = new Date(value).toISOString();
        return;
      }
      if (key === 'valid' && typeof value === 'string') {
        console.log(key, value);
        payload[key] = value === 'true' ? true : false;
        return;
      }
      if (typeof value === 'string') {
        payload[key] = value;
        return;
      }
      payload[key] = value;
    });

    try {
      const response = await axios.put('/v1/announce-banner', payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response?.status === 200) {
        setResultPopUp({
          visible: true,
          message: '이벤트 배너가 수정되었습니다.',
        });
      } else {
        setResultPopUp({
          visible: true,
          message: '이벤트 배너 수정에 실패했습니다.',
        });
      }
    } catch (err) {
      setResultPopUp({
        visible: true,
        message: '이벤트 배너 수정 중 에러가 발생했습니다.',
      });
    }
  };

  useEffect(() => {
    if (id) {
      const fetchEventBanner = async () => {
        const response = await axios.get(`/v1/announce-banner/${id}`);

        if (response?.status === 200) {
          reset({
            file: null,
            valid: response.data.valid ? 'true' : 'false',
            createdAt: formatDateTimeLocal(response.data.createdAt),
            startedAt: formatDateTimeLocal(response.data.startedAt),
            endedAt: formatDateTimeLocal(response.data.endedAt),
            linkUrl: response.data.linkUrl,
          });
        }
      };

      fetchEventBanner();
    }
  }, []);

  return (
    <div className="contentsWrap">
      <div className="contentsBox">
        <h2>{isEditMode ? '이벤트 배너 수정' : '이벤트 배너 생성'}</h2>
        <div
          className="contents"
          style={{
            display: 'flex',
            alignItems: 'center',
          }}>
          <form
            className="w-50"
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
            onSubmit={handleSubmit(isEditMode ? handleEditEventBannerSubmit : handleCreateEventBannerSubmit)}>
            <div
              className="w-100"
              style={{
                display: isEditMode ? 'none' : 'flex',
                marginTop: '1rem',
              }}>
              <div className="w-25">
                <span>이미지 파일</span>
              </div>
              <div className="w-50" style={{ display: 'flex', flexDirection: 'column' }}>
                <input
                  type="file"
                  className="w-100"
                  accept="*.jpg *.jpeg *.png"
                  {...register('file', {
                    required: { value: isEditMode ? false : true, message: '이미지 파일을 선택해주세요.' },
                  })}
                />
                <span style={{ color: 'red' }}>{formState?.errors?.['file']?.message}</span>
              </div>
            </div>
            {isEditMode && (
              <>
                <div className="w-100" style={{ display: 'flex', marginTop: '1rem' }}>
                  <div className="w-25">
                    <span>valid 값</span>
                  </div>
                  <div className="w-50" style={{ display: 'flex', flexDirection: 'column' }}>
                    <select
                      {...register('valid', {
                        setValueAs: value => value === 'true',
                      })}>
                      <option value="true">valid</option>
                      <option value="false">invalid</option>
                    </select>
                    <span style={{ color: 'red' }}>{formState?.errors?.['valid']?.message}</span>
                  </div>
                </div>
                <div className="w-100" style={{ display: 'flex', marginTop: '1rem' }}>
                  <div className="w-25">
                    <span>생성시간</span>
                  </div>
                  <div className="w-50" style={{ display: 'flex', flexDirection: 'column' }}>
                    <input
                      type="datetime-local"
                      className="w-100"
                      {...register('createdAt', {
                        required: { value: true, message: '생성 시간을 입력해주세요.' },
                      })}
                    />
                    <span style={{ color: 'red' }}>{formState?.errors?.['createdAt']?.message}</span>
                  </div>
                </div>
              </>
            )}
            <div className="w-100" style={{ display: 'flex', marginTop: '1rem' }}>
              <div className="w-25">
                <span>노출시작 시간</span>
              </div>
              <div className="w-50" style={{ display: 'flex', flexDirection: 'column' }}>
                <input
                  type="datetime-local"
                  className="w-100"
                  {...register('startedAt', {
                    required: { value: true, message: '시작 시간을 입력해주세요.' },
                  })}
                />
                <span style={{ color: 'red' }}>{formState?.errors?.['startedAt']?.message}</span>
              </div>
            </div>
            <div className="w-100" style={{ display: 'flex', marginTop: '1rem' }}>
              <div className="w-25">
                <span>노출종료 시간</span>
              </div>
              <div className="w-50" style={{ display: 'flex', flexDirection: 'column' }}>
                <input
                  type="datetime-local"
                  className="w-100"
                  {...register('endedAt', {
                    required: { value: true, message: '종료 시간을 입력해주세요.' },
                  })}
                />
                <span style={{ color: 'red' }}>{formState?.errors?.['endedAt']?.message}</span>
              </div>
            </div>
            <div className="w-100" style={{ display: 'flex', marginTop: '1rem' }}>
              <div className="w-25">
                <span>연결 URL 주소(optional)</span>
              </div>
              <div className="w-50" style={{ display: 'flex', flexDirection: 'column' }}>
                <input
                  type="text"
                  className="w-100"
                  {...register('linkUrl', {
                    pattern: {
                      value: /^(http|https):\/\//,
                      message: 'http:// 또는 https:// 를 포함한 URL을 입력해주세요.',
                    },
                  })}
                />
                <span style={{ color: 'red' }}>{formState?.errors?.['linkUrl']?.message}</span>
              </div>
            </div>
            <div className="w-100 mt-5" style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <input type="submit" value={isEditMode ? '수정하기' : '생성하기'} className="w-75 btn-default" />
            </div>
          </form>
        </div>
      </div>

      {resultPopUp.visible && (
        <div className="popupDefault">
          <div className="layer" style={{ whiteSpace: 'break-spaces' }}>
            <Row>
              <Col>{resultPopUp.message}</Col>
            </Row>
            <Row className="btnWrap">
              <Button
                size="sm"
                className="btn-default"
                onClick={() => {
                  setResultPopUp({
                    visible: false,
                    message: '',
                  });
                  fetchEventBanners();
                  reset();

                  if (isEditMode) {
                    history.replace('/event/banner');
                  }
                }}>
                리스트로 이동
              </Button>
            </Row>
          </div>
        </div>
      )}
    </div>
  );
}

export default NewBanner;
