import React, { useEffect, useState } from 'react';
import { Col, Row, FormGroup, Button, Card, CardBody, CardTitle, Input, Table, UncontrolledTooltip } from 'reactstrap';
import Chart from 'chart.js';

import axios from '../../../axios/index';
import DateSetting from '../../../components/Pages/DatePicker';
import { numberWithCommas } from '../../../components/Pages/commonFunction';
import useStore from '../../../stores/useStore';

interface pointTypeProps {
  type: number;
  locale: {
    kr: string;
    us: string;
  };
}

interface couponDataProps {
  couponPrice: number | string;
  overCount: number;
  overPersent: string;
  overPrice: number;
  publishCount: number;
  publishPrice: number;
  usedCount: number;
  usedPersent: string;
  usedPrice: number;
}

interface rateProps {
  type1: number;
  maxType1: number;
  type2: number;
  maxType2: number;
  type3: number;
  maxType3: number;
  type4: number;
  maxType4: number;
  type4First: number;
  type5: number;
  maxType5: number;
  type5First: number;
  type6: number;
  minType6: number;
  type7: number;
  minType7: number;
  type8: number;
  maxMainSet: number;
  maxSubSet: number;
  defaultFactor: string;
  mecuteTimeFactor: string;
  vipFactor: string;
  couponPrice: number;
}

interface prevRateProps {
  factorRates: {
    defalt: string;
    vip: string;
    mecutTime: string;
  };
  couponPrice: number;
  sumPrice: number;
  sumCount: number;
}

export default function CoffeeTest() {
  const { userState } = useStore();
  const [pickDate, setPickDate] = useState({ pin: '', start: '', limit: '' });
  const [pointType, setPointType] = useState();

  const [activeTab, setActiveTab] = useState(1);

  const [totalData, setTotalData] = useState<couponDataProps>();
  const [chartData, setChartData] = useState<any>({});

  const [activeGender, setActiveGender] = useState(0);

  const [coffeePopup, setCoffeePopup] = useState({
    view: false,
    state: 0,
  });
  const [prevRate, setPrevRate] = useState<prevRateProps>();
  const [defaultRate, setDefaultRate] = useState<rateProps>();
  const [reservedRate, setReservedRate] = useState<rateProps | null>();
  const [coffeeRate, setCoffeeRate] = useState({});

  const [confirmPopup, setConfirmPopup] = useState({ view: false, type: '' });
  const [inputs, setInputs] = useState({
    type1: 0, //	채팅(선톡) 회당
    maxType1: 0, //	채팅(선톡) 최대
    type2: 0, //	채팅 많이 회당
    maxType2: 0, //	채팅 많이 최대
    type3: 0, //	프로필 사진 등록 회당
    maxType3: 0, //	프로필 사진 등록 최대
    type4: 0, //	라이크 받기 회당
    maxType4: 0, //	라이크 받기 최대
    type4First: 0, //라이크 받기 최초
    type5: 0, //	자기소개 심사(여) 회당
    maxType5: 0, //	자기소개 심사(여) 최대
    type5First: 0, //자기소개 심사(여) 최초
    type6: 0, //	풍선보내기 회당
    minType6: 0, //	풍선보내기 최소
    type7: 0, //	풍선 받기 회당
    minType7: 0, //	풍선 받기 최소
    type8: 0, //vip보너스
    maxMainSet: 0, //	아이템보냄,받음
    maxSubSet: 0, //	아이템보냄,받음을 제외한 나머지 활동
    defaultFactor: 0, //	일반 계수
    mecuteTimeFactor: 0, //	미큐타임 계수
    vipFactor: 0, //	vip/mvp 계수,
    couponPrice: '0', //커피값
  });

  const {
    type1,
    maxType1,
    type2,
    maxType2,
    type3,
    maxType3,
    type4,
    maxType4,
    type4First,
    type5,
    maxType5,
    type5First,
    type6,
    minType6,
    type7,
    type8,
    minType7,
    maxMainSet,
    maxSubSet,
    defaultFactor,
    mecuteTimeFactor,
    vipFactor,
    couponPrice,
  } = inputs;

  useEffect(() => {
    setInputs({
      ...inputs,
      couponPrice: numberWithCommas(Number(couponPrice.replace(/,/gi, ''))),
    });
  }, [couponPrice]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value, name } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const getDate = (date?: string) => {
    if (date) {
      const dateParse = JSON.parse(date);
      setPickDate({
        pin: dateParse.pinDate,
        start: dateParse.startDate,
        limit: dateParse.endDate,
      });
    }
  };

  const confirmClick = (type: string) => {
    setConfirmPopup({
      view: true,
      type: type,
    });
  };
  const confirmClose = (type: string) => {
    if (type === 'coffeeRate') coffeeClose();
    setConfirmPopup({ view: false, type: '' });
  };

  const getPointType = () => {
    axios.get(`/common/reference/code/point/type`).then(res => {
      setPointType(res.data.data.rows);
    });
  };

  // 차트값
  const [tooltips, setTooltips] = useState<any>([]);
  const getChartData = () => {
    if (!pickDate.pin) return;
    axios
      .get(
        `/coffee/statistics/terms?pinDate=` +
          pickDate.pin +
          `&startDate=` +
          pickDate.start +
          `&limitDate=` +
          pickDate.limit,
      )
      .then(res => {
        setTotalData(res.data.data.rows.coupon);
        const data = res.data.data.rows.data;
        let label: string[] = res.data.data.rows.labels;
        label = label.map((item: string, idx: number) => {
          return item + ' / ' + data[idx].coffeeCoupon + '잔';
        });
        setTooltips(data);
        const dataset: any[] = [];
        const tooltip: any[] = [];
        data.map((wrap: any, idx: number) => {
          const couponLeng = wrap.coffeeCoupon;
          wrap.dataset.map((item: any, idx1: number) => {
            if (idx === 0) {
              dataset.push({});
            }
            pointType?.map((type: pointTypeProps) => {
              if (type.type === item.pointType) {
                const typeNumber = type.type;
                if (dataset[typeNumber - 1] !== undefined && !dataset[typeNumber - 1].label) {
                  let backgroundColor;
                  let hoverBackgroundColor;
                  switch (typeNumber) {
                    case 1: //풍선보내기
                      backgroundColor = '#B5F1E3';
                      hoverBackgroundColor = '#06C798';
                      break;
                    case 2: //풍선받기
                      backgroundColor = '#FFE1BE';
                      hoverBackgroundColor = '#FFA740';
                      break;
                    case 3: //채팅선톡
                      backgroundColor = '#BA8FFF';
                      hoverBackgroundColor = '#8840FF';
                      break;
                    case 4: //채팅많이
                      backgroundColor = '#FFADE8';
                      hoverBackgroundColor = '#FF40C9';
                      break;
                    case 5: //프로필사진
                      backgroundColor = '#9BDAFD';
                      hoverBackgroundColor = '#40BAFF';
                      break;
                    case 6: //라이크받기
                      backgroundColor = '#BDFCF8';
                      hoverBackgroundColor = '#40FFF4';
                      break;
                    case 7: //자기소개심사
                      backgroundColor = '#FCB0AB';
                      hoverBackgroundColor = '#FF4B40';
                      break;
                    case 8: //VIP보너스
                      backgroundColor = '#FCB0AB';
                      hoverBackgroundColor = '#FF4B40';
                      break;
                  }
                  dataset[typeNumber - 1] = {
                    label: type.locale.kr,
                    data: [couponLeng * item.pointAbsolutePersent],
                    backgroundColor: backgroundColor,
                    hoverBackgroundColor: hoverBackgroundColor,
                    maxBarThickness: 30,
                  };
                  tooltip.push(item.pointAbsolutePersent);
                } else {
                  if (dataset[typeNumber - 1] !== undefined) {
                    dataset[typeNumber - 1].data.push(couponLeng * item.pointAbsolutePersent);
                    tooltip.push(item.pointAbsolutePersent);
                  }
                }
              }
            });
          });
          if (data.length === idx + 1) {
            setChartData({ labels: label, datasets: dataset, tooltips: tooltip });
          }
        });
      });
  };

  //chart Draw
  const drawChart = () => {
    const filed = document.getElementById('chart');
    if (filed?.querySelector('canvas')) {
      filed.querySelector('canvas')?.remove();
    }
    const _draw = setInterval(() => {
      if (!filed?.querySelector('canvas')) {
        clearInterval(_draw);
        const canvas = document.createElement('canvas');
        document.getElementById('chart')?.appendChild(canvas);
        const context = canvas?.getContext('2d');
        if (context) {
          new Chart(context, {
            type: 'bar',
            data: chartData,
            options: {
              tooltips: {
                intersect: false,
                mode: 'index',
                callbacks: {
                  label: function(tooltipItem, data: any) {
                    const num = Number(tooltipItem.datasetIndex);
                    const index = Number(tooltipItem.index);
                    return (
                      data.datasets[num].label + '(1잔) : ' + tooltips[index].dataset[num].pointAbsolutePersent + '%'
                    );
                  },
                },
              },
              responsive: true,
              scales: {
                xAxes: [
                  {
                    stacked: true,
                  },
                ],
                yAxes: [
                  {
                    stacked: true,
                  },
                ],
              },
            },
          });
        }
      }
    }, 10);
  };

  const coffeeClick = () => {
    setCoffeePopup({
      view: true,
      state: 1,
    });
  };
  const coffeeClose = () => {
    setCoffeePopup({
      view: false,
      state: 0,
    });
  };
  const coffeeSubmit = () => {
    const params = {
      type1: type1 * 100,
      maxType1: maxType1 * 100,
      type2: type2 * 100,
      maxType2: maxType2 * 100,
      type3: type3 * 100,
      maxType3: maxType3 * 100,
      type4: type4 * 100,
      maxType4: maxType4 * 100,
      type4First: type4First * 100,
      type5: type5 * 100,
      maxType5: maxType5 * 100,
      type5First: type5First * 100,
      type6: type6 * 100,
      minType6: minType6 * 100,
      type7: type7 * 100,
      minType7: minType7 * 100,
      type8: type8 * 100,
      maxMainSet: maxMainSet * 100,
      maxSubSet: maxSubSet * 100,
      defaultFactor: defaultFactor,
      mecuteTimeFactor: mecuteTimeFactor,
      vipFactor: vipFactor,
      couponPrice: couponPrice.replace(/,/gi, ''),
    };
    axios
      .post(`/coffee/control/rates`, params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then(res => {
        getCoffeeRate();
        setCoffeePopup({
          view: true,
          state: 2,
        });
      });
  };

  const rateClick = () => {
    //즉시배포 버튼클릭
  };
  const rateClose = () => {
    //즉시배포 닫기
  };

  const rateDistribute = () => {
    axios.post(`/coffee/batch/publish/rate`).then(res => {
      getCoffeeRate();
    });
  };

  //커피비율
  const getCoffeeRate = () => {
    axios.get(`/coffee/control/rates`).then(res => {
      const result = res.data.data.rows;
      setPrevRate(result.status);
      setDefaultRate(result.service);
      if (result.reserved.defaultFactor) {
        setReservedRate(result.reserved);
      } else {
        setReservedRate(null);
      }
    });
  };

  useEffect(() => {
    getCoffeeRate();
  }, []);

  useEffect(() => {
    if (defaultRate) {
      setInputs({
        type1: defaultRate.type1 / 100,
        maxType1: defaultRate.maxType1 / 100,
        type2: defaultRate.type2 / 100,
        maxType2: defaultRate.maxType2 / 100,
        type3: defaultRate.type3 / 100,
        maxType3: defaultRate.maxType3 / 100,
        type4: defaultRate.type4 / 100,
        maxType4: defaultRate.maxType4 / 100,
        type4First: defaultRate.type4First / 100,
        type5: defaultRate.type5 / 100,
        maxType5: defaultRate.maxType5 / 100,
        type5First: defaultRate.type5First / 100,
        type6: defaultRate.type6 / 100,
        minType6: defaultRate.minType6 / 100,
        type7: defaultRate.type7 / 100,
        type8: defaultRate.type8 / 100,
        minType7: defaultRate.minType7 / 100,
        maxMainSet: defaultRate.maxMainSet / 100,
        maxSubSet: defaultRate.maxSubSet / 100,
        defaultFactor: Number(defaultRate.defaultFactor),
        mecuteTimeFactor: Number(defaultRate.mecuteTimeFactor),
        vipFactor: Number(defaultRate.vipFactor),
        couponPrice: defaultRate.couponPrice.toString(),
      });
    }
  }, [defaultRate]);

  //pointType, getPinDate
  useEffect(() => {
    getPointType();
    getDate();
  }, []);

  //Date,pointType 값에 따라 chartData 갱신
  useEffect(() => {
    getChartData();
  }, [pickDate, pointType]);

  //chartData에 따라 chart 갱신
  useEffect(() => {
    drawChart();
  }, [chartData]);

  return (
    <>
      <div className="body-section">
        <div className="contentsContainer">
          <div className="pageInfo">
            <span className="text-muted">커피 / </span>
            <span className="text-primary">사용량 및 설정</span>
          </div>
          <h1 className="pageTitle">사용량 및 설정</h1>

          {userState.user.permitions.gradeCode !== 1 && (
            <>
              <ul className="contentsTab">
                <li className={activeTab === 0 ? 'active' : ''} onClick={() => setActiveTab(0)}>
                  기간별 사용 통계
                </li>
                <li className={activeTab === 1 ? 'active' : ''} onClick={() => setActiveTab(1)}>
                  비율설정
                </li>
              </ul>
            </>
          )}
          <div className="contentsWrap">
            {userState.user.permitions.gradeCode === 1 && <p>권한이 없습니다.</p>}
            {userState.user.permitions.gradeCode !== 1 && (
              <>
                {activeTab === 0 && (
                  <>
                    <Row>
                      <Col md="10">
                        <FormGroup className="row mb-0">
                          <DateSetting getDate={getDate} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <Card className="card-stats">
                          <CardBody>
                            <Row>
                              <div className="col text-center">
                                <CardTitle tag="h5" className="text-left text-uppercase text-muted mb-0">
                                  발급
                                </CardTitle>
                                {totalData && (
                                  <>
                                    <span className="h1 font-weight-bold mb-0">
                                      {totalData.publishCount}
                                      <small>잔</small>
                                    </span>
                                    <br />
                                    <span className="text-muted ml-2">
                                      <small>{numberWithCommas(totalData.publishPrice)}₩</small>
                                    </span>
                                  </>
                                )}
                              </div>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="4">
                        <Card className="card-stats">
                          <CardBody>
                            <Row>
                              <div className="col text-center">
                                <CardTitle tag="h5" className="text-left text-uppercase text-muted mb-0">
                                  사용
                                </CardTitle>
                                {totalData && (
                                  <>
                                    <span className="h1 font-weight-bold mb-0">
                                      {totalData.usedCount}
                                      <small>잔</small>
                                    </span>
                                    <br />
                                    <span className="text-muted ml-2">
                                      <small className="text-danger">{numberWithCommas(totalData.usedPrice)}₩</small>
                                    </span>
                                  </>
                                )}
                              </div>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="4">
                        <Card className="card-stats">
                          <CardBody>
                            <Row>
                              <div className="col text-center">
                                <CardTitle tag="h5" className="text-left text-uppercase text-muted mb-0">
                                  만료
                                </CardTitle>
                                {totalData && (
                                  <>
                                    <span className="h1 font-weight-bold mb-0">
                                      {totalData.overCount}
                                      <small>잔</small>
                                    </span>
                                    <br />
                                    <span className="text-primary ml-2">
                                      <small>{numberWithCommas(totalData.overPrice)}₩</small>
                                    </span>
                                  </>
                                )}
                              </div>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="contentsBorder">
                          <Row className="mb-4">
                            <Col md="10">
                              <h2 className="mb-0">앱 내 유저 활동량</h2>
                              <span>총 커피쿠폰 개수 대비 유저들의 활동(행동) 비율</span>
                            </Col>
                            <Col md="2">
                              <Input bssize="sm" id="exampleFormControlSelect1" type="select">
                                <option>전체</option>
                                <option>일반</option>
                                <option>미큐타임</option>
                              </Input>
                            </Col>
                          </Row>
                          <div className="chart" id="chart" style={{ minHeight: '420px' }}></div>
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
                {activeTab === 1 && (
                  <>
                    <div>
                      <Row>
                        <Col>
                          <div className="contentsBorder">
                            <h4>
                              전날 발급된 총 커피
                              <small className="text-muted ml-2">
                                {prevRate && prevRate.couponPrice ? numberWithCommas(prevRate.couponPrice) : '-'}원 /
                                설정 계수 :{prevRate && prevRate.factorRates.defalt ? prevRate.factorRates.defalt : '-'}
                              </small>
                            </h4>
                            <Row>
                              <Col md="8" className="border-right mt-2 mb-0">
                                <span className="text-muted">비용</span>
                                <p className="font30 text-right mb-0">
                                  <b>{prevRate && numberWithCommas(prevRate.sumPrice)}</b>
                                  <small>원</small>
                                </p>
                              </Col>
                              <Col md="4" className="mb-0 mt-2">
                                <span className="text-muted">커피 개수</span>
                                <p className="font30 text-right mb-0">
                                  <b>{prevRate && numberWithCommas(prevRate.sumCount)}</b>
                                  <small>잔</small>
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col>
                          <div className="contentsBorder">
                            <h4>예상 발급 커피</h4>
                            <Row>
                              <Col md="8" className="border-right mt-2 mb-0">
                                <span className="text-muted">비용</span>
                                <p className="font30 text-right mb-0 text-primary">
                                  <b>
                                    {prevRate &&
                                      numberWithCommas(
                                        prevRate.sumCount * Number(couponPrice.replace(/,/gi, '')) * defaultFactor,
                                      )}
                                  </b>
                                  <small>원</small>
                                </p>
                              </Col>
                              <Col md="4">
                                <span className="text-muted">커피 개수</span>
                                <p className="font30 text-right mb-0 text-primary">
                                  <b>{prevRate && numberWithCommas(prevRate.sumCount * defaultFactor)}</b>
                                  <small>잔</small>
                                </p>
                              </Col>
                            </Row>
                            <Row className="mb-0 mt-4">
                              <Col sm="5">
                                <Row>
                                  <Col sm="4" className="pr-0">
                                    <span>커피값</span>
                                  </Col>
                                  <Col sm="4" className="pr-0">
                                    <span>
                                      <Input
                                        bssize="sm"
                                        type="text"
                                        className="border"
                                        placeholder={numberWithCommas(
                                          defaultRate?.couponPrice ? defaultRate?.couponPrice : 0,
                                        )}
                                        name="couponPrice"
                                        value={couponPrice}
                                        onChange={onChange}
                                      />
                                    </span>
                                  </Col>
                                </Row>
                              </Col>
                              <Col sm="5">
                                <Row>
                                  <Col sm="4" className="pr-0">
                                    <span className="text-primary">오늘의 계수</span>
                                  </Col>
                                  <Col sm="4" className="pr-0">
                                    <span>
                                      <Input
                                        bssize="sm"
                                        className="border-primary"
                                        placeholder={defaultRate?.defaultFactor}
                                        name="defaultFactor"
                                        value={defaultFactor}
                                        onChange={onChange}
                                      />
                                    </span>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-4 mb-0">
                        <Col md="6">
                          <div className="contentsBorder">
                            <h4 className="d-inline-block mr-2">커피 비율 재설정</h4>
                            <Row>
                              <Table
                                className="notHover mt-2 align-items-center table-flush text-center align-middle"
                                responsive>
                                <thead>
                                  <tr>
                                    <th style={{ minWidth: '110px' }}>행동</th>
                                    <th></th>
                                    <th style={{ minWidth: '80px' }} className="pl-0">
                                      기본
                                    </th>
                                    <th style={{ minWidth: '80px' }} className="text-primary pl-0">
                                      계수적용
                                    </th>
                                    <th style={{ minWidth: '80px' }} className="text-danger pl-0">
                                      미큐타임
                                    </th>
                                    <th style={{ minWidth: '100px' }}>최대</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td className="text-left">풍선보내기</td>
                                    <td>개당</td>
                                    <td className="hasInput pl-0">
                                      <div>
                                        <Input
                                          className="rightText"
                                          bssize="sm"
                                          type="number"
                                          name="type6"
                                          value={type6}
                                          onChange={onChange}
                                          placeholder={defaultRate ? defaultRate.type6 / 100 : false}
                                        />
                                        <span className="innerRight">%</span>
                                      </div>
                                    </td>
                                    <td className="hasInput pl-0">
                                      <div>
                                        <span className="text-primary">{defaultFactor * type6}%</span>
                                      </div>
                                    </td>
                                    <td className="hasInput pl-0">
                                      <div>
                                        <span className="text-danger">{mecuteTimeFactor * type6}%</span>
                                      </div>
                                    </td>
                                    <td className="hasInput pl-0">
                                      <div>
                                        <Input
                                          className="rightText"
                                          bssize="sm"
                                          type="number"
                                          name="minType6"
                                          value={minType6}
                                          onChange={onChange}
                                          placeholder={defaultRate ? defaultRate.minType6 / 100 : false}
                                        />
                                        <span className="innerRight">%</span>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-left">풍선 받기</td>
                                    <td>개당</td>
                                    <td className="hasInput pl-0">
                                      <div>
                                        <Input
                                          className="rightText"
                                          bssize="sm"
                                          type="number"
                                          name="type7"
                                          value={type7}
                                          onChange={onChange}
                                          placeholder={defaultRate ? defaultRate.type7 / 100 : false}
                                        />
                                        <span className="innerRight">%</span>
                                      </div>
                                    </td>
                                    <td className="hasInput pl-0">
                                      <div>
                                        <span className="text-primary">{defaultFactor * type7}%</span>
                                      </div>
                                    </td>
                                    <td className="hasInput pl-0">
                                      <div>
                                        <span className="text-danger">{mecuteTimeFactor * type7}%</span>
                                      </div>
                                    </td>
                                    <td className="hasInput pl-0">
                                      <div>
                                        <Input
                                          className="rightText"
                                          bssize="sm"
                                          type="number"
                                          name="minType7"
                                          value={minType7}
                                          onChange={onChange}
                                          placeholder={defaultRate ? defaultRate.minType7 / 100 : false}
                                        />
                                        <span className="innerRight">%</span>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-left">
                                      채팅 선톡
                                      <span className="ml-2 align-middle tip" id="tooltip">
                                        <small>
                                          <i className="far fa-question-circle"></i>
                                        </small>
                                        <UncontrolledTooltip delay={0} trigger="hover focus" target="tooltip">
                                          <div className="text-left">
                                            채팅 선톡 기준 : <br />
                                            대화창에서 먼저 첫 대화를 건넬때마다 1회로 간주
                                          </div>
                                        </UncontrolledTooltip>
                                      </span>
                                    </td>
                                    <td>회당</td>
                                    <td className="hasInput pl-0">
                                      <div>
                                        <Input
                                          className="rightText"
                                          bssize="sm"
                                          type="number"
                                          name="type1"
                                          value={type1}
                                          onChange={onChange}
                                          placeholder={defaultRate ? defaultRate.type1 / 100 : false}
                                        />
                                        <span className="innerRight">%</span>
                                      </div>
                                    </td>
                                    <td className="hasInput pl-0">
                                      <div>
                                        <span className="text-primary">{defaultFactor * type1}%</span>
                                      </div>
                                    </td>
                                    <td className="hasInput pl-0">
                                      <div>
                                        <span className="text-danger">{mecuteTimeFactor * type1}%</span>
                                      </div>
                                    </td>
                                    <td className="hasInput pl-0">
                                      <div>
                                        <Input
                                          className="rightText"
                                          bssize="sm"
                                          type="number"
                                          name="maxType1"
                                          value={maxType1}
                                          onChange={onChange}
                                          placeholder={defaultRate ? defaultRate.maxType1 / 100 : false}
                                        />
                                        <span className="innerRight">%</span>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-left">
                                      채팅 많이
                                      <span className="ml-2 align-middle tip" id="tooltip1">
                                        <small>
                                          <i className="far fa-question-circle"></i>
                                        </small>
                                        <UncontrolledTooltip delay={0} trigger="hover focus" target="tooltip1">
                                          <div className="text-left">
                                            채팅 많이 기준 : <br />
                                            동일 상대와 처음으로 여자는 4번, 남자는 10회 충족하면 1회로 간주, 그
                                            이후부터는 여자 12번, 남자 20번마다 1회로 간주.
                                          </div>
                                        </UncontrolledTooltip>
                                      </span>
                                    </td>
                                    <td>회당</td>
                                    <td className="hasInput pl-0">
                                      <div>
                                        <Input
                                          className="rightText"
                                          bssize="sm"
                                          type="number"
                                          name="type2"
                                          value={type2}
                                          onChange={onChange}
                                          placeholder={defaultRate ? defaultRate.type2 / 100 : false}
                                        />
                                        <span className="innerRight">%</span>
                                      </div>
                                    </td>
                                    <td className="hasInput pl-0">
                                      <div>
                                        <span className="text-primary">{defaultFactor * type2}%</span>
                                      </div>
                                    </td>
                                    <td className="hasInput pl-0">
                                      <div>
                                        <span className="text-danger">{mecuteTimeFactor * type2}%</span>
                                      </div>
                                    </td>
                                    <td className="hasInput pl-0">
                                      <div>
                                        <Input
                                          className="rightText"
                                          bssize="sm"
                                          type="number"
                                          name="maxType2"
                                          value={maxType2}
                                          onChange={onChange}
                                          placeholder={defaultRate ? defaultRate.maxType2 / 100 : false}
                                        />
                                        <span className="innerRight">%</span>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-left">프로필 사진</td>
                                    <td>회당</td>
                                    <td className="hasInput pl-0">
                                      <div>
                                        <Input
                                          className="rightText"
                                          bssize="sm"
                                          type="number"
                                          name="type3"
                                          value={type3}
                                          onChange={onChange}
                                          placeholder={defaultRate ? defaultRate.type3 / 100 : false}
                                        />
                                        <span className="innerRight">%</span>
                                      </div>
                                    </td>
                                    <td className="hasInput pl-0">
                                      <div>
                                        <span className="text-primary">{defaultFactor * type3}%</span>
                                      </div>
                                    </td>
                                    <td className="hasInput pl-0">
                                      <div>
                                        <span className="text-danger">{mecuteTimeFactor * type3}%</span>
                                      </div>
                                    </td>
                                    <td className="hasInput pl-0">
                                      <div>
                                        <Input
                                          className="rightText"
                                          bssize="sm"
                                          type="number"
                                          name="maxType3"
                                          value={maxType3}
                                          onChange={onChange}
                                          placeholder={defaultRate ? defaultRate.maxType3 / 100 : false}
                                        />
                                        <span className="innerRight">%</span>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-left">라이크 받기</td>
                                    <td>회당</td>
                                    <td className="hasInput pl-0">
                                      <div>
                                        <Input
                                          className="rightText"
                                          bssize="sm"
                                          type="number"
                                          name="type4"
                                          value={type4}
                                          onChange={onChange}
                                          placeholder={defaultRate ? defaultRate.type4 / 100 : false}
                                        />
                                        <span className="innerRight">%</span>
                                      </div>
                                    </td>
                                    <td className="hasInput pl-0">
                                      <div>
                                        <span className="text-primary">{defaultFactor * type4}%</span>
                                      </div>
                                    </td>
                                    <td className="hasInput pl-0">
                                      <div>
                                        <span className="text-danger">{mecuteTimeFactor * type4}%</span>
                                      </div>
                                    </td>
                                    <td className="hasInput pl-0">
                                      <div>
                                        <Input
                                          className="rightText"
                                          bssize="sm"
                                          type="number"
                                          name="maxType4"
                                          value={maxType4}
                                          onChange={onChange}
                                          placeholder={defaultRate ? defaultRate.maxType4 / 100 : false}
                                        />
                                        <span className="innerRight">%</span>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-left">자기소개 심사(여)</td>
                                    <td>회당</td>
                                    <td className="hasInput pl-0">
                                      <div>
                                        <Input
                                          className="rightText"
                                          bssize="sm"
                                          type="number"
                                          name="type5"
                                          value={type5}
                                          onChange={onChange}
                                          placeholder={defaultRate ? defaultRate.type5 / 100 : false}
                                        />
                                        <span className="innerRight">%</span>
                                      </div>
                                    </td>
                                    <td className="hasInput pl-0">
                                      <div>
                                        <span className="text-primary">{defaultFactor * type5}%</span>
                                      </div>
                                    </td>
                                    <td className="hasInput pl-0">
                                      <div>
                                        <span className="text-danger">{mecuteTimeFactor * type5}%</span>
                                      </div>
                                    </td>
                                    <td className="hasInput pl-0">
                                      <div>
                                        <Input
                                          className="rightText"
                                          bssize="sm"
                                          type="number"
                                          name="maxType5"
                                          value={maxType5}
                                          onChange={onChange}
                                          placeholder={defaultRate ? defaultRate.maxType5 / 100 : false}
                                        />
                                        <span className="innerRight">%</span>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                              <Button
                                bssize="sm"
                                color="default"
                                outline
                                className="pr-4 pl-4 m-auto"
                                onClick={() => {
                                  coffeeClick();
                                }}>
                                배포
                              </Button>
                            </Row>
                          </div>
                        </Col>
                        {reservedRate && (
                          <Col sm="6">
                            <div className="contentsBorder">
                              <h4>
                                예정 배포값
                                <small className="text-muted ml-2">
                                  2021.07.24 / {numberWithCommas(reservedRate.couponPrice)}원 / 00:00~
                                </small>
                              </h4>
                              <Row>
                                <Col>
                                  <Table
                                    className="notHover mt-2 align-items-center table-flush text-center align-middle"
                                    responsive>
                                    <thead>
                                      <tr>
                                        <th style={{ minWidth: '110px' }}>행동</th>
                                        <th></th>
                                        <th style={{ minWidth: '80px' }} className="pl-0">
                                          기본
                                        </th>
                                        <th style={{ minWidth: '80px' }} className="text-primary pl-0">
                                          계수적용
                                        </th>
                                        <th style={{ minWidth: '80px' }} className="text-danger pl-0">
                                          미큐타임
                                        </th>
                                        <th style={{ minWidth: '100px' }}>최대</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td className="text-left">풍선보내기</td>
                                        <td>개당</td>
                                        <td className="hasInput pl-0">
                                          <div>{reservedRate.type6 / 100}%</div>
                                        </td>
                                        <td className="hasInput pl-0">
                                          <div>
                                            <span className="text-primary">{defaultFactor * type6}%</span>
                                          </div>
                                        </td>
                                        <td className="hasInput pl-0">
                                          <div>
                                            <span className="text-danger">{mecuteTimeFactor * type6}%</span>
                                          </div>
                                        </td>
                                        <td className="hasInput pl-0">
                                          <div>{reservedRate.minType6 / 100} %</div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="text-left">풍선 받기</td>
                                        <td>개당</td>
                                        <td className="hasInput pl-0">
                                          <div>{reservedRate.type7 / 100} %</div>
                                        </td>
                                        <td className="hasInput pl-0">
                                          <div>
                                            <span className="text-primary">{defaultFactor * type7}%</span>
                                          </div>
                                        </td>
                                        <td className="hasInput pl-0">
                                          <div>
                                            <span className="text-danger">{mecuteTimeFactor * type7}%</span>
                                          </div>
                                        </td>
                                        <td className="hasInput pl-0">
                                          <div>{reservedRate.minType7 / 100} %</div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="text-left">
                                          채팅 선톡
                                          <span className="ml-2 align-middle tip" id="tooltip">
                                            <small>
                                              <i className="far fa-question-circle"></i>
                                            </small>
                                            <UncontrolledTooltip delay={0} trigger="hover focus" target="tooltip">
                                              <div className="text-left">
                                                채팅 선톡 기준 : <br />
                                                대화창에서 먼저 첫 대화를 건넬때마다 1회로 간주
                                              </div>
                                            </UncontrolledTooltip>
                                          </span>
                                        </td>
                                        <td>회당</td>
                                        <td className="hasInput pl-0">
                                          <div>{reservedRate.type1 / 100} %</div>
                                        </td>
                                        <td className="hasInput pl-0">
                                          <div>
                                            <span className="text-primary">{defaultFactor * type1}%</span>
                                          </div>
                                        </td>
                                        <td className="hasInput pl-0">
                                          <div>
                                            <span className="text-danger">{mecuteTimeFactor * type1}%</span>
                                          </div>
                                        </td>
                                        <td className="hasInput pl-0">
                                          <div>{reservedRate.maxType1 / 100} %</div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="text-left">
                                          채팅 많이
                                          <span className="ml-2 align-middle tip" id="tooltip1">
                                            <small>
                                              <i className="far fa-question-circle"></i>
                                            </small>
                                            <UncontrolledTooltip delay={0} trigger="hover focus" target="tooltip1">
                                              <div className="text-left">
                                                채팅 많이 기준 : <br />
                                                동일 상대와 처음으로 여자는 4번, 남자는 10회 충족하면 1회로 간주, 그
                                                이후부터는 여자 12번, 남자 20번마다 1회로 간주.
                                              </div>
                                            </UncontrolledTooltip>
                                          </span>
                                        </td>
                                        <td>회당</td>
                                        <td className="hasInput pl-0">
                                          <div>{reservedRate.type2 / 100} %</div>
                                        </td>
                                        <td className="hasInput pl-0">
                                          <div>
                                            <span className="text-primary">{defaultFactor * type2}%</span>
                                          </div>
                                        </td>
                                        <td className="hasInput pl-0">
                                          <div>
                                            <span className="text-danger">{mecuteTimeFactor * type2}%</span>
                                          </div>
                                        </td>
                                        <td className="hasInput pl-0">
                                          <div>{reservedRate.maxType2 / 100} %</div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="text-left">프로필 사진</td>
                                        <td>회당</td>
                                        <td className="hasInput pl-0">
                                          <div>{reservedRate.type3 / 100} %</div>
                                        </td>
                                        <td className="hasInput pl-0">
                                          <div>
                                            <span className="text-primary">{defaultFactor * type3}%</span>
                                          </div>
                                        </td>
                                        <td className="hasInput pl-0">
                                          <div>
                                            <span className="text-danger">{mecuteTimeFactor * type3}%</span>
                                          </div>
                                        </td>
                                        <td className="hasInput pl-0">
                                          <div>{reservedRate.maxType3 / 100} %</div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="text-left">라이크 받기</td>
                                        <td>회당</td>
                                        <td className="hasInput pl-0">
                                          <div>{reservedRate.type4 / 100} %</div>
                                        </td>
                                        <td className="hasInput pl-0">
                                          <div>
                                            <span className="text-primary">{defaultFactor * type4}%</span>
                                          </div>
                                        </td>
                                        <td className="hasInput pl-0">
                                          <div>
                                            <span className="text-danger">{mecuteTimeFactor * type4}%</span>
                                          </div>
                                        </td>
                                        <td className="hasInput pl-0">
                                          <div>{reservedRate.maxType4 / 100} %</div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="text-left">자기소개 심사(여)</td>
                                        <td>회당</td>
                                        <td className="hasInput pl-0">
                                          <div>{reservedRate.type5 / 100} %</div>
                                        </td>
                                        <td className="hasInput pl-0">
                                          <div>
                                            <span className="text-primary">{defaultFactor * type5}%</span>
                                          </div>
                                        </td>
                                        <td className="hasInput pl-0">
                                          <div>
                                            <span className="text-danger">{mecuteTimeFactor * type5}%</span>
                                          </div>
                                        </td>
                                        <td className="hasInput pl-0">
                                          <div>{reservedRate.maxType5 / 100} %</div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                  <Button
                                    bssize="sm"
                                    color="danger"
                                    outline
                                    onClick={rateDistribute}
                                    className="float-right">
                                    즉시배포
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        )}
                        {!reservedRate && (
                          <Col sm="6">
                            <div className="contentsBorder">
                              <h4>
                                예정 배포값
                                <small className="text-muted ml-2">
                                  2021.07.24 / {defaultRate && numberWithCommas(defaultRate.couponPrice)}원 / 00:00~
                                </small>
                              </h4>
                              <Row>
                                <Col>
                                  <Table
                                    className="notHover mt-2 align-items-center table-flush text-center align-middle"
                                    responsive>
                                    <thead>
                                      <tr>
                                        <th style={{ minWidth: '110px' }}>행동</th>
                                        <th></th>
                                        <th style={{ minWidth: '80px' }} className="pl-0">
                                          기본
                                        </th>
                                        <th style={{ minWidth: '80px' }} className="text-primary pl-0">
                                          계수적용
                                        </th>
                                        <th style={{ minWidth: '80px' }} className="text-danger pl-0">
                                          미큐타임
                                        </th>
                                        <th style={{ minWidth: '100px' }}>최대</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td className="text-left">풍선보내기</td>
                                        <td>개당</td>
                                        <td className="hasInput pl-0">
                                          <div>{defaultRate && defaultRate.type6 / 100}%</div>
                                        </td>
                                        <td className="hasInput pl-0">
                                          <div>
                                            <span className="text-primary">{defaultFactor * type6}%</span>
                                          </div>
                                        </td>
                                        <td className="hasInput pl-0">
                                          <div>
                                            <span className="text-danger">{mecuteTimeFactor * type6}%</span>
                                          </div>
                                        </td>
                                        <td className="hasInput pl-0">
                                          <div>{defaultRate && defaultRate.minType6 / 100} %</div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="text-left">풍선 받기</td>
                                        <td>개당</td>
                                        <td className="hasInput pl-0">
                                          <div>{defaultRate && defaultRate.type7 / 100} %</div>
                                        </td>
                                        <td className="hasInput pl-0">
                                          <div>
                                            <span className="text-primary">{defaultFactor * type7}%</span>
                                          </div>
                                        </td>
                                        <td className="hasInput pl-0">
                                          <div>
                                            <span className="text-danger">{mecuteTimeFactor * type7}%</span>
                                          </div>
                                        </td>
                                        <td className="hasInput pl-0">
                                          <div>{defaultRate && defaultRate.minType7 / 100} %</div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="text-left">
                                          채팅 선톡
                                          <span className="ml-2 align-middle tip" id="tooltip">
                                            <small>
                                              <i className="far fa-question-circle"></i>
                                            </small>
                                            <UncontrolledTooltip delay={0} trigger="hover focus" target="tooltip">
                                              <div className="text-left">
                                                채팅 선톡 기준 : <br />
                                                대화창에서 먼저 첫 대화를 건넬때마다 1회로 간주
                                              </div>
                                            </UncontrolledTooltip>
                                          </span>
                                        </td>
                                        <td>회당</td>
                                        <td className="hasInput pl-0">
                                          <div>{defaultRate && defaultRate.type1 / 100} %</div>
                                        </td>
                                        <td className="hasInput pl-0">
                                          <div>
                                            <span className="text-primary">{defaultFactor * type1}%</span>
                                          </div>
                                        </td>
                                        <td className="hasInput pl-0">
                                          <div>
                                            <span className="text-danger">{mecuteTimeFactor * type1}%</span>
                                          </div>
                                        </td>
                                        <td className="hasInput pl-0">
                                          <div>{defaultRate && defaultRate.maxType1 / 100} %</div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="text-left">
                                          채팅 많이
                                          <span className="ml-2 align-middle tip" id="tooltip1">
                                            <small>
                                              <i className="far fa-question-circle"></i>
                                            </small>
                                            <UncontrolledTooltip delay={0} trigger="hover focus" target="tooltip1">
                                              <div className="text-left">
                                                채팅 많이 기준 : <br />
                                                동일 상대와 처음으로 여자는 4번, 남자는 10회 충족하면 1회로 간주, 그
                                                이후부터는 여자 12번, 남자 20번마다 1회로 간주.
                                              </div>
                                            </UncontrolledTooltip>
                                          </span>
                                        </td>
                                        <td>회당</td>
                                        <td className="hasInput pl-0">
                                          <div>{defaultRate && defaultRate.type2 / 100} %</div>
                                        </td>
                                        <td className="hasInput pl-0">
                                          <div>
                                            <span className="text-primary">{defaultFactor * type2}%</span>
                                          </div>
                                        </td>
                                        <td className="hasInput pl-0">
                                          <div>
                                            <span className="text-danger">{mecuteTimeFactor * type2}%</span>
                                          </div>
                                        </td>
                                        <td className="hasInput pl-0">
                                          <div>{defaultRate && defaultRate.maxType2 / 100} %</div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="text-left">프로필 사진</td>
                                        <td>회당</td>
                                        <td className="hasInput pl-0">
                                          <div>{defaultRate && defaultRate.type3 / 100} %</div>
                                        </td>
                                        <td className="hasInput pl-0">
                                          <div>
                                            <span className="text-primary">{defaultFactor * type3}%</span>
                                          </div>
                                        </td>
                                        <td className="hasInput pl-0">
                                          <div>
                                            <span className="text-danger">{mecuteTimeFactor * type3}%</span>
                                          </div>
                                        </td>
                                        <td className="hasInput pl-0">
                                          <div>{defaultRate && defaultRate.maxType3 / 100} %</div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="text-left">라이크 받기</td>
                                        <td>회당</td>
                                        <td className="hasInput pl-0">
                                          <div>{defaultRate && defaultRate.type4 / 100} %</div>
                                        </td>
                                        <td className="hasInput pl-0">
                                          <div>
                                            <span className="text-primary">{defaultFactor * type4}%</span>
                                          </div>
                                        </td>
                                        <td className="hasInput pl-0">
                                          <div>
                                            <span className="text-danger">{mecuteTimeFactor * type4}%</span>
                                          </div>
                                        </td>
                                        <td className="hasInput pl-0">
                                          <div>{defaultRate && defaultRate.maxType4 / 100} %</div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="text-left">자기소개 심사(여)</td>
                                        <td>회당</td>
                                        <td className="hasInput pl-0">
                                          <div>{defaultRate && defaultRate.type5 / 100} %</div>
                                        </td>
                                        <td className="hasInput pl-0">
                                          <div>
                                            <span className="text-primary">{defaultFactor * type5}%</span>
                                          </div>
                                        </td>
                                        <td className="hasInput pl-0">
                                          <div>
                                            <span className="text-danger">{mecuteTimeFactor * type5}%</span>
                                          </div>
                                        </td>
                                        <td className="hasInput pl-0">
                                          <div>{defaultRate && defaultRate.maxType5 / 100} %</div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                  <Button
                                    bssize="sm"
                                    color="danger"
                                    outline
                                    onClick={rateDistribute}
                                    className="float-right">
                                    즉시배포
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        )}
                      </Row>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {/* 커피비율 배포하기 팝업 - 배포하시겠습니까? */}
      {coffeePopup.view && coffeePopup.state !== 0 && (
        <div className="popupDefault">
          <div className="layer">
            <Row>
              <Col>
                {coffeePopup.state === 1 && <p>배포하시겠습니까?</p>}
                {coffeePopup.state === 2 && <p>배포되었습니다.</p>}
              </Col>
            </Row>
            <Row className="btnWrap">
              {coffeePopup.state === 1 && (
                <>
                  <Button
                    bssize="sm"
                    className=" btn-default"
                    onClick={() => {
                      confirmClick('coffeeRate');
                    }}>
                    아니오
                  </Button>
                  <Button bssize="sm" className="btn-default" onClick={coffeeSubmit}>
                    네
                  </Button>
                </>
              )}
              {coffeePopup.state === 2 && (
                <>
                  <Button bssize="sm" className="btn-default" onClick={coffeeClose}>
                    확인
                  </Button>
                </>
              )}
            </Row>
          </div>
        </div>
      )}
      {/* 커피비율 즉시배포 팝업 - 배포하시겠습니까? */}
      {coffeePopup.view && coffeePopup.state !== 0 && (
        <div className="popupDefault">
          <div className="layer">
            <Row>
              <Col>
                {coffeePopup.state === 1 && <p>배포하시겠습니까?</p>}
                {coffeePopup.state === 2 && <p>배포되었습니다.</p>}
              </Col>
            </Row>
            <Row className="btnWrap">
              {coffeePopup.state === 1 && (
                <>
                  <Button
                    bssize="sm"
                    className=" btn-default"
                    onClick={() => {
                      confirmClick('coffeeRate');
                    }}>
                    아니오
                  </Button>
                  <Button bssize="sm" className="btn-default" onClick={coffeeSubmit}>
                    네
                  </Button>
                </>
              )}
              {coffeePopup.state === 2 && (
                <>
                  <Button bssize="sm" className="btn-default" onClick={coffeeClose}>
                    확인
                  </Button>
                </>
              )}
            </Row>
          </div>
        </div>
      )}
    </>
  );
}
