import React, { useState, useEffect } from 'react';
import { Col, Row, Button } from 'reactstrap';

import classnames from 'classnames';

import axios from '../../../axios/index';
import PageNav from '../../../components/Pages/PageNav';
import Resource from '../../../utils/Resource';
import { getS3SourceUri } from '../../../utils/utils';

interface PhotoCheckProps {
  memberPopupOpen: (pk: number) => void;
  dataReload: boolean;
}

interface UserProps {
  pk: number;
  userPk: number;
  imageUrl: string;
  nick: string;
  gender: string;
  profileImage: string;
  updatedAt: string;
  name: string;
  career: string;
}

const ITEMS_PER_PAGE = 10;

export default function UserVerification(props: PhotoCheckProps) {
  const [restorePopup, setRestorePopup] = useState({ view: false, state: 0, stage: 'approved' });

  const [photoLists, setPhotoLists] = useState<Array<UserProps>>([]);
  const [photoTag, setPhotoTag] = useState(0);
  const [photoPage, setPhotoPage] = useState({ current: 0, max: 0 });

  const [checkboxLists, setCheckboxLists] = useState<number[]>([]);
  const [checkItem, setCheckItem] = useState<any>([]);

  //checkbox value check
  const checkedItemHandler = (e: React.ChangeEvent<HTMLInputElement>, userPk: number, pk: number, img: string) => {
    if (e.target.checked) {
      const item = { pk: pk, userPk: userPk, img: img };
      setCheckboxLists([...checkboxLists, pk]);
      setCheckItem([...checkItem, item]);
    } else {
      setCheckboxLists(checkboxLists.filter((el: any) => el !== pk));
      setCheckItem(checkItem.filter((el: any) => el.pk !== pk));
    }
  };

  //checkbox 전체선택
  const checkedItemAll = () => {
    if (photoLists.length !== checkboxLists.length) {
      const lists: number[] = [];
      const listItem: any[] = [];
      photoLists.map((item: UserProps, idx: number) => {
        lists.push(item.pk);
        listItem.push({ pk: item.pk, userPk: item.pk, img: item.imageUrl });
        if (photoLists.length - 1 === idx) {
          setCheckboxLists(lists);
          setCheckItem(listItem);
        }
      });
    } else {
      setCheckboxLists([]);
      setCheckItem([]);
    }
  };

  const memberDetailPopup = (pk: number) => {
    props.memberPopupOpen(pk);
  };

  // 사진리스트
  const getPhotoLists = (page: number, tag?: number) => {
    if (tag !== undefined && tag > -1) setPhotoTag(tag);
    axios
      .get(`/v1/member/job/verification/image?start=${page + 1}&limit=${ITEMS_PER_PAGE}`)
      .then(res => {
        const result = res.data;
        if (result.meta.itemCount === 0) {
          setPhotoLists([]);
          setCheckboxLists([]);
          setCheckItem([]);
        } else {
          setPhotoLists(result.items);
          setPhotoPage({ current: page, max: result?.meta?.totalPages ?? 1 });
        }
      })
      .catch(error => console.log(error));
  };

  // 검수완료팝업
  const restoreClick = () => {
    setRestorePopup({ view: true, stage: 'approved', state: checkboxLists.length === 0 ? -1 : 0 });
  };
  const restoreClose = () => {
    setRestorePopup({ ...restorePopup, view: false, state: 0 });
  };
  const submitVerificationStage = (stage: string) => {
    const restoreParams = {
      pks: checkboxLists,
      stage: stage,
    };
    // 인증완료 또는 반려하기
    axios
      .patch(`/v1/member/job/verification/image/stage`, restoreParams)
      .then(res => {
        if (res.status === 200) {
          setRestorePopup({ ...restorePopup, state: 1 });
          setCheckboxLists([]);
        }
      })
      .catch(err => console.log(err));
  };

  // 반려하기 팝업
  const deleteClick = () => {
    setRestorePopup({ view: true, stage: 'rejected', state: checkboxLists.length === 0 ? -1 : 0 });
  };

  useEffect(() => {
    getPhotoLists(0);
  }, []);

  return (
    <>
      <div className="body-section">
        <div className="contentsContainer">
          <div className="pageInfo">
            <span className="text-muted">회원 / </span>
            <span className="text-primary">직장인증</span>
          </div>
          <h1 className="pageTitle">직장인증</h1>
          <ul className="contentsTab">
            <li className={'active'} onClick={() => getPhotoLists(0)}>
              인증 사진
            </li>
          </ul>
          <div className="contentsWrap">
            <div className="imgList">
              {/* 탭별 상단 notiMessage */}
              <Row className="mb-3">
                <Col>
                  <span>
                    명함 또는 사원증 사진들이며 운영팀의 검수가 필요합니다.
                    <br /> 프로필사진, 실명(성별), 직업 클릭 시 상세페이지로 이동합니다.
                  </span>
                </Col>
              </Row>
              {/* 버튼영역 */}
              <Row>
                <Col lg="6" xs="12">
                  <Button color="default" bssize="sm" outline id="checkBoxAll" onClick={checkedItemAll}>
                    전체선택 ({checkboxLists.length}개 선택됨)
                  </Button>
                  <Button color="primary" bssize="sm" outline onClick={restoreClick}>
                    인증완료
                  </Button>
                  <Button color="danger" bssize="sm" outline onClick={deleteClick}>
                    반려하기
                  </Button>
                </Col>
              </Row>
              {/* 이미지 리스트 영역 */}
              <Row className="innerScroll">
                <Col className="pr-0">
                  <ul className="pl-0">
                    {photoLists.length === 0 && <li>조회된 결과가 없습니다.</li>}
                    {photoLists.length > 0 &&
                      photoLists.map((item: UserProps, idx: number) => {
                        return (
                          <li key={item.pk + photoPage.current * ITEMS_PER_PAGE}>
                            <div
                              className={classnames(
                                'img',
                                { 'border-gender-m': item.gender === 'M' },
                                { 'border-gender-f': item.gender === 'F' },
                              )}>
                              <div className="checkbox custom-control custom-checkbox labelImg">
                                <input
                                  className="custom-control-input"
                                  id={'checkBox' + item.pk}
                                  type="checkbox"
                                  checked={checkboxLists.indexOf(item.pk) > -1}
                                  onChange={e => {
                                    checkedItemHandler(e, item.pk, item.pk, item.imageUrl);
                                  }}
                                />
                                <label className="custom-control-label" htmlFor={'checkBox' + item.pk}>
                                  <img
                                    className="pointer"
                                    src={getS3SourceUri(item.imageUrl)}
                                    alt={(item.name ? item.name + '님의' : '') + ` 명함 또는 사원증`}
                                  />
                                </label>
                              </div>
                            </div>
                            <div className="userInfo">
                              <div
                                className={classnames('userImg', 'pointer', {
                                  mvp: false,
                                })}
                                style={{
                                  backgroundImage: item.profileImage
                                    ? `url(` +
                                      (item.profileImage.indexOf('imgProfileSquar') > -1
                                        ? Resource.DefaultImgDomain
                                        : Resource.ImgDomain) +
                                      item.profileImage +
                                      `)`
                                    : '',
                                }}
                                onClick={() => {
                                  memberDetailPopup(item.userPk);
                                }}
                              />
                              <div className="userName">
                                <span
                                  className="pointer"
                                  dangerouslySetInnerHTML={{
                                    __html: item.name ?? '미등록' + '(' + item.gender + ')',
                                  }}
                                  title={item.name ?? '미등록' + '(' + item.gender + ')'}
                                  onClick={() => {
                                    memberDetailPopup(item.userPk);
                                  }}></span>
                              </div>
                              {/* 직장 정보 */}
                              <div className="userName">
                                <span
                                  className="pointer"
                                  dangerouslySetInnerHTML={{
                                    __html: item.career ?? '미등록',
                                  }}
                                  title={item.career ?? '미등록'}
                                  onClick={() => {
                                    memberDetailPopup(item.userPk);
                                  }}></span>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </Col>
              </Row>
            </div>
            <Row>
              <Col>
                <PageNav
                  getPageInfo={page => getPhotoLists(page)}
                  current={photoPage.current}
                  maxPage={photoPage.max}
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>

      {/* 인증완료 팝업 */}
      {restorePopup.view && (
        <div className="popupDefault">
          <div className="layer">
            <div>
              <Row>
                <Col>
                  {restorePopup.state === -1 && (
                    <p>{restorePopup.stage === 'approved' ? '인증 완료할' : '반려할'} 사진을 선택해주세요.</p>
                  )}
                  {restorePopup.state === 0 && (
                    <p>
                      {checkboxLists.length}장의 사진을 '{restorePopup.stage === 'approved' ? '인증완료' : '인증실패'}
                      '로 지정하시겠습니까?
                    </p>
                  )}
                  {restorePopup.state === 1 && (
                    <p>'{restorePopup.stage === 'approved' ? '인증완료' : '반려'}' 되었습니다.</p>
                  )}
                </Col>
              </Row>
              {restorePopup.state === -1 && (
                <Row className="btnWrap">
                  <Button bssize="sm" className="btn-default" onClick={restoreClose}>
                    확인
                  </Button>
                </Row>
              )}
              {restorePopup.state === 0 && (
                <Row className="btnWrap">
                  <Button bssize="sm" className="btn-default" onClick={restoreClose}>
                    취소
                  </Button>
                  <Button
                    bssize="sm"
                    className=" btn-default"
                    onClick={() => submitVerificationStage(restorePopup.stage)}>
                    확인
                  </Button>
                </Row>
              )}
              {restorePopup.state === 1 && (
                <Row className="btnWrap">
                  <Button
                    bssize="sm"
                    className="btn-default"
                    onClick={() => {
                      restoreClose();
                      getPhotoLists(0, photoTag);
                    }}>
                    확인
                  </Button>
                </Row>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
