import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Col, Row, Button, Container } from 'reactstrap';

import axios from '../../../../../axios';
import { formatKoreanLocalTime, getS3SourceUri } from '../../../../../utils/utils';

export interface BannerDetailInfo {
  id: string;
  valid: boolean;
  imageUrl: string;
  linkUrl: string;
  startedAt: string;
  endedAt: string;
  createdAt: string;
}

const BannerDetail = ({ id }: { id: number }) => {
  const [detail, setDetail] = useState<BannerDetailInfo>();
  const history = useHistory();

  const handleInvalidateBannerClick = async (id: string) => {
    try {
      const response = await axios.delete(`/v1/announce-banner/${id}`);
      if (response?.status === 200) {
        alert('배너가 비노출 처리되었습니다.');
        history.push('/event/banner');
      } else {
        alert('배너 비노출 처리에 실패했습니다.');
      }
    } catch (err) {
      console.error('/v1/announce-banner/${id} DELETE 에러', err);
    }
  };

  useEffect(() => {
    const fetchBannerDetail = async () => {
      const res = await axios.get(`/v1/announce-banner/${id}`);

      if (res.status !== 200) {
        alert('상세 데이터를 가져올 수 없어요🤔');
        return;
      }

      setDetail(res.data);
    };

    fetchBannerDetail();
  }, []);

  return (
    <div className="body-section">
      <div className="contentsContainer">
        <div className="pageInfo">
          <span className="text-muted">이벤트 / </span>
          <span className="text-primary">배너 상세</span>
        </div>
        <h1 className="pageTitle">이벤트 배너 상세</h1>
        <div className="contentsWrap justify-content-center" style={{ display: 'flex', flexDirection: 'column' }}>
          {detail ? (
            <>
              <div className="justify-content-end mb-3" style={{ display: 'flex' }}>
                <Button
                  size="lg"
                  color="primary"
                  onClick={() => history.push(`/event/banner?id=${detail.id}&edit=true`, { id: detail.id })}>
                  수정하기
                </Button>
                <Button size="lg" color="danger" onClick={() => handleInvalidateBannerClick(detail.id)}>
                  비노출처리 하기
                </Button>
              </div>

              <div className="justify-content-center" style={{ display: 'flex' }}>
                <Container className="contentsBorder">
                  <Row xs="4">
                    <Col className="border-right">배너 ID</Col>
                    <Col xs="6">{detail.id}</Col>
                  </Row>
                  <Row xs="4">
                    <Col className="border-right" style={{ textTransform: 'uppercase' }}>
                      valid
                    </Col>
                    <Col xs="6" style={{ color: detail.valid ? 'blue' : 'red' }}>
                      {detail.valid ? 'valid' : 'invalid'}
                    </Col>
                  </Row>
                  <Row xs="4">
                    <Col className="border-right">이미지</Col>
                    <Col xs="6">
                      <img alt="배너 이미지" width={'304px'} height={'70px'} src={getS3SourceUri(detail.imageUrl)} />
                    </Col>
                  </Row>

                  <Row xs="4">
                    <Col className="border-right">생성일</Col>
                    <Col xs="6">{formatKoreanLocalTime(detail.createdAt)}</Col>
                  </Row>
                  <Row xs="4">
                    <Col className="border-right">노출시작 시간</Col>
                    <Col xs="6">{formatKoreanLocalTime(detail.startedAt)}</Col>
                  </Row>
                  <Row xs="4">
                    <Col className="border-right">노출종료 시간</Col>
                    <Col xs="6">{formatKoreanLocalTime(detail.endedAt)}</Col>
                  </Row>
                  <Row xs="4">
                    <Col className="border-right">연결 링크 URL</Col>
                    <Col xs="6">{detail.linkUrl || '-'}</Col>
                  </Row>
                </Container>
              </div>
            </>
          ) : (
            <p>상세 데이터를 가져올 수 없어요🤔</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default BannerDetail;
