/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import ReactDOM from 'react-dom';
// react library for routing
import { Router, Route, Switch, Redirect } from 'react-router-dom';
// plugins styles from node_modules
import 'react-notification-alert/dist/animate.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/vendor/@fortawesome/fontawesome-free/css/all.min.css';
// core styles
import './assets/scss/argon-dashboard-pro-react.scss?v1.1.0';

import { paths } from './routes';

import AdminLayout from './layouts/Admin';
import AuthLayout from './layouts/Auth';
import CoffeeTest from './views/pages/coffee/CoffeeTestPage';
import LoginStore from './stores/LoginStore';
import store from './stores/index';
import History from './components/History';
import { Provider as MobxProvider } from 'mobx-react';

const { token } = LoginStore();

if (window.location.origin.indexOf('devprivate') > -1) {
  const favicons = document.querySelectorAll('link[rel="icon"');

  for (const favicon of favicons) {
    favicon.href = favicon.href.replace('origin', 'dev');
  }
}

ReactDOM.render(
  <MobxProvider {...store}>
    <Router history={History}>
      <Switch>
        <Route path={paths} render={props => <AdminLayout {...props} />} />
        <Route path="/auth" render={props => <AuthLayout {...props} />} />
        <Route path="/test" render={props => <CoffeeTest {...props} />} />
        <Redirect from="*" to="/member/dash" />
      </Switch>
    </Router>
  </MobxProvider>,
  document.getElementById('root'),
);
