import React, { useState, useEffect, version } from 'react';
import { Col, Row, Button, Input } from 'reactstrap';

import 'rc-time-picker/assets/index.css';
import axios from '../../../axios/index';
import { countDots, removeDots } from '../../../utils/utils';

enum PopUpType {
  Distribution = 'distribution',
  Complete = 'complete',
  Failed = 'failed',
}

const initialPopUp = {
  type: PopUpType.Distribution,
  visible: false,
  message: '',
};
const initialVersionInput = {
  google: '',
  ios: '',
};

export default function AppSetting() {
  const [currentVersion, setCurrentVersion] = useState({
    google: '',
    ios: '',
  });
  const [versionInput, setVersionInput] = useState(initialVersionInput);
  const [popUp, setPopUp] = useState(initialPopUp);

  const fetchCurrentVersion = async () => {
    const response = await axios.get('/v1/main/app/version');

    if (response?.status === 200) {
      setCurrentVersion({
        google: response.data.googleVersion,
        ios: response.data.iosVersion,
      });
    }
  };

  const handleVersionInputChange: React.ChangeEventHandler<HTMLInputElement> = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = e.target;
    setVersionInput({
      ...versionInput,
      [name]: value,
    });
  };

  const makePopUp = (type: PopUpType) => {
    switch (type) {
      case PopUpType.Distribution:
        return {
          type: PopUpType.Distribution,
          visible: true,
          message: '앱 버전을 강제 배포하시겠습니까?',
        };
      case PopUpType.Complete:
        return {
          type: PopUpType.Complete,
          visible: true,
          message: '앱 버전이 강제 배포되었습니다.',
        };
      case PopUpType.Failed:
        return {
          type: PopUpType.Failed,
          visible: true,
          message: '앱 버전 배포에 실패했습니다.',
        };
    }
  };

  const handleForceVersionDistributionClick = async () => {
    if (!versionInput.google || !versionInput.ios) {
      alert('앱 버전을 입력해주세요.');
      return;
    }

    if (versionInput.google === currentVersion.google || versionInput.ios === currentVersion.ios) {
      alert('현재 버전과 동일합니다.');
      return;
    }

    if (!versionInput.google.includes('.') || !versionInput.ios.includes('.')) {
      alert('버전 형식이 올바르지 않습니다. (올바른 형식: X.X.X)');
      return;
    }

    if ([...removeDots(versionInput.google), ...removeDots(versionInput.ios)].some(char => isNaN(Number(char)))) {
      alert('"숫자" 와 "." 만 입력가능합니다. (올바른 형식: X.X.X)');
      return true;
    }

    if (
      countDots(versionInput.google) !== 2 ||
      countDots(versionInput.ios) !== 2 ||
      versionInput.google.includes('..') ||
      versionInput.ios.includes('..')
    ) {
      alert('버전 형식이 올바르지 않습니다. (올바른 형식: X.X.X)');
      return;
    }

    setPopUp(makePopUp(PopUpType.Distribution));
  };

  const changeAppVersion = async () => {
    try {
      const payload = {
        googleVersion: versionInput.google,
        iosVersion: versionInput.ios,
      };

      const response = await axios.put('/v1/main/app/version', payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response || response?.status !== 200) {
        setPopUp(makePopUp(PopUpType.Failed));
        return;
      }

      setPopUp(makePopUp(PopUpType.Complete));
      setVersionInput(initialVersionInput);
      fetchCurrentVersion();
    } catch (err) {
      setPopUp(makePopUp(PopUpType.Failed));
    }
  };

  useEffect(() => {
    fetchCurrentVersion();
  }, []);

  return (
    <>
      <div className="body-section">
        <div className="contentsContainer">
          <div className="pageInfo">
            <span className="text-muted">관리자 및 앱 / </span>
            <span className="text-primary">앱 버전 관리</span>
          </div>
          <h1 className="pageTitle">
            앱 버전 관리
            {/* <Button className="linkBtn rightBtn ml-1 mr-1" bssize="sm" color="default" outline>
              <a href="https://firebase.google.com/" target="_blank">
                푸시 보고서 확인
                <i className="fas fa-external-link-alt ml-2"></i>
              </a>
            </Button>
            <Button className="linkBtn rightBtn ml-1 mr-1" bssize="sm" color="default" outline>
              <a href="https://firebase.google.com/" target="_blank">
                푸시 알림 확인
                <i className="fas fa-external-link-alt ml-2"></i>
              </a>
            </Button> */}
          </h1>
          <div className="contentsWrap">
            <h2>버전 관리 </h2>
            <Row>
              <Col lg="3" xs="6">
                <label className="form-control-label" htmlFor="aosVersion">
                  {`* 안드로이드 앱버전  (현재: ${currentVersion.google})`}
                </label>
                <Input
                  id="aosVersion"
                  name="google"
                  placeholder="X.X.X"
                  value={versionInput.google}
                  onChange={handleVersionInputChange}
                />
              </Col>
              <Col lg="3" xs="6">
                <label className="form-control-label" htmlFor="iosVersion">
                  {`* IOS 앱버전 (현재: ${currentVersion.ios})`}
                </label>
                <Input
                  id="iosVersion"
                  name="ios"
                  placeholder="X.X.X"
                  value={versionInput.ios}
                  onChange={handleVersionInputChange}
                />
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <Button
                  bssize="sm"
                  color={'default'}
                  outline
                  disabled={!versionInput.google || !versionInput.ios}
                  onClick={handleForceVersionDistributionClick}>
                  강제 업데이트 배포 버전 설정
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      {popUp.visible && (
        <div className="popupDefault">
          <div className="layer" style={{ whiteSpace: 'break-spaces' }} onClick={() => setPopUp(initialPopUp)}>
            <Row>
              <Col>{popUp.message}</Col>
            </Row>
            <Row className="btnWrap">
              <Button
                size="sm"
                className="btn"
                onClick={() => {
                  setPopUp(initialPopUp);
                }}>
                취소
              </Button>
              <Button
                size="sm"
                className="btn-default"
                onClick={() => {
                  if (popUp.type === PopUpType.Distribution) {
                    changeAppVersion();
                    return;
                  }

                  setPopUp(initialPopUp);
                }}>
                확인
              </Button>
            </Row>
          </div>
        </div>
      )}
    </>
  );
}
