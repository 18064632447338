import moment from 'moment-timezone';
import 'moment/locale/ko';

export const getS3SourceUri = (path: string): string => {
  return `${process.env.REACT_APP_IMAGE_DOMAIN}${path}`;
};

export const formatKoreanLocalTime = (utc: string) => {
  const date = moment(utc).tz('Asia/Seoul');
  const hours = date.hours();
  const amPm = hours >= 12 ? '오후' : '오전';
  const formattedHours = hours % 12 === 0 ? '12' : String(hours % 12);
  const formattedMinutes = date.format('mm');

  return `${date.format('YYYY.MM.DD. ')}${amPm} ${formattedHours}:${formattedMinutes}`;
};

export const formatDateTimeLocal = (utc: string) => {
  const date = new Date(utc);
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  const hours = ('0' + date.getHours()).slice(-2);
  const minutes = ('0' + date.getMinutes()).slice(-2);

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export const extractPkValue = (query: string) => {
  const regex = /pk=(\d+)/; // "pk=" 다음에 따라오는 숫자 값을 찾기 위한 정규식
  const match = regex.exec(query); // 정규식과 일치하는 부분을 찾음

  if (match && match[1]) {
    const pkValue = parseInt(match[1]); // 문자열을 정수로 변환
    return pkValue;
  }

  return null; // "pk" 값이 없거나 숫자가 아닌 경우 null 반환
};

export const removeDots = (string: string) => {
  // 정규식을 사용하여 문자열에서 모든 "."을 제거
  return string.replace(/\./g, '');
};

export const countDots = (string: string) => {
  // 정규식을 사용하여 문자열에서 "."의 개수를 세기
  const dotRegex = /\./g;
  const matches = string.match(dotRegex);
  return matches ? matches.length : 0;
};
