import { observable, action, makeAutoObservable } from 'mobx';

const modalState = makeAutoObservable({
  view: false,
  confirmView: { pk: 0, view: false, voteStatus: 0 },
  userDeviceVerificationConfirmView: { pk: 0, view: false, isPassed: 0 },

  openModal() {
    this.view = true;
  },
  closeModal() {
    this.view = false;
  },
  openConfirmModal(pkNum: number, status: number) {
    this.confirmView = { pk: pkNum, view: true, voteStatus: status };
  },
  closeConfirmModal() {
    this.confirmView = { ...this.confirmView, view: false };
  },
  openUserDeviceVerificationConfirmModal(pkNum: number) {
    this.userDeviceVerificationConfirmView = { pk: pkNum, view: true, isPassed: 1 };
  },
  closeUserDeviceVerificationConfirmModal() {
    this.userDeviceVerificationConfirmView = { ...this.userDeviceVerificationConfirmView, view: false, isPassed: 1 };
  },
});

export { modalState };
